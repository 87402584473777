@use "../../variables";
@use "../../mixins";

 .combos {
    position: relative;
    background: variables.$neutral-lighter-25;
}

.combo {
    margin-right: 40px;

    .price {
        margin-bottom: 42px;
        display: flex;
        justify-content: center;

        &:not(.discount) {
            font-size: variables.$font-size-3xl;
        }
    }

    &__title {
        font-size: variables.$font-size-lg;
        color: variables.$neutral-darker;
        margin-bottom: 8px;
    }

    &__info {
        margin-top: 28px;
        color: variables.$neutral-dark;
        font-size: variables.$font-size-sm;
    }

    .button {
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;
    }
}

.plus {
    font-size: variables.$font-size-6xl;
    position: absolute;
    color: variables.$primary;
    top: 140px;
    right: 5px;
}

.discount {
    font-size: variables.$font-size-s;
    font-weight: variables.$font-weight-bold;
    color: variables.$black-500;
    margin-top: 6px;
    @if variables.$site-nova-or-avi {
        font-size: variables.$font-size-sm;
        font-weight: variables.$font-weight-bold;
        color: variables.$color-discount-light;
        margin-top: 0;
    }
}

.items-carousel .tns-nav {
    display: flex;
    justify-content: center;
    padding: 24px 0;

    button {
        border-radius: 50%;
        background: variables.$primary;
        padding: 5px;
        margin: 0 8px;
        border: 0;
        position: relative;

        &:not(.tns-nav-active) {
            background: variables.$neutral-lighter;
        }

        &:hover {
            background: variables.$neutral;
        }

        &:focus {
            outline: 0;
        }
    }
}

.items-carousel {
    justify-content: start;

    &__controls {
        top: 45%;
        transform: translateY(-50%);
    }

    &__item {
        min-width: 220px;
    }
}
@media(min-width: variables.$responsive-break) {
    .items-carousel {
        @include mixins.carousel-buttons;
    }
}
