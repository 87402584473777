@use '../../variables';

@use 'slider_defaults';
.noUi-connect {
  background: variables.$secondary;
}

.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  top: -16px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: variables.$secondary;
  box-shadow: none;
  outline: none;
}

.noUi-handle::before, .noUi-handle::after {
  display: none;
}
.noUi-handle, .noUi-target {
  border: none;
}
.noUi-tooltip{
  border: none;
}
.range-slider {
    margin-top: 55px;
    width: 93%;
}