@use "../../variables";
@use 'sass:math';

.loading-button {

    .button {
        &__text {
            font-size: variables.$font-size-default;
            font-weight: variables.$font-weight-bold;
        }
    }

    .loading-anim {
        display: none;
        width: 24px;
        height: 24px;

        .variable-stroke {
            stroke: variables.$primary;
        }
    }
}

.loading-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: none;

    &--active {
        display: block;
        z-index: variables.$zindex-header-overlay;
        opacity: .5;
    }

    &--dark {
        background: variables.$neutral-darker;
    }
}

.global-loader {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 180px;
    height: 180px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 15px rgba(86, 86, 86, 0.3);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 50px;
    background: variables.$white;
    z-index: variables.$zindex-loader;

    &--active {
        display: flex;
    }

    .variable-stroke {
        stroke: variables.$secondary;
    }
}

.mask {
    fill: none;
}

.variable-stroke {
    fill: none;
    clip-path: url(#starShape);
    stroke: transparent;
    stroke-linejoin: round;
    stroke-width: 10px;
    stroke-dasharray: 350;
    animation: dash 2s linear infinite;
    stroke-dashoffset: -350;
}

@keyframes dash {

    0% {
        stroke-dashoffset: -350;
    }

    40% {
        stroke-dashoffset: 0;
    }

    60% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 350;
    }
}

.loading-anim {
    width: 120px;
    height: 120px;
}

.result-count {
    display: flex;
    justify-content: center;
    color: variables.$neutral-dark;
    font-size: variables.$font-size-sm;
}

@if variables.$site-nova {
    .loading-overlay {

        &--dark {
            background-image: url('/images/novastar/background_no_stars.png'); //TODO: needs new designs
            background-color: variables.$primary-dark;
            opacity: 0.9;
        }
    }

    .global-loader {
        background-image: url('/images/novastar/novanaut-head--faceless.png');
        background-size: 108%;
        background-position-x: center;
        background-repeat: no-repeat;
        background-color: #1a1646;
    }

    // Novastar loader - Novanaut with KITT-eyes
    // Based on https://codepen.io/IOIO72/pen/wKXMYR

    $lightSize: 7px;

    $lightOn: rgba(#B3E5F8, 1);
    $lightOff: rgba(#48436F, 0);

    $lightNumber: 3;
    $lightUpDuration: 0.75;

    @keyframes lightUp {
        0% {
            background-color: $lightOn;
            box-shadow: 0 0 10px 2px $lightOn;
        }

        20% {
            background-color: $lightOff;
            box-shadow: 0 0 10px 2px $lightOff;
        }

        90% {
            background-color: $lightOff;
            box-shadow: 0 0 10px 2px $lightOff;
        }

        95% {
            background-color: $lightOn;
            box-shadow: 0 0 10px 2px $lightOn;
        }

        100% {
            background-color: $lightOn;
            box-shadow: 0 0 10px 2px $lightOn;
        }
    }

    %centermiddle {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    %lightBase {
        display: inline-block;
        width: 14px;
        height: $lightSize;
        margin: 2px;
        border-radius: 2px;

        animation: {
            name: lightUp;
            iteration-count: infinite;
            timing-function: linear;
            fill-mode: forwards;
            duration: 0s + ($lightUpDuration * 2);
        }
    }

    .kitt {
        @extend %centermiddle;

        list-style: none;
        padding: 0;
        margin-top: 0;
        width: 120px;
        height: unset;
        text-align: center;

        &__eye {
            @extend %lightBase;

            &:before {
                @extend %lightBase;

                content: '';
                position: absolute;
                margin: 0 0 0 -7px;
            }

            @for $i from 1 through $lightNumber {
                &:nth-child(#{$i}) {
                    animation: {
                        delay: 0s + ($lightUpDuration * (math.div($i, 8)));
                    }

                    &:before {
                        animation: {
                            delay: 0s + ((2 * $lightUpDuration) - (math.div($lightUpDuration * $i, 8)));
                        }
                    }
                }
            }
        }
    }
}

@if variables.$site-avi {
    .global-loader {
        background: none;
        box-shadow: none;
    }
    .loading-anim {
        width: initial;
        height: initial;

        &.avit {
            svg {
                animation: pulsate 1s ease-in-out infinite alternate;
            }

            @keyframes pulsate {
                0% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(1.4);
                }
            }
        }
    }
}