@use '../variables';
@use '../mixins';

/*
    Background 100% width. Left area semitransparent, right area with content.
*/
.lr-panel {
    @include mixins.lr-panel;
}
.lr-panel:not(#login-panel) .accordion__title {
    padding: 16px 0;
    justify-content: center;
    font-weight: variables.$font-weight-bold;
}

.panel__back {
    color: variables.$primary;
    font-weight: variables.$font-weight-normal;
    padding: 16px 0;
    cursor: pointer;

    .icon {
        color: variables.$secondary;
    }
}

#StoreSearch {
    border-radius: 8px;
    margin: 0 4px;
    width: 99%;
}

/* wide */
@media(min-width: variables.$responsive-break) {
    .tab-content.lr-panel { // Product card tabs
        position: initial;
        opacity: initial;

        .panel-left {
            display: none;
        }

        .panel-right {
            position: initial;
        }
    }
    .lr-panel:not(.sub-menu):not(#shipping) {
        inset: 0; // #categoryMenu, product card shipping override
    }
    #shipping:not(.lr-panel--active) {
        display: block;
        opacity: 1;
        position: unset;
        pointer-events: all;
        .panel-right {
            position: unset;
            .chevron-responsive{
                display: none;
            }
        }
    }
    #categoryMenu { // #categoryMenu overrides
        .main-nav__item--active .sub-menu.lr-panel {
            display: flex;

            .panel-right {
                width: 100%;
                display: flex;
            }
        }

        .lr-panel {
            display: none;
            opacity: initial;
            pointer-events: initial;
            position: absolute;

            .panel-left {
                display: none;
            }

            .panel-right {
                position: initial;
                inset: initial;
                transition: initial;
                box-sizing: initial;
                background: initial;
                overflow: initial;
            }
        }
    }
    .lr-panel--active {
        .panel-right {
            left: 65%;
            width: 35%;
        }
    }
}

/* narrow */
@media(max-width: variables.$responsive-break - 1) {
    .responsive-lr-panel{
        @include mixins.lr-panel;
    }
    #login-panel.lr-panel {
        .panel-right {
            left: -100%;
            max-width: 500px;
        }

        &--active .panel-right {
            left: 0;
        }
    }
    .lr-panel, .responsive-lr-panel {
        inset: 0; // #categoryMenu override
    }

    .lr-panel--active {
        .panel-right {
            left: 10%;
            width: 90%;
        }
    }

    #login-panel {
        .input-field {
            flex-direction: column;
        }
    }
}
