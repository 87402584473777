@use "../../variables";
@use "../../mixins";

.maps-list {
    .maps-list__item {
        .store {
            display: none;

            &__information {
                margin: 16px 0;
                font-size: variables.$font-size-sm;
            }

            &__type {
                font-weight: variables.$font-weight-bold;
            }
        }

        .maps-list__button {
            @include mixins.responsive-category-menu-item;
            display: flex;
            text-align: left;

            &.expandable:after {
                content: "+";
            }
        }

        &.open {
            .store {
                display: block;
            }

            .maps-list__button:after {
                content: "-";
            }
        }
    }
}

.store {
    padding: 0 16px 8px;
}

#map {
    display: none;
}

.panel-open #map {
    display: block;
}

.storesopen{
    padding: 0 20px;

    #map {
        display: block;
        position: sticky !important; // static HTML inline style override
        width: -webkit-fill-available;
    }
}

.maps {
    .panel-left-extra {
        position: absolute;
        background: variables.$neutral-darker-opaque;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
    &:not(.lr-panel) .panel-left-extra {
        display: none;
    }
    &.lr-panel .panel-left,
    .panel-left {
        background: none;
        opacity: 1;
    }
    .panel-right {
        background: variables.$white-500;
        text-align: left;
        overflow-y: auto;
        padding: 0 16px;
        height: -webkit-fill-available;
    }
}

button[data-panel-target=customer-service-panel] {
    border: 1px solid variables.$neutral-lighter-100;
    border-radius: 8px;
    height: 70px;
    width: 100%;
}

.maps-list {
    margin-top: 16px;
    flex-direction: column;
    flex-wrap: nowrap;

    &__button {
        font-size: variables.$font-size-default;
        color: variables.$primary;
        border: 0;
        background: transparent;
        width: 100%;

        &:after {
            color: variables.$neutral;
            font-size: variables.$font-size-3xl;
        }
    }

    .store {

        &__link {
            margin-top: 16px;
        }

        .driveIn-link {
            display: none;
        }
    }

    &__item {
        border: 1px solid variables.$neutral-lighter-100;
        border-radius: 8px;
        margin: 4px 8px;

        &.open {
            overflow: initial;
            max-height: initial;
        }
    }
}

.maps__overlay-parcel {
    display: flex;
    margin: 16px;

    .alert {
        width: 100%;
        margin-left: 16px;
    }

    input {
        min-width: 400px;
    }

    .loading-button {
        .loading-anim {
            display: flex;
            visibility: hidden;
        }
    }
}

@media(min-width: variables.$responsive-break) {
    .maps {
        display: flex;
        min-height: 40vw;

        &.panel__wrapper {
            background: transparent;
            width: 100%;
        }

        .panel-left {
            display: flex;
            flex-basis: 65%;
            position: relative;

            .google-maps {
                position: absolute;
                inset: 15px;
            }
        }

        .panel-right {
            background: variables.$white-500;
            flex-basis: 35%;
            overflow-y: auto;
        }

        .maps-closest__button {
            font-weight: variables.$font-weight-bold;
            height: 32px;
            margin: 16px auto;
            display: flex;

            .button__text {
                padding: 0;
            }

            .icon {
                padding-left: 0;
            }
        }

        .maps__desc {
            display: block;
            font-size: 14px;
            color: variables.$neutral;
        }
        .google-maps{
            min-height:460px;
        }
    }

    #map {
        z-index: variables.$zindex-positive;
        border: 3px solid variables.$white-500;
        border-radius: 23px;
        max-height: 90vh;
    }
}

.maps-header h1 {
    padding: 10px 0;
}

.store__information {
    &.red {
        color: variables.$supporting-01-dark;
    }
}

.panel-left {
    .google-maps {
        .cookie-disclaimer {
            background: variables.$white-500;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-radius: 18px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .maps-closest__button {
        display: none;
    }
    .maps {
        width: 100% !important;
        height: 100%;

        .panel-left {
            display: none;
            position: absolute;
            width: 120%;
            left: 0;
            height: 100%;
        }

        .panel-right {
            left: 10%;
            width: 90%;
            height: 100%;
        }
    }
}