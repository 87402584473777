@use "../../variables";

.discussion {
  background: variables.$neutral-lighter-25;
  min-height: 480px;

    &-overview {
        align-items: center;
        text-align: center;
        
        .review-rating__stars {
            justify-content: center;
        }
    }
    
    &-list {
        display: block;
    }
    
    &-star {
        $rating-star-size: 100px;
        background-size: $rating-star-size $rating-star-size;
        width: $rating-star-size;
        height: $rating-star-size;
        display: inline-flex;

        @if variables.$site-nova {
            background-image: url('/images/no_reviews_nova.png');
        }
        @else {
            background-image: url('/images/review_star.svg');
        }        
    }
    
    &__button-disabled {
        color: variables.$neutral-lighter-100;
    }

  &--empty {
    display: flex;
    flex-direction: column;
  }

  &__text {
    color: variables.$neutral-dark;
  }
    
    &__average-rating {
        font-size: variables.$font-size-3xl;
        font-weight: variables.$font-weight-bold;
    }
    
    &__text-light {
        color: variables.$neutral;
    }

    &__text-primary {
        color: variables.$primary;
    }
    
    &__reviewed {
        font-size: variables.$font-size-md;
        margin: 0 5px 5px;
    }

  &__item {

    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }
}

.discussion-rating-star {
    $rating-star-size: 20px;
    background-size: $rating-star-size $rating-star-size;
    width: $rating-star-size;
    height: $rating-star-size;
    display: inline-flex;
}

.discussion-rating-count {
    font-size: variables.$font-size-lg;
}

.discussion-rating-table {
    td {
        white-space: nowrap;
        padding: 2px 5px;
    }
    
    .discussion-rating-value {
        text-align: right;
        font-weight: variables.$font-weight-bold;
    }

    .discussion-rating-star {
        $rating-star-size: 14px;
        background-size: $rating-star-size $rating-star-size;
        width: $rating-star-size;
        height: $rating-star-size;
        display: inline-flex;
    }
    
    .discussion-progress-bar {
        width: 100%;

        &__unfilled {
            background-color: variables.$white;
            border-radius: 8px;
            height: 15px;
            overflow: hidden;
        }
        
        &__filled {
            background-color: variables.$secondary;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 100%;
        }
    }
}

@media (max-width: variables.$responsive-break - 1){
    .discussion {
        
        .responsive-panel {
            padding: 0;
        }
        
        &__container {
            flex-direction: column;

            &--empty {
                flex-direction: column-reverse;
            }

            &:not(.discussion__container--empty) {
                .discussion-overview {
                    background: variables.$neutral-lighter-25;
                }
            }
        }

        &-overview {
            padding: 10px;
        }

        &--empty {
            background: variables.$neutral-lighter-25;
            padding: 24px;
        }

        &__separator {
            border: variables.$neutral-lighter-50 solid 5px;
        }

        &__reviewed {
            text-align: center;
        }

        &__item {

            &:first-of-type {
                margin-top: 24px;
            }
        }
    }
}

@media (min-width: variables.$responsive-break){
    .discussion {
        &__container{
            padding-top: 56px;
            padding-bottom: 56px;
        }

        &-overview {
            padding: 0 50px;
        }

        &--empty {
            padding-top: 16px;
        }

        &__separator {
            border: variables.$neutral-lighter-100 solid 1px;
            margin-top: 16px;
        }
    }
}