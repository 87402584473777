@use "../../variables";

.services {

  &-list {
    margin-top: 24px;
    margin-bottom: 16px;
    color: variables.$neutral-darker;

    &__item {
      display: flex;
      margin-bottom: 8px;
      font-size: variables.$font-size-sm;
  
      .icon {
        color: variables.$secondary;
      }
    }
  }

}