/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@use "Variables" as faVariables;

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: faVariables.$fa-font-display;
  src: url("#{faVariables.$fa-font-path}/fa-solid-900.woff2") format("woff2"),
    url("#{faVariables.$fa-font-path}/fa-solid-900.woff") format("woff");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
