@use "../../variables";

@media (min-width: variables.$mobile-break) {
    .image__wrapper {
        position: relative;

        .play-button {
            transition: all .4s ease-in-out;
            height: 2.5rem;
            width: 2.5rem;
            background: 0 0;
            background-image: none;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 0;
            cursor: pointer;
            padding: 0;
            background-image: url(/images/play.svg);
        }
    }

    .vertical-list {

        &__title {
            font-size: variables.$font-size-xl;
            font-weight: variables.$font-weight-normal;
            color: variables.$neutral-darker;
            padding-bottom: 8px;
            border-bottom: solid 1px variables.$neutral-lighter-100;
        }

        &__item {
            display: flex;
            margin-top: 16px;


            .topic {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                color: variables.$secondary;
                font-weight: variables.$font-weight-bold;
            }

            .paragraph,
            .date {
                margin-top: 8px;
            }

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .date {
                color: variables.$neutral;
                font-size: variables.$font-size-sm;
            }

            .title {
                font-weight: variables.$font-weight-bold;
                color: variables.$primary;
            }

            h3 {
                font-size: variables.$font-size-sm;
            }
        }
    }


    .vertical-list--ordered {

        .vertical-list__item {

            &:hover {
                background: variables.$neutral-lighter-25;
            }
        }

        .image__wrapper {

            &:before {
                content: attr(data-count);
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                color: variables.$neutral-lighter;
                font-size: variables.$font-size-7xl;
                font-weight: variables.$font-weight-bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .vertical-list--small {

        .vertical-list__item:hover {

            .content {
                background-color: variables.$neutral-lighter-25;
            }
        }

        .content {
            padding: 0 16px;
            justify-content: center;
        }

        .image {
            padding: 8px;
            max-width: 88px;
            min-width: 88px;
            max-height: 88px;
            min-height: 88px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }


    .vertical-list--large {        

        .vertical-list__item {

            &:hover {
                background: variables.$neutral-lighter-25;
            }

            p.paragraph {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding-right: 10px;
            }
        }

        .image {
            min-width: 210px;
            max-width: 210px;
            height: 100px;
            width: 100%;
            object-fit: cover;
        }

        .content {
            padding: 8px 0;
            margin-left: 32px;
            justify-content: center;
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .vertical-list--large {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
