@use "../../variables";
@use "../../mixins";

.carousel-card {
    background: variables.$white;
    border: solid 1px variables.$neutral-lighter-100;
    height: 100%;
    box-sizing: border-box;
    display: flex;

    &__title {
        display: flex;
        font-size: variables.$font-size-sm;
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;
        text-align-last: left;
        text-align: left;
        word-break: break-word;
        margin: 0;
        margin-bottom: 12px;
        line-height: variables.$paragraph-lh;
        max-height: 4.5em;
        overflow: hidden;
    }

    &__content {
        margin: 0 24px;
        height: 100%;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        
        a {
            height: inherit;
        }
        
        .nova-head{
            margin-top: 10px;
            margin-right: -20px;
            width: 40px;
        }
    }

    &__code {
        font-size: 13px;
        display: flex;

        .value {
            color: variables.$neutral-dark;
            font-weight: variables.$font-weight-normal;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 8px;
        min-height: 72px;

        .checkbox {
            display: flex;
            height: 18px;
            width: 18px;

            &__label {
                padding: 0;
            }
        }
    }

    &__button {
        color: variables.$neutral;

        &:hover {
            color: variables.$primary;
        }

        &:focus {
            outline: none;
            color: variables.$neutral;
        }
    }


    .price {
        display: block;
        align-items: baseline;
        padding-top: 12px;
        text-align: left;
        font-size: variables.$font-size-md;
        font-weight: variables.$font-weight-bold;

        .label{
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-s
        }
        
        &--discount {
            color: variables.$supporting-01;
        }
    }

    .discount {
        line-height: variables.$paragraph-lh;
        position: relative;
        text-decoration: line-through;

        // Loyal customer offers should not be strikedThrough. Against the law in EE.
        &__loyal{
            text-decoration: none;
        }
        @if variables.$site-nova-or-avi {
            &{
                color: variables.$neutral;
                font-weight: variables.$font-weight-normal; 
            }
        }
    }

    &--unchecked {
        opacity: .5;

        .carousel-card__title,
        .checkbox__label,
        .price {
            opacity: .5;
        }
    }

    &.hover {
        opacity: 1;
        border-color: transparent !important;

        .carousel-card__title,
        .checkbox__label,
        .price {
            opacity: 1;
        }
    }

    .add-to-cart {
        @include mixins.add-to-cart;
    }
}
@media(min-width: variables.$responsive-break){
    .carousel-card {
        flex-direction: column;
        max-width: 240px;

        &__content {
            margin-bottom: 18px;
        }

        .image {
            transition: all .1s ease-in-out;

            &__wrapper {
                margin: 24px 42px 16px 42px;
                padding: 10px;
            }
        }

        &--clickable {

            &:hover:not(.carousel-card--unchecked) {
                border-color: variables.$primary-light;

                .carousel-card__title {
                    color: variables.$primary-dark;
                }

                img {
                    transform: scale(1.1);
                }
            }
        }

        &:hover .add-to-cart {
            display: block;
        }
        
        &__footer .discount__loyal{
            position: absolute;
        }
    }
}
@media(max-width: variables.$responsive-break - 1){
    .carousel-card {
        border-radius: 10px;
        overflow: hidden;

        &__title {
            margin-top: 8px;
        }
        
        &__content {
            width: 70%;
        }
        
        &__footer {
            align-items: center;
        }

        .image__wrapper {
            padding-left: 8px;
            padding-top: 16px;
        }

        .add-to-cart {
            display: block;
        }
    }
}