@use "../../variables";

.banner {

    &__container {
        display: flex;
    }

    &--dark {
        background: variables.$neutral-darker-600;
    }

    &__paragraph,
    &__title {
        color: variables.$white;
    }

    &__title {
        font-size: variables.$font-size-2xl;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 24px;
        font-weight: variables.$font-weight-bold;
    }

    &__paragraph {
        font-size: variables.$font-size-sm;
        line-height: variables.$paragraph-lh;
        margin-bottom: 26px;
    }

    &__link {
        color: variables.$secondary;
        font-weight: variables.$font-weight-bold;

        &:visited {
            color: variables.$secondary;
            font-weight: variables.$font-weight-bold;
        }
    }

    &__text {
        padding: 80px 0;
        max-width: 500px;
        margin-right: 100px;
    }

    &__image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &__percent {
        position: absolute;
        right: 0;
        left: 0;
        top: 15%;
    }

    &__bubble {
        height: 56px;
    }

    &__bubble-value {
        position: absolute;
        left: 30%;
        top: 10%;
        font-size: variables.$font-size-4xl;
        color: variables.$white;
        font-weight: variables.$font-weight-bold;
    }

    &__img {
        padding: 30px 0;
        object-fit: contain;

        &--list {
            height: 315px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .banner__container {
        .banner__img {
            display: block;
            height: 300px;
            object-fit: cover;
        }
    }
}
