@use "../../variables";
@use "../../mixins";

.close {
    cursor: pointer;
    border: 0;
    background: 0;
    color: variables.$neutral-lighter-100;
    min-width: auto;
    float: right;
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
        background: 0;
        color: variables.$neutral-darker;
    }
}

#bonus-modal > .modal__content {
    min-width: 360px;
}

#bonus-modal .modal__header {
    display: block;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 46, 68, .5);
    z-index: variables.$zindex-modal;
    display: none;

    &--large {

        .close {
            margin: 0;
            margin-left: auto;
        }

        .controls {

            .prev {
                margin-left: 200px;
            }

            .next {
                margin-right: 200px;
            }
        }

        .modal__content {
            max-width: 90vw;
            max-height: 90vh;
            width: 100%;
            height: 100%;
        }
    }

    &--small {
        .modal__content {
            width: min(90%, 800px);
            max-height: calc(100vh - 64px);
            overflow: auto;
        }
    }

    &__content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: variables.$white;
        display: none;
        border-radius: variables.$border-radius-round;
        max-height: 99vh;
        overflow: auto;
        width: min(90%, 800px);
    }

    &__footer {
        max-height: 20vh;
        box-sizing: border-box;

    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-height: 20vh;
        box-sizing: border-box;
    }

    &__header,
    &__body,
    &__footer {
        padding: 24px;
    }

    &--active {
        display: block;

        .modal__content {
            display: block;
        }
    }

    &__body {
        overflow-y: auto;
    }
}

.terms-modal .modal__body {
    max-height: 700px;
    margin-bottom: 20px;
}

.video-modal {
    .close {
        position: absolute;
        right: -80px;
        top: -80px;
    }

    .modal {

        &__header,
        &__body,
        {
            padding: 0;
        }
        &__footer{
            display: none;
        }

        &__wrapper,
        &__body {
            height: 100%;
        }
    }

    .modal__content {
        max-width: 1216px;
        max-height: 684px;
    }
}

.modal-email {
    @include mixins.modal-gray;
    @include mixins.modal-general;
}

.my-page-overview {
    .modal__title {
        font-size: 32px;
        font-weight: normal;
    }

    .modal__body, .modal__footer {
        background: variables.$neutral-lighter-25;
    }
}

.terms__label + .openModal {
    line-height: 30px;
    margin-left: 10px;
}

.delete-card-token-modal{
    .modal__title{
        color: variables.$primary;
    }
    .modal__body{
        background: variables.$neutral-lighter-25;
        .delete-card-token__secondary{
            color: variables.$neutral;
        }
    }
    .modal__footer{
        display: flex;
        justify-content: space-evenly;
    }
}

@media (min-width: variables.$responsive-break) {

}

@media(max-width: variables.$responsive-break - 1) {
    .close {
        top: 8px;
        right: 8px;
    }
    .modal {
        &__footer {
            padding-left: 8px;
            padding-right: 8px;
            padding-bottom: 8px;
            text-align: center;
        }

        &-discussion.modal--active {
            z-index: 1000001;
        }
    }
}