@use "../variables";

.in-transit {

    .continue-shopping {
        min-width: 360px;
        font-weight: variables.$font-weight-bold;
    }

    &__wrapper {
        max-width: 600px;
        text-align: center;
        margin: 0 auto;
    }

    &__icon {
        margin-top: 96px;
        margin-bottom: 40px;
    }

    &__title {
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-3xl;
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    .list {
        display: flex;
        margin-top: 40px;
        margin-bottom: 20px;
        flex: 1 1 0;

        .list__item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 24px 56px;

            &:not(:last-of-type) {
                border-right: solid 1px variables.$neutral-lighter-100;
            }
        }

        .button {
            margin-top: auto;
        }

        &__paragraph {
            margin-top: 24px;
            margin-bottom: 20px;
        }
    }

    .bonus-points {
        display: flex;
        font-size: variables.$font-size-xl;
        color: variables.$supporting-01;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 20px;

        .icon-single-star {
            margin-right: 8px;
        }
    }
    
    .typeform-wrapper{
        margin-bottom: 50px;
    }
}
