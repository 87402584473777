@use "../../variables";

@media (min-width: variables.$responsive-break) {
    .responsive-menu__wrapper {
        .accordion__description {
            text-align: center;
            
            #description-login {
                margin-bottom: 8px;
                font-size: variables.$font-size-default;
                color: variables.$primary;
            }
        }
        
        .greeting {
            margin-left: 8px;
            color: variables.$neutral-darker;
        }
    }
    .panel-list {

        li {
            padding: 9px 0;
            &:first-of-type(:not(.panel-list__item--border-bottom)) {
                padding-top: 20px;
            }

            .icon {
                width: 24px;
                justify-content: center;
                color: variables.$primary;
            }

            &--border-bottom {
                padding-bottom: 20px;
                margin-bottom: 11px;
                border-bottom: solid 1px variables.$neutral-lighter-100;
            }
        }

        &__link {
            color: variables.$primary;
            display: flex;
            align-items: center;

            .text {
                margin-left: 8px;
            }

            &:hover {

                .text {
                    text-decoration: underline;
                }
            }
        }

        &__toggle {
            display: none;
        }

        &__impersonate {
            display: flex;
            &-end{
                cursor: pointer;
                font-weight: variables.$font-weight-bold;
                color: variables.$primary;
                margin-left: 48px;
                margin-top: 8px;
            }
            .text {
                margin-left: 8px;
            }
        }
    }

    .panel__header__top__title{
        display:none;
    }

    #impersonate {
        margin-left: 10px;
        width: 270px;
        border: none;
    }

    #impersonate:focus {
        border: initial;
    }
    .panel__footer {
        .logout {
            width: 100%;
        }
    }
}


@media (max-width: variables.$responsive-break - 1){
    .responsive-panel__wrapper.responsive-menu__wrapper {
        background: variables.$primary;
        .panel {
            background: variables.$primary;
            padding: 0;

            &__title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                color: variables.$white;
            }

            &__close {
                color: variables.$white;
            }

            &__header {
                margin-top: 20px;
                display: flex;
                flex-direction: column-reverse;

                &__top {
                    display: flex;
                    align-items: center;

                    &__title {
                        display: flex;
                        flex-grow: 1;
                        justify-content: center;
                        align-items: center;
                        font-size: variables.$font-size-md;
                        color: variables.$secondary;
                        
                        a{
                            color: variables.$secondary;
                            display: contents;
                        }
                    }
                }
            }

            &__body {
                order: 1;
                display: flex;
                flex-direction: column;
            }

            &__footer {
                text-align: center;
            }

            .footer {
                &__contacts {
                    text-align: center;
                    padding-top: 8px;
                    color: variables.$white;
                }
                
                &__service {
                    padding: 0 24px;
                }

                &__stores-and-service-buttons {
                    display: block;

                    .button--inverted {
                        color: white;
                    }
                }
                
                &__bottom {
                    text-align: center;
                }
            }

            &-list {
                background: variables.$primary;

                &__first-level-item {
                    width: 100%;
                    border-bottom: 1px solid variables.$primary-dark;

                    &:first-of-type {
                        border-top: 1px solid variables.$primary-dark;
                    }


                    &:not(.panel-list__first-level-item--active) .panel-list {
                        display: none;
                    }

                    &--active .panel-list {
                        display: block;

                        &__toggle {
                            font-weight: variables.$font-weight-bold;

                            .icon {
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .icon {
                        position: absolute;
                        right: 10px;
                        color: variables.$primary-light;
                        -webkit-transition: all .5s ease;
                        transition: all .5s ease;

                        @if (variables.$site-nova-or-avi) {
                            color: variables.$primary-lighter;
                        }
                    }
                    
                    .my-account-list .icon{
                        display: none;
                    }
                    
                    .accordion__title:after {
                        display: none;
                    }
                    
                    @if (variables.$site-nova-or-avi) {
                        &.service-link {
                            display: none;
                        }

                        li {
                            background: variables.$primary-dark-10;
                            @if (variables.$site-nova) {
                                background: variables.$primary-dark;
                            }
                        }
                    }
                }

                &__second-level-item {
                    width: 100%;

                    &:not(.panel-list__second-level-item--active) .panel-list {
                        display: none;
                    }

                    &--active .panel-list {
                        display: block;

                        &__toggle-second {
                            font-weight: variables.$font-weight-bold;

                            .icon {
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                        //nesting without & for increased css specificity
                        li {
                            border-top: none;
                            background: variables.$primary-dark-10;
                            @if (variables.$site-avi) {
                                background: variables.$primary-dark;
                            }
                        }
                    }
                    
                    .panel-list__toggle-second{
                        padding-left: 30px;
                    }

                    .accordion__title:after {
                        display: none;
                    }
                }

                &__third-level-item {
                    width: 100%;

                    &:not(.panel-list__third-level-item--active) .panel-list {
                        display: none;
                    }

                    &--active .panel-list {
                        display: block;

                        &__toggle-third {
                            font-weight: variables.$font-weight-bold;

                            .icon {
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                        
                        li {
                            border-top: none;
                            background: variables.$primary-darker-10;
                            padding: 10px 0;
                            &:not(:last-child) {
                                border-bottom: 1px solid variables.$primary-dark;
                            }
                            a {
                                color: variables.$white;
                                padding-left: 50px;
                                display: flex;
                                height: 55px;
                                align-items: center;
                            }
                        }
                    }

                    .panel-list__toggle-third{
                        padding-left: 40px;
                    }

                    .accordion__title:after {
                        display: none;
                    }
                }

                &__item {
                    max-width: 100%;
                    background: variables.$primary-dark;
                    
                    &:not(:first-of-type) {
                        border-top: 1px solid variables.$primary;
                    }
                }

                &__link, &__toggle, &__impersonate, .accordion__link, &__toggle-second, &__toggle-third {
                    max-width: 100%;
                    padding: 20px;
                    color: variables.$white;
                }

                &__impersonate-end {
                    padding: 0 0 20px 20px;
                    color: variables.$white;
                    cursor: pointer;
                }

                &__link {
                    display: block;
                }

                &__toggle, &__toggle-second, &__toggle-third {
                    display: flex;
                    align-items: center;

                    &:after {
                        position: absolute;
                        right: 0;
                        padding-right: 10px;
                        font-size: variables.$font-size-3xl;
                    }
                }
            }

            .logout, .forgot {
                border: none;
                background: none;
                color: variables.$secondary;
                text-decoration: underline;
                font-weight: variables.$font-weight-normal;
                font-size: variables.$font-size-sm;
                min-width: 0;
                height: auto;
            }

            .logout {
                margin: 10px 0;
            }
        }

        .logo {
            &-brand {
                height: 20px;
                @if variables.$site-avi {
                    content: url('/images/avitela/logo.svg');
                    height: 40px;
                }
            }

            &-my-euronics {
                content: url('/images/user_icon.svg');
            }
        }

        .accordion {
            width: 100%;
            padding: 0;
            min-width: 0;

            &__item {
                position: relative;
                margin: 0;
                color: variables.$white;
                border-top: 1px solid variables.$primary;

                &--active .accordion__title {
                    font-weight: variables.$font-weight-bold;

                    &:after {
                        font-weight: variables.$font-weight-bold;
                    }
                }

                .icon {
                    display: none;
                }
            }

            &__title {
                padding: 20px;
                color: variables.$white;

                &__text {
                    display: flex;
                    align-items: center;

                    &:after {
                        position: absolute;
                        right: 0;
                        padding-right: 10px;
                        font-weight: variables.$font-weight-bold;
                        font-size: variables.$font-size-xl;
                    }
                }
            }

            &__description {
                text-align: center;
                
                #description-login,
                #description-register {
                    padding: 20px;
                }
                
                p {
                    margin: 0 !important;
                    font-size: variables.$font-size-sm !important;
                    color: variables.$white !important;
                }
            }

            &__content {
                padding: 20px;
                
                .login__form .input-field__input {
                    border-radius: 6px;
                }
            }
        }

        .login {
            &__wrapper {
                padding: 0;
                color: variables.$white;
                background: variables.$primary-dark;
                border-radius: 6px;
            }

            &-hero {
                display: none;
            }

            &__section {
                order: -1;
                margin: 20px;
                
                li {
                    padding: 10px 5px;
                }
            }
        }

        .tabs-menu {
            margin-top: 20px;

            &__list {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            &__item {
                &:not(.tabs-menu__item--active) .tabs-menu__link {
                    color: variables.$secondary;
                    font-weight: variables.$font-weight-normal;
                }
                width: 100%;
            }

            &__link {
                width: 100%;
                display: inline-flex;
                justify-content: center;
                color: variables.$white;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-md;
            }
        }
    }
}