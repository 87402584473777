@use "../../variables";

.product {
    font-size: variables.$font-size-sm;

    &__review {
        margin: 24px 0;
    }

    &__title {
        display: none;
        font-size: variables.$font-size-lg;
        color: variables.$neutral-darker;
        margin: 8px 0;
    }

    &__info {

        &__text {
            line-height: variables.$paragraph-lh;
            margin-top: 20px;
        }

        &__title {
            color: variables.$neutral-darker;
            font-size: variables.$font-size-default;
            margin: 0;
        }
    }

    .flash-sales {
        margin-bottom: 24px;
    }

    .additional {
        margin-bottom: 24px;
    }


    .switch {
        margin-bottom: 24px;
    }

    &__buttons {

        .button {
            min-width: auto;
            padding: 0 8px;
            font-weight: variables.$font-weight-bold;

            &__text {
                font-size: variables.$font-size-sm;
                margin-right: 8px;
            }
        }

        .button:not(:first-of-type) {
            margin-left: 8px;
        }

        .icon {
            padding: 0 5px;
        }

        .active .icon {
            color: variables.$white;
            background: variables.$primary;
        }
    }

    .product-bonus {
        margin-top: 20px;
        margin-bottom: 32px;
    }

    .overview-producer {
        display: flex;
        margin-bottom: 8px;
        line-height: 24px;

        &__code {
            color: variables.$neutral-dark;
            display: flex;

            .value {
                word-wrap: break-word;
                color: variables.$neutral-darker;
                font-weight: variables.$font-weight-bold;
                margin-left: 8px;
            }
        }

        &__info {
            color: variables.$neutral-dark;
            display: flex;

            .value {
                word-wrap: break-word;
                color: variables.$primary;
                font-weight: variables.$font-weight-bold;
                margin-left: 7px;
            }
        }

        @if variables.$site-nova {
            &__code .value, &__info .value {
                color: variables.$primary-dark;
                font-weight: variables.$font-weight-normal;
            }
        }

        .vertical-separator {
            border-right: solid 1px variables.$neutral-lighter;
            margin: 0 8px;
        }
    }
    
    .fa-map-marker-check{
        height: 16px;
        width: 16px;
    }
}


.overview-rating {
    width: 100%;
    display: flex;

    &__stars {
        margin-right: 8px;
    }

    &__reviews {
        color: variables.$primary;
        margin-right: 16px;
        text-decoration: none;

        &:visited {
            color: variables.$primary;
        }
    }

    &__average {
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
        margin-right: 16px;
    }

    &__sold {
        color: variables.$neutral-dark;

        .amount {
            color: variables.$neutral-darker;
            font-weight: variables.$font-weight-bold;
        }
    }
}

.icon.absolute {
    position: absolute;
}

.product-card-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    @if variables.$site-nova {
        border-radius: 4px;
    }
}

.product-card-icons.active {
    background: variables.$primary;
}

@media(min-width: variables.$responsive-break) {
    .product{
        &__info {
            margin-top: 46px;
        }
        &__status {
            .best-price-block{
                display: none;
            }
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .product {
        &__title,
        &__buttons {
            text-align: center;
        }
        
        &__title {
            display: block;
        }

        .review-rating__stars {
            padding-left: 10px;
        }

        &__info {
            margin-top: 20px;
        }
        
        &__status{
            .badge__wrapper{
                justify-content: center;
                .best-price-block{
                    display: flex;
    
                    .novanaut {
                        &-head, &-head-curved-line, &-head-percent, &-head-percent-curved-line {
                            width: 64px;
                        }
    
                        &-message {
                            border-radius: 6px;
                            background: variables.$color-lavender;
                            color: variables.$white;
                            padding: 8px 12px;
                            position: relative;
                            margin-left: 10px;
    
                            &::before {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                top: 50%;
                                left: -8px;
                                border-top: 8px solid transparent;
                                border-bottom: 8px solid transparent;
                                border-right: 8px solid variables.$color-lavender;
                                margin-top: -8px;
                            }
                        }
                    }
                }
            }
        }

        .overview-producer {
            justify-content: center;
            flex-direction: column;
            justify-content: center;

            &__code, &__info {
                @if variables.$site-nova-or-avi {
                    justify-content: left;
                }
            }
        }
    }
}