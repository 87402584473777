@use "../../variables";
@use "../../mixins";

$input-padding: 14px 8px;

.textarea {
    align-items: baseline;
    width: 100%;
    display: flex;

    &--vertical {
        flex-direction: column;

        .textarea__label {
            padding: 0;
            padding-bottom: 8px;
        }
    }
}

.textarea__label {
    padding: $input-padding;
    flex: 1 0 10%;
    text-align: center;

    &--small {
        font-size: variables.$font-size-sm;
    }
}

.textarea__container {
    @include mixins.input-field-container;
}

.cart-address .textarea__container {
    padding: 16px;
}
.textarea__input {
    @include mixins.input-field-input;
}

.textarea__helper-text {
    line-height: variables.$lh-large;

    @include mixins.input-field-helper-text;
}
