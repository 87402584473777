@use "../../variables";

.info {

    &__list {
        display: flex;
        justify-content: space-around;
    }

    &__item > * {
        display: flex;
        justify-content: space-around;

        .img-tiny {
            width: 68px;
            height: 68px;
            align-self: center;
        }
    }

    &__title {
        margin-bottom: 4px;
        color: variables.$primary;
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;
    }

    &__text {
        font-size: variables.$font-size-sm;
        line-height: variables.$paragraph-lh;
    }

    &__content {
        flex-direction: column;
        padding-left: 16px;
        align-content: center;
    }

    &__link {
        color: variables.$primary;

        &:visited {
            color: variables.$primary;
        }
    }
}

@media(min-width:variables.$mobile-break) {
    .info {
        &__spacer {
            border-right: 1px solid variables.$neutral-lighter-100;
        }
    }
}

@media(max-width:variables.$mobile-break - 1) {
    .info {
        &__spacer {
            display: none;
        }

        &__list {
            flex-direction: column;
        }

        &__item {
            margin: 16px;

            & > * {
                justify-content: flex-start;
                margin-left: 10%;
            }
        }
    }
}
