@use "../../variables";

.breadcrumbs {
    padding: 0;
    color: variables.$neutral;

    &__item {
        display: inline-block;
        list-style: none;
        font-size: variables.$font-size-sm;

        &:before {
            content: ">";
            margin: 0 10px;
        }

        &:first-child {

            &:before {
                content: "";
                margin: 0;
            }
        }

        &:hover {

            .breadcrumbs__link {
                text-decoration: underline;
            }
        }
    }

    &__link {
        color: variables.$neutral;
    }

    .fa-chevron-left {
        display: none;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .breadcrumbs {
        &__item {
            display: none;

            &:before {
                display: none;
            }

            &:hover {

                .breadcrumbs__link {
                    text-decoration: none;
                }
            }
        }

        &__item:nth-last-child(2) {
            display: inline-block;
            font-size: variables.$font-size-md;
        }

        &__link {
            color: variables.$primary;
        }

        .fa-chevron-left {
            display: inline-block;
            color: variables.$secondary;
        }
    }
}
