@use "variables";

$largeFontSize: 20px;
$largeLineHeight: 24px;
$mediumFontSize: 16px;
$mediumLineHeight: 16px;
$smallFontSize: 15px;
$smallLineHeight: 17px;
$borderRadius: 25px;
@if (variables.$site-nova) {
    $borderRadius: 3px;
}

$borderRadiusSquareButton: 3px;
$largeButtonMinWidth: 240px;
$mediumButtonMinWidth: 165px; // previous: 192px
$smallButtonMinWidth: 144px;

$input-padding: 14px 8px;

@mixin input-field-container {
    flex: 1 1 80%;
    width: 100%;
    flex-direction: column;
    min-width: 0;
    display: flex;

    &--icon {
        position: relative;
        justify-content: center;
        width: 100%;

        .icon {
            position: absolute;
            right: 8px;
            top: 16px;
        }
    }

    &.inline {
        flex-direction: row;
        justify-content: space-between;
    }
}

@mixin input-field-input {
    font-size: variables.$font-size-default;
    padding: $input-padding;
    box-sizing: border-box;
    border: solid 1px variables.$neutral-lighter;
    border-radius: 2px;
    width: 100%;

    &:active,
    &:focus {
        border-color: variables.$primary;
    }

    &:focus {
        outline: none;
    }

    &--error {
        border-color: variables.$supporting-01-dark;
    }
}

@mixin input-field-helper-text {
    display: block;
    padding: 8px 0;
    font-size: variables.$font-size-sm;

    &--regular {
        display: flex;
    }

    &--hint {
        display: flex;
        color: variables.$neutral;
    }

    &--error {
        display: flex;
        color: variables.$supporting-01-dark;
    }
}

@mixin input-field-label {
    padding: 0 .5rem;
    flex: 1 1 25%;

    &--small {
        font-size: variables.$font-size-sm;
    }
}


@mixin input-field-helper-link {
    padding: 8px 0;
    font-size: variables.$font-size-sm;

    &:visited {
        color: initial;
    }
}

@mixin input-field-editable {
    .input-field {

        &__label {
            color: variables.$neutral-dark;
            padding: 0;
        }

        &__input {
            padding: 0;
            border: 0;
            color: variables.$neutral-dark;
        }
    }
}

@mixin slider-controls {
    .next,
    .prev {
        color: variables.$secondary;
        font-size: variables.$font-size-md;
        height: 50px;
        width: 35px;
        border: 0;
        background-color: white;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        top: 250px;
        cursor: pointer;

        &:hover {
            color: variables.$secondary-darker;
        }
    }

    .next {
        right: 0;
        border-radius: 20% 0 0 20%;
        filter: drop-shadow(-2px 0px 4px variables.$neutral-lighter-25);
    }

    .prev {
        left: 0;
        border-radius: 0 20% 20% 0;
        filter: drop-shadow(2px 0px 4px variables.$neutral-lighter-25);
    }
}


@mixin tns-nav {

    .tns-nav {
        display: flex;
        justify-content: center;
        margin: 16px 0;

        button {
            border-radius: 50%;
            background: variables.$primary;
            padding: 5px;
            margin: 0 5px;
            border: 0;

            &:not(.tns-nav-active) {
                background: variables.$neutral-lighter;
            }

            &:focus {
                outline: 0;
            }
        }
    }
}

@mixin carousel-buttons {

    .tns-outer {
        margin: 0 48px;
    }

    &__controls {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &:focus {
            outline: 0;
        }

        .next {

            .icon {
                right: 37%;
            }
        }

        .prev {

            .icon {
                left: 37%;
            }
        }


        .prev,
        .next {
            position: relative;
            padding: 0;
            background: 0;
            border: 0;
            display: flex;
            justify-content: center;
            color: variables.$primary;
            width: 40px;
            height: 40px;
            align-items: center;
            border-radius: variables.$border-radius-circle;


            &:hover {
                cursor: pointer;
                background-color: variables.$neutral-lighter-50;
            }

            .icon {
                position: absolute;
                padding: 0;
            }
        }
    }

    @media(max-width: variables.$mobile-break) {
        .tns-outer {
            margin: 0;
        }
    }
}

@mixin text-three-dots($text-area-width: 200px) {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: $text-area-width;
    white-space: nowrap;
}

@mixin my-overview-section {
    $latest-deals-background: variables.$white;
    $section_padding: 1.25rem 1.5rem;
    background: $latest-deals-background;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        padding: $section_padding;

        &--border {
            border-bottom: solid 1px variables.$neutral-lighter-50;
        }
    }

    &__more {
        text-align: center;
        padding: 1.25rem 1.5rem;
    }
}

@mixin my-info-container {
    display: flex;
    justify-content: center;
    background-color: variables.$white;
    border-radius: variables.$border-radius-round;

    &__container {
        & > * {
            margin-bottom: 1rem;
        }

        &--section {
            width: 100%;
            margin-bottom: 0;

            & > * {
                margin-bottom: 1rem;
            }
        }
    }
}

@mixin modal-gray {
    .modal {
        &__header {
            background: variables.$white;

            .icon {
                margin: auto 0;
            }
        }

        &__body,
        &__footer {
            background: variables.$neutral-lighter-25;
        }
    }
}

@mixin modal-general {
    .modal {
        &__title {
            font-size: variables.$font-size-xl;
            margin: auto auto auto 0;
            padding-right: 30px;

            .title {
                color: variables.$blue-500;
            }

            .product-name {
                padding-top: 4px;
                color: variables.$blue-500;
                font-weight: variables.$font-weight-normal;

                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

        &__footer {
            display: flex;
            justify-content: center;
            padding-top: 8px;
            font-weight: variables.$font-weight-bold;

            .continue-shopping {
                margin: auto;
                font-size: variables.$font-size-default;

                a:before {
                    content: "< ";
                    font-size: variables.$font-size-lg;
                }
            }

            .create-order {

                .button {

                    &__text {
                        font-size: variables.$font-size-default;
                    }
                }
            }
        }
    }
}

@mixin ghost-button {
    background-color: transparent;
    min-width: unset;
    border: 0;

    &:hover {
        background-color: transparent;
        color: variables.$primary-dark;
    }

    &:active {
        background-color: transparent;
    }

    &:disabled {
        background-color: unset;
        color: variables.$primary-lighter;
    }
}

@mixin medium-button {
    min-width: $mediumButtonMinWidth;
    height: 48px;

    .button__text {
        font-size: $mediumFontSize;
        line-height: $mediumLineHeight;
    }
}

@mixin quaternary-button {
    background-color: variables.$primary-dark;
    color: variables.$secondary;
}

@mixin inverted-button {
    background-color: variables.$primary;
    color: variables.$secondary;
}

@mixin button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$secondary;
    color: variables.$primary;
    font-size: $largeFontSize;
    line-height: $largeLineHeight;
    border-radius: $borderRadius;
    border: unset;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: variables.$font-weight-bold;

    .button__text {
        font-size: $largeFontSize;
        pointer-events: none;
        padding-bottom: 8px;
        padding-top: 10px;
    }

    .icon {
        font-size: $mediumFontSize;
        pointer-events: none;
    }

    &:hover {
        background-color: variables.$secondary-dark;
    }

    &:disabled, &--disabled {
        background-color: variables.$secondary-light;
        color: variables.$primary-lighter;
        pointer-events: none;

        &:hover {
            background-color: variables.$secondary-light;
        }
    }

    &--square {
        border-radius: $borderRadiusSquareButton;
    }

    &--bold {

        .button__text {
            font-weight: variables.$font-weight-bold;
        }
    }

    &--secondary {
        background-color: variables.$white;
        border: solid 1px variables.$primary;

        &--gc {
            font-weight: 400;
            font-size: 16px;
            width: 100%;
        }

        &:hover {
            background-color: variables.$neutral-lighter-25;
            color: variables.$primary-dark;
        }
    }

    &--tertiary {
        background-color: variables.$neutral-lighter-50;

        &:hover {
            background-color: variables.$neutral-lighter-100;
            color: variables.$primary-dark;
        }

        &:disabled {
            background: variables.$neutral-lighter-25;
            color: variables.$neutral-lighter;
        }
    }

    &--pointer-events-none {
        pointer-events: none;
    }

    &--quaternary {
        @include quaternary-button;
    }

    &--inverted {
        @include inverted-button;
    }

    &--ghost {
        @include ghost-button;
    }

    &--ghost-medium {
        @include ghost-button;

        .button__text {
            font-size: $mediumFontSize;
            line-height: $mediumLineHeight;
        }
    }

    &--ghost-small {
        @include ghost-button;

        .button__text {
            font-size: $smallFontSize;
            line-height: $smallLineHeight;
        }
    }

    &--large {
        min-width: $largeButtonMinWidth;

        .button__text {
            font-size: $largeFontSize;
            line-height: $largeLineHeight;
        }
    }

    &--medium {
        @include medium-button;
    }

    &--small {
        min-width: $smallButtonMinWidth;

        .button__text {
            font-size: $smallFontSize;
            line-height: $smallLineHeight;
        }
    }

    &.full-width {
        width: 100%;
    }

    &[data-action="delete"] {

        &:hover {
            color: variables.$supporting-01-dark;
        }
    }

    &.link {
        background: none;
        border: none;
        padding: 0;
        /*input has OS specific font-family*/
        cursor: pointer;
    }
}

@mixin add-to-cart {
    display: none;
    float: right;
    .hover-toggle {
        pointer-events: none;
    }
    svg {
        width: 40px;
        height: 40px;
        pointer-events: none;

        use {
            pointer-events: none;
        }
    }
    &--active {
        display: block;

        circle {
            fill: variables.$primary;
            stroke: variables.$white;
        }

        path {
            fill: variables.$white;
        }
    }

    &:hover:not(.add-to-cart--active) {
        .hover-toggle {
            .d {
                display: none !important;
            }

            .d-none {
                display: inline !important;
            }
        }

        cursor: pointer;

        circle {
            fill: variables.$secondary-darker;
            stroke: variables.$neutral-dark;
        }

        path {
            fill: variables.$neutral-dark;
        }
    }
}

@mixin news-text-short {
    padding: 16px 24px 24px 24px;
    height: 100%;
}

@mixin responsive-category-menu-item {
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

@mixin menu-item-three-columns {
    @include responsive-category-menu-item;
    display: grid;
    grid-template-columns: fit-content(100%) minmax(0, 1fr) fit-content(100%);
    border: 1px solid variables.$neutral-lighter-100;
    border-radius: 8px;
    margin-bottom: 8px;
    grid-gap: 16px;
    background: variables.$white;
}

@mixin nav-icons {
    .icon-hover,
    .icon-expand {
        display: none;
    }

    &:hover {

        .icon-idle {
            display: none;
        }

        .icon-hover {
            display: block;
        }
    }
}

@mixin panel {
    display: flex;
    position: relative;
    background: variables.$white;
    /*border: solid 1px variables.$neutral-lighter;*/
    flex-direction: column;
}

@mixin panel-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: variables.$zindex-panel;
    transition: .3s ease-out;
}

@mixin panel__back {
    position: sticky;
    cursor: pointer;
    padding: 16px 0;
    color: variables.$primary;
    font-weight: normal;
    z-index: 1;
    background: variables.$white;
    top: 0;

    .icon {
        color: variables.$secondary;
        display: inline;
    }
}


@mixin scrollbars {
    // FF
    & {
        scrollbar-width: thin;
        scrollbar-color: variables.$secondary variables.$neutral-lighter;
    }
    /* width and height */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: variables.$neutral-lighter;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: variables.$secondary;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: variables.$secondary-darker;
    }
}

@mixin accordion {
    &--collapse {
        .accordion__arrow {
            position: relative;
            right: 0;
        }

        .accordion__arrow:hover {
            cursor: pointer;
        }

        .accordion__arrow:focus {
            box-shadow: none;
            outline: 0;
        }

        .accordion__title {
            font-weight: variables.$font-weight-normal;
        }

        .accordion__item {
            padding: 0 32px;
            background: variables.$neutral-lighter-25;
            margin: 0;
        }

        .accordion__content {
            padding: 0;
        }
    }

    &__title {
        padding: 14px 0;
        display: flex;
        position: relative;
        font-weight: variables.$font-weight-bold;
        justify-content: center;
    }

    &__content {
        padding: 0 24px 24px 24px;
    }

    &__arrow {
        position: absolute;
        right: 24px;
    }

    &__item {
        background: variables.$white;
    }
}

@mixin responsive-title {
    font-size: variables.$font-size-xl;
    font-weight: variables.$font-weight-normal;
    color: variables.$primary;
    text-transform: none;
}

@mixin panel-accordion {
    &--collapse {

        .accordion__arrow {
            position: relative;
            right: 0;
        }

        .accordion__arrow:hover {
            cursor: pointer;
        }


        .accordion__arrow:focus {
            box-shadow: none;
            outline: 0;
        }

        .accordion__title {
            font-weight: variables.$font-weight-normal;
        }

        .accordion__item {
            padding: 0 32px;
            background: variables.$neutral-lighter-25;
            margin: 0;
        }

        .accordion__content {
            padding: 0;
        }
    }

    &__title {
        padding: 14px 0;
        display: flex;
        position: relative;
        font-weight: variables.$font-weight-bold;
        justify-content: center;
    }


    &__content {
        padding: 0 24px 24px 24px;
    }

    &__arrow {
        position: absolute;
        right: 24px;
    }
}

@mixin lr-panel {
    display: flex;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    background: none;

    &.lr-panel--active {
        pointer-events: all;
        opacity: 1;
        z-index: variables.$zindex-panel;

        .tab-content--active {
            display: flex; // Product card tabs
        }
    }
    .panel-left {
        background: variables.$neutral-darker;
        opacity: .5;
        width: 100%;
    }

    .panel-right {
        position: absolute;
        inset: 0;
        box-sizing: border-box;
        background: variables.$white;
        overflow: auto;
        z-index: variables.$zindex-panel;
        opacity: 1;
        transition: left .3s ease-out;

        aside > * {
            margin: 16px;
        }

        &:not(.panel-right--active) {
            left: 100%;
        }
    }

    .accordion__content {
        padding: 0 24px 24px 24px;
    }

}

@mixin rental-periods {
    .rental-period-button {
        &.button {
            border-radius: 8px;
            border: 1px solid variables.$gray-200;
            width: 20%;
            font-weight: variables.$font-weight-normal;

            &:hover {
                background: variables.$white;
                border: 1px solid variables.$primary;
            }

            span {
                font-size: variables.$font-size-s;
                color: variables.$neutral-dark;
            }

            &.button--active {
                border: 2px solid variables.$secondary;
                font-weight: variables.$font-weight-bold;

                span {
                    color: variables.$primary;
                }
            }
        }

        &__container {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            margin: 20px 0;
            flex-wrap: wrap;
        }
    }
    @media(max-width: variables.$responsive-break) {
        .rental-period-button.button {
            width: 45%;
        }
    }
}