@use "../../variables";
@use "../../mixins";

.shipping {
    border-radius: 4px;

    &__titles {
        background: variables.$neutral-lighter-25;
        padding: 16px 24px;
    }

    &__title {
        color: variables.$neutral-darker;
        margin: 0;
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;
    }

    &__subtitle {
        color: variables.$neutral-dark;
        margin: 0;
        margin-top: 8px;
        font-size: variables.$font-size-sm;
    }

    &__price {
        font-weight: variables.$font-weight-bolder;
        font-size: variables.$font-size-md;
        text-align: right;
        white-space: nowrap;
        padding: 0 16px;
    }

    &__details {
        cursor: pointer;
        text-decoration: underline;
        color: variables.$primary;
    }

    &-list {
        &__delivery{
            width: 100%;
        }
        
        &__item {
            border-bottom: 1px solid variables.$neutral-lighter-100;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            font-size: variables.$font-size-sm;
            font-weight: variables.$font-weight-bold;
            color: variables.$neutral-darker;
        }

        &__name {
            padding-bottom: 8px;
        }

        &__time {
            color: variables.$primary;
            font-weight: variables.$font-weight-normal;
        }

        &__text {
            display: none;
        }
        &__icon{
            width:40px;
            padding-right: 16px;
        }

    }

    &-panel {
        // Overriding mixin styles in regular view
        .panel {
            overflow-y: hidden;
            width: initial;
        }

        .responsive-panel__back {
            display: none;
        }

        &.shipping.lr-panel .accordion .accordion__title {
            padding: 16px !important;
            justify-content: space-between !important;
        }

        &.shipping.lr-panel:not(.lr-panel--active)
        .accordion {
            .accordion__title {
                cursor: default;
                pointer-events: none;

                &::after {
                    content: "";
                }
            }

            .accordion__content {
                display: none;
            }

            .accordion__item[data-shipping-type-shop] {
                .accordion__title {
                    padding-bottom: 0;
                }

                .shipping__details {
                    padding: 8px 16px 16px;
                }
            }
        }
        // In panel view
        &.shipping.lr-panel.lr-panel--active {
            @include mixins.panel-wrapper;
            left:0;
            width:100%;
            .panel {
                overflow-y: auto;
            }

            .responsive-panel__back {
                @include mixins.panel__back;
                border-radius: 0 25px 25px 0;
                padding: 16px 16px 16px 0;
                display: initial;
            }

            .shipping {
                &__titles {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: variables.$white;
                }

                &__title {
                    color: variables.$primary;
                    font-size: variables.$font-size-lg;
                    font-weight: variables.$font-weight-normal;
                }

                &__subtitle {
                    color: variables.$neutral;
                    font-size: variables.$font-size-md;
                }

                &__details {
                    display: none;
                }

                &-list {
                    margin: 0 8px;

                    &__item {
                        border: solid 1px variables.$neutral-lighter-100;
                        border-radius: 4px;
                        margin-bottom: 8px;
                    }
                }
            }

            .accordion {
                @include mixins.accordion;
                & {
                    width: initial;
    
                    // Kauplusest kättesaamise tarnemeetodi liigutamine kõige viimaseks
                    display: flex;
                    flex-direction: column;
                }
                
                [data-shipping-type-shop] {
                    order: 9;
                }
                
                &__title {
                    justify-content: space-between;
                    padding: 16px 48px 16px 16px;
                    color: variables.$primary;
                    font-size: variables.$font-size-md;

                    .shipping-list__time {
                        font-size: variables.$font-size-sm;
                    }
                }

                &__content {
                    padding: 24px;
                    background-color: variables.$neutral-lighter-25;

                    .dropdown {
                        &__list {
                            position: static;
                            opacity: 1;
                            overflow: initial;
                            box-shadow: none;
                            max-height: unset;
                            border: none;

                            &-item {
                                display: flex;
                                justify-content: space-between;
                                color: variables.$primary;
                                font-size: variables.$font-size-md;
                                background: variables.$white;
                                border: 1px solid variables.$neutral-lighter-100;
                                border-radius: 4px;
                                margin-bottom: 8px;
                                padding: 16px;
                                white-space: normal;

                                p {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;
                                    color: variables.$primary;
                                }

                                &.disabled {
                                    background: inherit;
                                    border: none;
                                }

                                &__icon::before {
                                    color: variables.$neutral-lighter;
                                    font-size: variables.$font-size-xl;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .shipping {
        max-width: 280px;
        border: solid 1px variables.$neutral-lighter-100;

        &-panel {
            .panel {
                width: 100%;
            }
        }

        &-list__item:last-child {
            border-bottom: 0;
        }

        &.lr-panel.lr-panel--active {
            max-width: unset;
            margin-top: 0;
            left: 100%;
            width: 496px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .shipping .accordion {
        .accordion__content {
            padding: 24px 8px !important;
        }

        .dropdown__list-item {
            padding: 16px 8px;
        }
    }
}
