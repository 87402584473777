@use '../variables';

#btn-notify-page-error {
    position: fixed;
    bottom: 10px;
    left: 20px;
    width: auto;
    padding: 20px;
    min-width: auto;
    height: 48px;
    border-radius: 999rem;
    z-index: 9999;

    .icon-alert-error {
        height: 1.8rem;
    }

    .button__text {
        padding: 0 6px;
        font-size: variables.$font-size-lg;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    #btn-notify-page-error {
        bottom: 80px;
        padding: 10px;

        .button__text {
            display: none;
        }
    }
}