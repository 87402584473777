@use "../../variables";

.more-separator {
  display: flex;
  &__line {
    flex: 3;
    border-top: solid 1px variables.$neutral-lighter-100;
    margin-top: .5rem;
  }
  &__text {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
  }
}
