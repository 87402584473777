@use "../../variables";

.badge {
    display: inline-block;
    padding: 3px 0 1px 4px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 154px;
    font-size: 13px;
    line-height: 16px;
    font-weight: variables.$font-weight-normal;
    color: variables.$neutral-dark;
    
    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__label {
        margin-left: 8px;
        font-weight: variables.$font-weight-bold;
        flex-wrap: wrap;
    }

    .icon {
        padding-left: 0;
    }

    &--bonus {
        background: variables.$supporting-03;
        border: solid 1px variables.$supporting-03;
        color: variables.$white;
        font-weight: variables.$font-weight-bold;
        padding: 3px 16px 1px 16px;
    }

    &--danger-dot{
        background-color: variables.$supporting-01-dark;
    }

    &--primary-dot{
        background-color: variables.$secondary;
    }

    &--success-dot{
        background-color: variables.$supporting-03;
    }

    &--preorder-dot{
        background-color: variables.$ltblue-gradient;
    }
    
    &--inorder-dot{
        background-color: variables.$supporting-09;
    }
    
    &--points {
        background: variables.$supporting-03;
        color: variables.$white;
        font-weight: variables.$font-weight-bold;
        padding: 3px 16px 1px 16px;
    }
    @if variables.$site-nova {
        &{
            border: none;
        }
    }
    
    &--salesstatus {
        background: variables.$neutral-lighter;
        color: variables.$white;
        padding: 3px 12px 1px 12px;
        height: 16px;
        margin: auto 4px;
        max-width: 60px;
    }
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}