@use '../../variables';

.customer-service-panel,
.notify-page-error-panel {

    .panel {
        overflow-y: auto;
    }

    .feedback-title {
        i {
            color: variables.$secondary;
            font-size: variables.$font-size-xl;
        }

        span {
            font-size: variables.$font-size-lg;
        }
    }

    .product-availability-title {
        font-size: variables.$font-size-sm;
    }

    .info-email {
        align-items: center;
    }

    form {

        .row {
            @extend .m-t-16 !optional;
        }

        label {
            @extend .m-l-8 !optional;
        }

        .textarea {
            flex-direction: column;

            &__label {
                padding: 8px 0;
            }
        }

        textarea {
            height: 100px;
        }

        .required::after {
            content: '*';
        }
    }

    .links {
        .title {
            font-weight: variables.$font-weight-normal;
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .customer-service-panel,
    .notify-page-error-panel {
        font-size: variables.$font-size-default;
    }
}
