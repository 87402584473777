@use "../../variables";

.description-container {
    display: flex;
    min-height: variables.$description-block;

    &:after,
    &:before {
        content: "";
        flex: 1;
    }

    &--orange {

        &:after,
        &:before {
            background: variables.$supporting-01;
        }
    }

    &--blueorange {

        &:before {
            background: variables.$primary-light;
        }

        &:after {
            background: variables.$supporting-01;
        }
    }

    &--blue {

        &:after,
        &:before {
            background: variables.$primary-light;
        }
    }

    .container {
        display: flex;
        min-width: variables.$grid__bp-lg * 1px;
        max-width: variables.$grid__bp-lg * 1px;

        .row .col-6 {
            height: 100%;
            flex-direction: column;
            justify-content: center;
        }
    }

    img {
        object-fit: contain;
    }
}

.description__tab {
    padding: 20px 0;

    &.loadbee-content {
        padding: 0;
    }

    .loadbeeToggle {
        & + div.description-content {
            display: none;
        }

        &[style="display: none;"] + div.description-content {
            display: block;
            padding: 88px 0 88px 0;
        }
    }
}

@media screen and (max-width: variables.$grid__bp-lg * 1px) {

    .description-block {

        .container {
            margin: 0 10px;
        }
    }

    .description-container {

        .container {
            padding: 0;
        }
    }
}
