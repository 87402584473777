@use "../../variables";

.recently-viewed {

  &__title {
    color: variables.$neutral-darker;
    font-weight: variables.$font-weight-normal;
    margin-top: 24px;
  }

  &__list {
    .items-carousel__list {
        display: inline-flex;
    }
  }

  &--border-top {
    margin: 30px 0;
    padding-top: 24px;
    text-align: center;

    @media only screen and (max-width: variables.$responsive-break - 1) {
      border-top: solid 1px variables.$neutral-lighter-100;

      &:before {
        display: none;
      }
    }

    &:before {
      background: variables.$neutral-lighter-100;
      height: 1px;
      position: absolute;
      content: "";
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      height: 1px;
    }
  }
}
