@use "../../variables";

.comparison-sticky {
    display: none;
    align-items: center;
    background: variables.$white;
    box-shadow: 0 -2px 4px rgba(38, 47, 68, .25);
    position: relative;
    z-index: variables.$zindex-sticky;

    &__inner-container {
        width: variables.$responsive-break;
        margin: auto;
        display: flex;
        background: variables.$white;
        border: 1px solid #f1c400;
        padding: 8px 0;
    }

    .difference-div, .difference-sticky-div {
        width: 230px;
    }

    &--active {
        display: flex;
    }

    &__list {
        display: flex;
        width: auto;
        padding: 8px 0;
        justify-content: space-between;
        background: variables.$white;
    }

    &__img {
        width: 55px;
        height: 55px;
        padding: 5px;
    }

    &__item {
        display: flex;
        width: 230px;
        align-items: center;
        flex-wrap: wrap;

        &:hover {

            .comparison-sticky__close {
                display: block;
            }
        }

        > div {
            width: 100%;
        }
    }

    &__link {
        font-size: variables.$font-size-sm;
        font-weight: variables.$font-weight-bold;
        margin-left: 8px;
        color: variables.$primary;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        &:visited {
            color: variables.$primary;
        }
    }

    &__close {
        display: none;
        border: 0;
        height: 16px;
        width: 16px;
        margin-left: auto;
        margin-right: 8px;
        background: transparent;
        color: variables.$neutral-lighter-100;
        padding: 0;

        .icon {
            padding: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__replace {
        display: inline-block;
        margin-top: 8px;
        font-weight: variables.$font-weight-bold;
    }
}
