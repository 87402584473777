@use "../../variables";

@mixin tabs {
    .tabs-menu {
        align-items: center;
        width: 100%;

        &--category {

            .toggle {
                align-items: center;
                margin-left: auto;

                &__label {
                    font-size: variables.$font-size-sm;
                }
            }


            .fa-toggle-on {
                color: variables.$primary;

                &:after {
                    color: variables.$primary-lighter-50;
                    opacity: 1;
                }
            }

            .fa-toggle-off {
                color: variables.$primary;

                &:after {
                    color: variables.$primary-lighter-50;
                    opacity: 1;
                }
            }

            .button {
                padding: 0;
                margin-left: 8px;
            }

            .tabs-menu__item {

                &--hidden {
                    display: none;
                }

                &:last-child {
                    margin-left: 4px;
                }
            }

            .tabs-menu__link {
                font-size: variables.$font-size-sm;
                color: variables.$neutral-dark;
                padding: 8px;
                border-bottom: solid 3px transparent;
            }


            .dropdown {
                color: variables.$neutral-dark;
                border: 0;
                font-size: variables.$font-size-sm;
                width: 150px;

                .icon {
                    z-index: variables.$zindex-positive;
                }

                &__selected {
                    color: variables.$neutral-dark;
                    border-radius: variables.$border-radius-round;
                    z-index: variables.$zindex-positive;
                    line-height: 100%;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    border-bottom: solid 3px transparent;
                }

                .dropdown__selected:hover {
                    background: variables.$primary-lighter-10;
                }

                .dropdown__selected--active {
                    color: variables.$primary;
                    border-bottom: solid .1875rem variables.$secondary;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    font-weight: variables.$font-weight-normal;
                }

                .dropdown__selected.open {
                    border: 1px solid variables.$primary;
                    border-bottom: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &__title {
            color: variables.$neutral-darker;
            font-size: variables.$font-size-sm;
            margin-right: 8px;
        }

        &--wide {

            &:before {
                content: "";
                background: variables.$white;
                position: absolute;
                right: 0;
                left: 0;
                z-index: variables.$zindex-pseudo-bg;
            }
        }

        &__result {
            color: variables.$neutral-dark;

            .counter {
                font-weight: variables.$font-weight-bold;
            }
        }

        &__list {
            padding: 0;
            align-items: stretch;
            flex-wrap: wrap;

            &--transparent {
                background: transparent;
            }

            &--primary {
                width: 100%;
                background: variables.$white;
                display: flex;

                .tabs-menu__item {
                    flex: 1 1 100px;

                    &:hover {
                        background: variables.$neutral-lighter-25;
                    }
                }

                .tabs-menu__link {
                    color: variables.$primary;
                    font-weight: variables.$font-weight-normal;
                    font-size: variables.$font-size-default;
                    border-bottom: solid 4px transparent;
                    letter-spacing: 0.01em; // Nexa `li` ligature prevention

                    &:hover {

                        &:not(.tabs-menu__link--active) {
                            color: variables.$primary-dark;
                        }
                    }

                    &--active {
                        font-weight: variables.$font-weight-bold;
                        border-bottom: solid 4px variables.$secondary;
                    }
                }
            }
        }

        &__item {
            list-style: none;
            display: inline-flex;
            border-bottom: 0;

            &:first-of-type {

                .tabs-menu__link {
                    margin-left: 0;
                }
            }

            .icon {
                display: none;
            }

            &--active {
                .tabs-menu__link {
                    font-weight: variables.$font-weight-bold;
                    color: variables.$primary;
                    border-bottom: solid 3px variables.$secondary;
                }
                &:hover:not(.tabs-menu__item--active) {
                    .tabs-menu__link {
                        color: variables.$primary;
                        border-bottom: 3px solid variables.$primary-lighter-50;
                    }
                }
            }
            
            &:hover {
                cursor: pointer;
            }
        }

        &__link {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            text-align: center;
            position: relative;


            &:after {
                content: attr(data-amount);
                position: relative;
                top: -10px;
                font-size: variables.$font-size-xs;
                font-weight: variables.$font-weight-bold;
            }
        }

        &__cart {
            display: flex;
            padding-left: 10px;
            padding-bottom: 0;
            align-items: center;
            border-left: solid 1px #f3f3f3;

            img {
                max-height: 37px;
                max-width: 37px;
            }

            .button {
                height: 100%;
                border-radius: 0;

                .icon {
                    font-size: variables.$font-size-lg;
                }

                &__text {
                    font-size: variables.$font-size-default;
                    font-weight: variables.$font-weight-bold;
                }
            }
        }

        &__content {
            order: 99;
            padding: 0;
        }

        .price {
            font-size: variables.$font-size-default;
            margin: 0 16px 0 8px;
        }

        .responsive-sort {
            display: block;
            grid-row: 1;
            overflow: auto;

            .scroll-category {
                display: none;
            }

            .sort-menu {
                width: 100%;

                h2 {
                    display: none;
                }

                &__list {
                    margin: 0;
                    //hide all elements
                    .sort-menu__item {
                        display: none;

                        button {
                            display: none;
                        }
                    }

                    //show category elements
                    .sort-menu__item {
                        margin: 0;

                        .sort-menu__sub {
                            display: flex;
                            justify-content: space-between;
                            margin: 0;
                            padding: 0;
                            height: 50px;
                        }

                        &.category-jumplist {
                            display: block;

                            li:first-child {
                                margin-left: auto;
                                padding-left: 16px;
                                border: 0;
                            }

                            li:last-child {
                                margin-right: auto;
                                padding-right: 16px;
                            }

                            li {
                                display: block;
                                border-left: 1px solid variables.$neutral-lighter-50;

                                div {
                                    margin: auto;
                                    padding: 0;
                                    height: 100%;
                                }

                                .custom-radio {
                                    white-space: nowrap;

                                    &.checked {
                                        background-color: variables.$primary-lighter-10;
                                    }

                                    &__label {
                                        padding: 0 24px;

                                        &::before {
                                            content: none;
                                        }

                                        &::after {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    
}

// Use TabType.SameForMobile to keep the tabs design same in desktop and mobile.
@media (max-width: variables.$responsive-break) {
    .same-for-mobile{
        @include tabs;
    }
}

// Styling for desktop only.
@media (min-width: variables.$responsive-break) {
    @include tabs;
}


@media all and (-ms-high-contrast: none) {

    .tabs-menu__list--primary {


        .tabs-menu__link {

            &:hover {

                &:not(.tabs-menu__link--active) {
                    text-shadow: -.5px 0 .3px variables.$primary, .5px 0 .3px variables.$primary, -.5px 0 .3px variables.$primary, .5px 0 .3px variables.$primary
                }
            }
        }
    }
}


@media (max-width: variables.$responsive-break - 1) {
    .tabs-menu--vertical {
        .tabs-menu {
            &__item {
                width: 100%;
                background-color: #F7FAFF;
                border: 1px solid variables.$primary-lighter-10;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &--active {
                    background: variables.$primary-lighter-10;
                }

                .icon {
                    color: variables.$primary;
                    padding: 16px;
                }
            }

            &__link {
                color: variables.$primary;
                width: 100%;
                padding: 16px;

                &:after {
                    position: absolute;
                    right: 16px;
                }

                &--active:after {
                    content: "-";
                }

                &:not(.tabs-menu__link--active):after {
                    content: "+";
                }
            }
        }
    }
}