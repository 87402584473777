@use '../variables';
@use "../content-templates.scss"; // not LT

.staticContentContainer {
    .giftcard {
        &__description {
            grid-area: description;
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            padding-bottom: 30px;

            @media(max-width: variables.$responsive-break - 1) {
                font-size: variables.$font-size-md;
            }
        }

        &__contact-info {
            grid-area: inputs;
        }

        &__message {
            grid-area: message;
            align-self: end;

            textarea {
                resize: none;
                height: 100%;
                width: 100%;
            }
        }

        &__sum {
            grid-area: sum;
        }

        &__buttons {
            grid-area: buttons;

            .button {
                width: 100%;
            }
        }

        &__container {
            display: grid;
            grid-template-columns: 3fr  3fr;
            grid-template-areas:
            "header header"
            "description description"
            "inputs sum"
            "inputs message"
            "buttons buttons";

            column-gap: 32px;
            justify-items: stretch;

            .textarea__container {
                flex-direction: column-reverse;
            }

            h1 {
                padding-bottom: 6px;
                font-size: 25px;
                line-height: 30px;
                text-align: left;
                font-weight: 800;
            }

            @media(max-width: variables.$responsive-break - 1) {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-areas:
                      "header"
                      "description"
                      "inputs"
                      "message"
                      "sum"
                      "buttons";
            }

            &__message,
            &__sum,
            &__buttons {
                margin-top: 16px;
            }
        }

        .input-field {
            &--vertical {
                align-items: start;
                flex-direction: column;
            }

            &__label {
                font-size: variables.$font-size-md;
            }

            &__input {
                border: unset;
                background: variables.$neutral-lighter-25;
            }
        }

        .textarea {
            &__label {
                padding: 0;
                font-size: variables.$font-size-md;
            }

            &__input {
                border: unset;
                background: variables.$neutral-lighter-25;
            }
        }
    }
}

