@use "alerts/alerts";
@use "autocomplete/autocomplete";
@use "checkbox/checkbox";
@use "dropdown/dropdown";
@use "input-field/input-field";
@use "radio/radio_button";
@use "radio/radio_tabs";
@use "switch/switch";
@use "textarea/textarea";
@use "date_input/date_input";

form {
  width: 100%;
}

.form-captcha-invalid [type=submit] {
    display: none;
}