@use "../variables";

.content-sidebar {
    background-color: #F1F2F6;
    width: 100%;
    padding: 0 32px 32px 32px;

    &__list {


        .list-item {
            display: flex;
            margin-top: 32px;
        }
    }

    &__link {
        width: 100%;
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;

        &--active {
            border-bottom: solid 2px variables.$secondary;
            color: variables.$primary;
            font-size: 16px;
        }
    }
}
