@use "../../variables";

.specification {
    background: variables.$neutral-lighter-25;
    position: relative;


    &__item {
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        -o-column-break-inside: avoid;
        -ms-column-break-inside: avoid;
        break-inside: avoid;
        padding-top: 40px;
    }

    &__tab {

        > h2 {
            padding-top: 20px;
        }
    }

    &__row {
        display: flex;
        padding: 5px 0;
        border-bottom: solid 1px variables.$neutral-lighter-100;
    }

    &__heading {
        font-size: variables.$font-size-lg;
    }

    &__title,
    &__value {
        flex-basis: 50%;
    }

    &__title {
        display: flex;
        font-size: variables.$font-size-sm;
        color: variables.$neutral-dark;
        align-items: center;
        line-height: 1.4;

        .icon {
            color: variables.$neutral;
        }
    }

    &__value {
        font-size: variables.$font-size-sm;
        color: variables.$neutral-darker;
        justify-content: flex-end;
        display: block;
        text-align: right;
        line-height: 1.4;
        margin: auto;

        .icon {
            color: variables.$neutral;
        }
    }

    .icon {
        padding: 0 0 0 8px;
    }
}

a.link--primary.product-link {
    text-decoration: none;
    line-height: 24px;
}

@media(min-width:variables.$responsive-break){
    .specification {
        padding-bottom: 88px;

        &__list {
            column-count: 3;
        }

        &__item {
            padding-right: 20px;
        }
    }
   
}
@media(max-width:variables.$responsive-break - 1){
    .specification {

        &__value {
            word-break: break-all;
        }

        .responsive-panel__back .icon {
            padding: 0 .5rem;
        }
    }
}