@use "../../variables";

.additional {
  display: flex;
  
  &__item {
    display: flex;

    &:not(:first-of-type) {
      border-left: solid 1px variables.$neutral-lighter;
    }

    &:first-of-type {

      .icon {
        padding-left: 0;
      }
    }
  }

  .icon {
    align-items: center;
    color: variables.$secondary;
  }

  &__title {
    margin: 0;
    margin-bottom: 4px;
    color: variables.$neutral-darker;
    font-weight: variables.$font-weight-bold;
  }

  &__content {
    flex-direction: column;
    padding: 0 8px;
  }

  &__link {
    color: variables.$primary;

    &:visited {
      color: variables.$primary;
    }
  }
}