@use "../../variables";

#product-review-panel-rating .review-rating__stars .rating-star{
    $rating-star-size: 32px;
    background-size: $rating-star-size $rating-star-size;
    width: $rating-star-size;
    height: $rating-star-size;
    display: inline-flex;
}

.review-rating {
    max-width: 400px;
    text-align: center;

    &__title {
        color: variables.$neutral-darker;
    }

    &__stars {
        display: flex;

        button {
            font-size: inherit;
            background: transparent;
            border: unset;
            padding: 0;

            .rating-star {
                $rating-star-size: 14px;
                background-size: $rating-star-size $rating-star-size;
                width: $rating-star-size;
                height: $rating-star-size;
                display: inline-flex;

                &__unfilled {
                    background-image: url('/images/rating_star.svg');
                }

                &__filled {
                    background-image: url('/images/rating_star_filled.svg');
                }
            }
        }

        .icon {
            padding: 0;
            transform: rotate(15deg);
            color: variables.$neutral;
        }

        .checked {
            color: variables.$secondary;
        }
    }


    &__average {
        font-size: 40px;
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
        margin: 0;
    }

    &__reviews {
        color: variables.$primary;
    }

    &__desc {
        color: variables.$neutral;
        font-size: variables.$font-size-sm;
    }
}

.scorecard {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;

    &__row {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }

    &__stars {
        flex-basis: 15%;
    }

    &__star {
        color: variables.$secondary;
        transform: rotate(15deg);
        padding: 0;
    }

    &__bar {
        flex-basis: 70%;
        margin: 0 16px;
    }

    &__amount {
        flex-basis: 15%;
    }

    &__number {
        font-weight: variables.$font-weight-bold;
    }

    &__amount,
    &__number {
        color: variables.$black;
        font-size: variables.$font-size-sm;
    }
}

.rating-count {
    color: variables.$primary;
    padding-left: 5px;
}

@media (max-width: variables.$responsive-break - 1) {
    #rating-stars {
        justify-content: center;
    }
}

@media (min-width: variables.$responsive-break) {
    #rating-stars {
        display: inline-flex;
        cursor: pointer;
        
        button {
            cursor: pointer;
        }
    }
}