@use "../../variables";
.accordion {
    width: 100%;

    &__arrow {
        transition: .3s ease;
    }

    &-toggle-handle {
        .accordion__item {
            position: relative;

            .accordion__title::after {
                content: '+';
                position: absolute;
                right: 8px;
                font-size: variables.$font-size-3xl;
                font-weight: variables.$font-weight-normal;

                 //Due to font differencies
                @if variables.$site-nova-or-avi {
                    padding-bottom: 6px;
                    right: 4px;
                }
            }

            &--active .accordion__title::after {
                content: '-';

                // Due to font differencies
                @if variables.$site-nova-or-avi {                    
                    right: 8px;
                }
            }
            
            &--chevron {
                .accordion__title::after {
                    content: '';
                }
                &.accordion__item--active .accordion__title {
                    &::after {
                        content: '';
                    }
                    
                    .chevron {
                        transition: .3s ease;
                        transform: rotate(180deg);
                    }
                }
            }            
            
        }
    }

    &__item {
        overflow: hidden;
        list-style: none;
        margin-bottom: 8px;
        
        &--active {
            overflow: visible;

            > .accordion__title .accordion__arrow {
                transition: .3s ease;
                transform: rotate(180deg);
            }

            > .accordion__content {
                display: block;
            }
        }
    }

    &__title {
        color: variables.$primary;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__content {
        display: none;
        padding: 16px 0;
    }
}


.accordion__item {

    &--large {

        .accordion__title {
            font-size: variables.$font-size-lg;
        }
    }

    &--medium {

        .accordion__title {
            font-size: variables.$font-size-default;
        }
    }

    &--small {

        .accordion__title {
            font-size: variables.$font-size-sm;
        }
    }
}
