@use "../../variables";
@use "../../mixins";

.product-card {
    display: flex;
    flex-direction: column;
    border-style: none solid solid none;
    border-color: variables.$neutral-lighter-100;
    border-width: 1px;
    width: 100%;
    position: relative;
    padding: 15px 32px 20px 32px;
    justify-content: space-between;

    .product_name {
        flex-grow: 1;
    }

    .product-card__overlays .campaign-label {
        display: flex;
    }

    .content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__bonus {

        .more-points {
            display: none;
            color: variables.$supporting-01-dark;
            font-size: variables.$font-size-sm;
            font-weight: variables.$font-weight-bold;
        }

        &--active {

            .content {
                opacity: .7;
            }

            .more-points {
                display: block;
            }
        }
    }

    &:hover {

        .product-card__image {
            transform: scale(1.05);
        }
    }

    &__image {
        transition: ease-in-out .5s;
    }

    &.landscape {
        padding-left: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        flex-direction: row;
        width: 100%;
        border-style: none none solid none;
        border-top: solid 1px transparent;

        &:hover {
            border-top: solid 1px variables.$primary-light;
            border-bottom-color: variables.$primary-light;
        }

        .product-card__overlays {
            top: 16px;
        }
    }

    &:hover:not(.landscape) {

        .product-card__title {
            color: variables.$primary-dark;
        }

        .tooltip__button {
            display: block;
        }

        .add-to-cart {
            display: block;

            button {
                display: none;
            }
        }
    }

    &__title {
        color: variables.$primary;
        line-height: variables.$paragraph-lh;

        &:visited {
            color: variables.$primary;
        }
    }

    &__link {

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            z-index: variables.$zindex-product-overlays;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        border-style: solid none none solid;
        border-color: variables.$neutral-lighter-100;
        border-width: 1px;
        width: 100%;
        margin-bottom: 25px;
        position: relative;

        &--landscape {
            border-style: solid none none none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        .badge__label {
            display: none;
        }
    }

    &__colors {
        position: absolute;
        right: 24px;
        bottom: 27px;
        display: flex;

        .icon-circle {
            width: 20px;
            height: 20px;
            margin-right: -8px;
        }
    }

    &__heading {
        color: variables.$primary;
        font-size: variables.$font-size-default;
        margin-top: 8px;
    }

    &__image-wrapper {
        position: relative;
        text-align: center;
    }

    &__availability {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }

    &__description {
        display: none;
    }

    &__price {
        margin-top: 8px;
        height: 110px;
        align-content: center;

        .price {
            font-size: variables.$font-size-2xl;
            color: variables.$neutral-darker;
            font-weight: variables.$font-weight-bold;
            line-height: 100%;

            .discount__price,
            .discount__old {
                line-height: 100%;

                @if(variables.$site-nova){
                    margin-top: 4px;
                }

                .label{
                    font-weight: variables.$font-weight-bold;
                    text-decoration: line-through;
                }

                &__loyal .label {
                    text-decoration: none;
                }

                @extend .del !optional;

                @if variables.$site-nova-or-avi {
                    .label {
                        font-weight: variables.$font-weight-normal;
                        color: variables.$neutral;
                        
                        @if(variables.$site-nova){
                            color: variables.$neutral-dark;
                        }
                    }
                }
            }

            .discount__old {
                position: relative;
            }

            .discount__old:before {
                top: 42%;
            }

            .cp {
                font-size: 50%;
                line-height: variables.$font-size-default;
                @if variables.$site-nova-or-avi {
                    vertical-align: super;
                }
            }
        }

        .monthly-payment {
            font-size: variables.$font-size-sm;
            line-height: 100%;
            margin: 6px 0;
            color: variables.$neutral-dark;
        }

        &--discount {

            .price,
            .monthly-payment {
                color: variables.$supporting-01;
            }
        }

        .label {
            font-size: variables.$font-size-s;
            font-weight: variables.$font-weight-normal;
        }

        .button {
            display: none;
            font-weight: variables.$font-weight-bold;
        }
    }
    
    .product-card__price--discount .price{
            margin-bottom: unset;
    }

    .card-label {
        padding: 4px 10px;
        text-transform: uppercase;
        float: left;
        line-height: 20px;
        font-size: variables.$font-size-xs;
        max-width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &--client {
            background-color: variables.$supporting-05;
            color: variables.$supporting-09;
        }

        &--sale {
            background-color: variables.$supporting-09;
            color: variables.$supporting-05;
        }
    }

    .icons-wrapper .campaign-tooltip i {
        z-index: variables.$zindex-tooltip;
    }


    .label-text {
        background: variables.$primary-lighter-10;
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;
        padding: 8px 15px;
        margin-left: -15px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        z-index: variables.$zindex-positive;
        max-width: 70%;

        @if (variables.$site-nova) {
            background: variables.$neutral-lighter-25;
        }
    }

    .overview-producer {
        font-size: 13px;
        display: block;
        margin-bottom: 8px;

        .value {
            color: variables.$neutral-dark;
            font-weight: variables.$font-weight-normal;
        }

        &__code {
            border-right: 0;

            .badge + .value {
                margin-left: 8px;
            }
        }
    }

    .grid-label {
        &--client {
            fill: variables.$primary;
        }

        &--sale {
            fill: variables.$secondary;
        }
    }
    
    .add-to-cart {
        margin-right: -20px;
        @include mixins.add-to-cart;
        
        button {
            display: none;
        }
    }

    .add-to-cart-container.loyalty .nova-head {
            width: 50px;
            position: absolute;
            right: 5px;
            bottom: 100px;
    }

    .tooltip__wrapper {
        display: block;
        margin-bottom: 5px;
    }
}

.vertical {

    .product-card {

        &__comparison {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .compare-landscape {
                display: none;
            }

            .save {
                display: none;
            }

            .tooltip {
                &__wrapper {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    z-index: variables.$zindex-product-card-overlays;

                    &:hover .icon {
                        color: variables.$neutral-dark;
                    }
                }

                &__button {
                    display: none;
                    height: 40px;
                    width: 40px;
                    border-radius: variables.$border-radius-round;
                    border: solid 1px variables.$comparison-border;

                    &--active {
                        display: block;
                    }

                    &:hover:not(.tooltip__button--active) {
                        color: variables.$neutral-dark;
                        background: variables.$tooltip-background-hover;
                        border: solid 1px variables.$neutral;
                    }
                }
            }


            &.favorite {
                top: 45px;
            }
        }

        &__availability {
            margin-top: auto;
        }

        &__price {
            justify-content: flex-end;
        }
    }
}

.item {
    display: flex;
    margin: 5px 0;

    &-label {
        flex-basis: 50%;
        color: variables.$neutral-dark;
        font-size: variables.$font-size-sm;
    }

    &-description {
        color: variables.$neutral-darker;
        font-size: variables.$font-size-sm;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.product-card__overlays {
    position: absolute;
    top: 16px;
    left: 0;
    color: variables.$white;
    font-weight: variables.$font-weight-bolder;
    z-index: variables.$zindex-positive;
    width: 100%;

    .label-without-icon {
        margin-bottom: 5px;
    }

    :not(:first-of-type) {
        margin-top: 5px;
    }

    &.rating {
        position: relative;
        top: 0;
    }

    .rating {
        position: relative;
        width: 62px;
    }
}

.product-card:not(.comparison) {
    .product-card__overlays.rating {
        margin-left: -32px;
    }
}

.product-card {
    justify-content: center;
    position: relative;

    .nova-head.in-comparison {
        float: right;
        width: 50px;
        margin-top: -60px;
    }
}

.discount-overlay {
    position: absolute;
    z-index: variables.$zindex-positive;

    &--right {
        right: 10px;
        top: 25%;
    }


    &--left {
        left: 0;
        top: 45%;
    }
}

.similar-product {
    background: variables.$primary-lighter-50;
    padding: 16px 24px;
    width: inherit;

    .title {
        color: variables.$neutral-darker;
        margin: 0;
        font-size: 16px;
        font-weight: 700;
    }
}

.product-not-available {
    margin-top: 25px;

    .alert {
        width: 100%;
    }
}

svg.rating text {
    fill: variables.$white;
    font-weight: variables.$font-weight-bold;
    font-family: variables.$font-family;
    font-size: variables.$font-size-xl;
}

.rating-6 path {
    fill: #00A651;
    stroke: #00A651;
}

.rating-5 path {
    fill: #50B848;
    stroke: #50B848;
}

.rating-4 path {
    fill: #BFD730;
    stroke: #BFD730;
}

.rating-3 path {
    fill: #FFF200;
    stroke: #FFF200;
}

.rating-2 path {
    fill: #FDB913;
    stroke: #FDB913;
}

.rating-1 path {
    fill: #F37021;
    stroke: #F37021;
}

.rating-0 path {
    fill: #ED1C24;
    stroke: #ED1C24;
}

.rating-a {
    fill: #00a651;
}

.rating-b {
    fill: #51b848;
}

.rating-c {
    fill: #bed731;
}

.rating-d {
    fill: #fcee23;
}

.rating-e {
    fill: #fcb918;
}

.rating-f {
    fill: #f37021;
}

.rating-g {
    fill: #ed1c24;
}

.product__info {
    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 14;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-right: 10px;
    }

    .read__more {
        display: block;
        text-align: center;
    }

    &--expanded {
        .product__info__text {
            display: block;
        }

        .read__more {
            display: none;
        }
    }
}

.monthly-payment {
    min-height: 14px;
}

@media (max-width: variables.$responsive-break - 1) {

    .product-card:not(.comparison) {

        &:hover:not(.landscape) {
            .product-card__image {
                transform: none;
            }
        }

        .discount-overlay--right {
            grid-column: 2;
            grid-row: 4;
            right: 8px;
            top: 0;
        }

        display: grid;
        grid-template-columns: 40% 60%;
        background: variables.$white;
        border: 1px solid variables.$neutral-lighter-100;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0;
        margin: auto;
        height: 100%;

        .product-card__overlays {
            position: relative;
            grid-column: 1;
            grid-row: 1 /span 3;
            top: 0;
            margin: 0 auto auto 0;
            width: 100%;

            &.campaign {
                margin: 16px auto auto 0;
                width: 100%;

                .campaign-label {
                    display: grid;
                    grid-auto-flow: column;
                }
            }

            &.rating {
                margin: 120px auto auto 0;
                width: auto;
            }
        }

        .card-label {
            max-width: 100%;
        }

        .product-card__image-div {
            grid-column: 1;
            grid-row: 1 / span 3;
            padding: 5px;
        }

        a.product_name {
            grid-column: 2;
            grid-row: 1;
            padding: 4px 8px 0 8px;
        }

        .review-rating__stars {
            grid-column: 2;
            grid-row: 2;
            padding-left: 8px;
        }

        .producer-availability {
            grid-column: 2;
            grid-row: 3;
            padding-left: 8px;
            margin-bottom: 8px;
        }

        .product-card__comparison {
            display: block;
            position: relative;
            grid-column: 1;
            grid-row: 5;
            top: 0;
            margin-right: auto;

            &.favorite {
                display: block;
                position: relative;
                grid-column: 1;
                grid-row: 5;
                top: 0;
                left: 50px;
            }

            .tooltip__wrapper {
                top: 0;

                &.compare {
                    position: relative;
                    right: 0;
                    margin: 8px 4px 8px 16px;

                    button {
                        display: block;
                        border: solid 1px variables.$comparison-border;
                    }
                }
            }
        }

        .product-card__price {
            grid-column: 2;
            grid-row: 4;
            padding-left: 8px;
            margin: 0;

            .price {
                display: block;

                .discount {
                    margin: 0;
                }
            }

            .add-to-cart {
                display: none;
            }
        }

        .add-to-cart-container.loyalty .nova-head {
            width: 50px;
            position: absolute;
            right: 15px;
            bottom: 55px;
        }
        
        .btn-addtocart {
            display: block;
            grid-column: 2;
            grid-row: 5;
            margin: auto;
            height: 40px;
            position: absolute;
            right: 18px;
            bottom: 10px;

            .add-to-cart {
                display: block;
                margin-top: -7px;
                margin-right: 6px;

                svg {
                    width: 47px;
                    height: 47px;
                }
            }
        }
    }

    .free-campaign-label {
        display: none;
    }
}

@media (min-width: variables.$responsive-break) {
    .product-card {
        &__heading {
            margin-bottom: 8px;
        }
        .add-to-cart{
            position: absolute;
            right: 30px;
            bottom: 55px;
        }
        
        .free-campaign-label {
            display: flex;
            align-items: center;
            margin-left: 3px;

            .label-text:not(.show-always) {
                display: none;
            }

            &:hover .label-text {
                display: initial;
            }
        }
        &:not(.comparison){
            .product-card__overlays.rating .energy-rating > a{
                position: absolute;
                margin-top: -38px;
            }
        }
        &__price .price{
            margin-bottom: 25px;
            &.loyalty {
                margin-bottom: 0;
            }
        }
    }
}

.product-card-gray-link {
    color: variables.$neutral-dark;
    text-decoration: none !important;
    font-size: variables.$font-size-s;

    .icon {
        padding-left: 0px;
        margin-top: 6px;
    }
}

@if variables.$site-nova-or-avi {
    .product-card {
        display: flex;
        flex-direction: column;
        background-color: variables.$white;

        width: 100%;
        position: relative;
        padding: 15px 32px 20px 32px;
        box-shadow: variables.$default-box-shadow;
        margin: 12px;

        &__list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 25px;
            position: relative;
            border: none;
        }
    }
}