@use "variables" as faVariables;

// Stacked Icons
// -------------------------

.#{faVariables.$fa-css-prefix}-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: (faVariables.$fa-fw-width*2);
}

.#{faVariables.$fa-css-prefix}-stack-1x,
.#{faVariables.$fa-css-prefix}-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.#{faVariables.$fa-css-prefix}-stack-1x {
  line-height: inherit;
}

.#{faVariables.$fa-css-prefix}-stack-2x {
  font-size: 2em;
}

.#{faVariables.$fa-css-prefix}-inverse {
  color: faVariables.$fa-inverse;
}
