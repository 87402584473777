@use "../../variables";

@mixin blue-footer {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 268px, 100%;
}

.footer__sub {
    background: variables.$secondary;
    align-items: center;
    color: variables.$neutral-darker;

    .footer__content {
        display: flex;
        align-items: center;
        padding: 16px 0;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 50px;

        @media (max-width: variables.$responsive-break - 1) {
            flex-direction: column;

            label {
                justify-content: inherit;
            }
        }
    }

    .input-field__label {
        font-size: variables.$font-size-md;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        flex: unset;
        color: variables.$primary;
    }

    .input-field {

        &__input {
            height: 32px;
            padding: 8px;
            margin: 8px;
            font-size: variables.$font-size-sm;
            max-width: 350px;
            width: 100%;
            border: 0;
            border-radius: 2px;

            @media (max-width: variables.$responsive-break - 1) {
                max-width: 60vw;
                flex-direction: column;

                label {
                    justify-content: inherit;
                }
            }
        }
    }

    .button {
        margin: 10px;
        border: solid 1px variables.$primary;
        height: 32px;
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-md;
        padding: 0 30px;

        &:hover {
            background-color: variables.$primary;
            color: variables.$white;
        }
    }
}

.footer__main {
    position: relative;
    overflow: hidden;
    color: variables.$white;
}

.footer__title {
    margin: 0;    
    h3{
        color: variables.$white;
    }
}

.footer__list {
    padding: 0;
    list-style: none;
}

.footer__list-item {
    a {
        line-height: 21px;
        color: variables.$white;
        font-size: variables.$font-size-sm;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__phone {
    font-size: variables.$font-size-xl;
    font-weight: variables.$font-weight-bold;
    position: relative;
    margin-bottom: 16px;

    a {
        color: variables.$white;
    }
}

.footer {
    clear: both;

    &__logos {
        display: flex;
        align-items: center;
        justify-content: right;
    }

    &--dark {

        .footer__main {
            background: variables.$neutral-darker;
        }
    }
    
    &__whatsapp {
        display: none;
    }

    .logo-brand {
        @if variables.$site-avi {
            content: url('/images/avitela/logo.svg');
        }
    }
    
    &__title {
        .icon{
            display: none;
        }
    }
}

.logo-uefa {
    width: 20%;
    padding-right: 50px;
}

.logo-brand {
    width: 139px;
    @if variables.$site-avi {
        height: 72px;
    } @else {
        height: 24px;
    }
}

.icon-phone {
    position: relative;
    top: 3px;
    content: url('/images/phone_icon.svg');
}

.socials {
    display: flex;
    list-style: none;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
    margin: 8px;
    &__item {
        margin: 0 16px;
    }
    &-mobile{
        display: none;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .footer {
        .panel-list__first-level-item{
            &:not(:first-of-type) {
                border-top: 1px solid variables.$primary;
            }
        }
        
        &:not(.panel-list__first-level-item--active) &__list {
            display: none;
        }
        
        &__links{
            .panel-list__first-level-item--active{
                .icon {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
                
                .footer__list {
                    display: grid;
                }

                h3{
                    font-weight: bold;
                }
            } 
        }
        
        &__main {
            background: variables.$primary-dark;
            text-align: center;
        }

        &__title {
            text-transform: uppercase;
            width: 100%;
            text-align: left;
            font-weight: 400;
            margin: 16px 40px;
            display: flex;

            .icon{
                display: flex;
                position: absolute;
                right: 20px;
                color: variables.$primary-light;
                -webkit-transition: all .5s ease;
                transition: all .5s ease;
                @if (variables.$site-nova-or-avi) {
                    color: variables.$primary-lighter;
                }
            }

            h3{
                font-weight: normal;
            }
        }

        &__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2px;

            &-item {
                text-align: center;
                background: variables.$primary;

                a {
                    display: block;
                    padding: 16px;
                }
            }
        }

        &__logo {
            display: none;
        }

        &__text {
            background: variables.$primary-dark;
            color: variables.$white;
            line-height: 30px;
            padding-bottom: 20px;
        }

        &__contacts {
            @include blue-footer;
            background-color: variables.$primary-dark;

            .footer {
                &__service, &__phone, &__working-hours {
                    margin: 16px;
                }
                
                &__whatsapp {
                    display: block;
                    margin: 16px auto;
                }

                &__stores-and-service-buttons {
                    display: flex;
                    margin: 16px 8px;
                    justify-content: center;
                    
                    .button--inverted{
                        color: variables.$white;
                    }

                    .button {
                        margin: 4px;
                        min-width: 250px;
                    }
                }
            }
        }

        .socials {
            display: none;
            &-mobile{
                display: flex;
                margin-top: 30px;
            }
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .footer {
        &__main {
            padding: 40px 0;
            @include blue-footer;
            background-color: variables.$primary;
        }

        &__top {
            display: flex;
        }

        &__list {
            margin-top: 16px;
        }

        &__links {
            display: flex;
            flex-basis: 60%;
            justify-content: space-between;
        }
        
        &__contacts {
            margin-left: auto;
        }

        &__service, &__working-hours, &__stores-and-service-buttons {
            display: none;
        }

        &__bottom {
            margin-top: 32px;
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
            align-items: center;

            p {
                color: variables.$white;
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .footer__top{
        display: flex;
        flex-direction: column-reverse;
    }
}

@media (max-width: variables.$mobile-break - 1) {
    .footer {
        &__stores-and-service-buttons {
            flex-direction: column;
        }
    }
}

@if variables.$site-nova {
    @media (min-width: variables.$responsive-break) {
        .footer {
            padding: 40px 0 0 0;
            background: url('/images/novastar/background_planets.png') variables.$primary-dark no-repeat right bottom;

            &__main {
                background: 60vw bottom;
                background-size: 175px;
                
                .novanaut {
                    transform: rotate(15deg);
                    position: absolute;
                    left: 59vw;
                    bottom: 15px;
                    height: 260px;
                }
            }
        }
    }

    @media (max-width: variables.$responsive-break - 1) {
        
        .footer{
            .footer__main .footer__contacts {
                background: url('/images/novastar/background_no_stars.png') variables.$primary-dark no-repeat;
                background-size: 800px;
            }
            
            .novanaut {
                display: none;
            }
        }

    }
}