@use "../../variables";

.image-slider {
    position: relative;

    &__outer {
        overflow: hidden;
    }

    &__main {
        .image-slider__img {
            object-fit: cover;
            display: block;
        }
    }

    &__main, &__responsive {
        .image-slider__content {
            display: flex;
        }
    }

    &__content {
        margin: auto;
        transform: translateX(0);
        transition-duration: 0.3s;
    }

    &__img {
        width: 100%;
    }

    .nav {
        display: flex;
        height: 8px;

        &__goto {
            flex: 1;
            background: variables.$neutral-lighter;
        }

        &--active {
            background: variables.$secondary;
        }
    }

    .prev, .next {
        position: absolute;
        top: calc(50% - 25px);
        font-size: 32px;
        color: variables.$neutral-darker-700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }

    .prev {
        left: 8px;
    }

    .next {
        right: 8px;
    }

    .prev:hover, .next:hover {
        background: variables.$neutral-darker;
        opacity: 0.5;
        color: variables.$white;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .image-slider {

        &:not([data-bound]) .image-slider__content > :not(:first-child) {
            display: none;
        }

        &__item {
            display: inline-block;
        }

        &__responsive, &__main {
            .image-slider__img {
                display: block;
                height: 300px;
                object-fit: cover;
            }
        }

    }
}

@media(min-width: variables.$responsive-break) {
    .image-slider {

        &__main .image-slider__img {
            height: 433px;
        }

        &__responsive {
            .nav, .prev, .next {
                display: none;
            }
        }

        &__content {
            flex-wrap: wrap;
            justify-content: space-between;
            touch-action: none;
            display: flex;

            &.no-drag {
                pointer-events: none;
            }
        }
    }

  .image-slider__main {
    .image-slider__content {
      flex-wrap: initial;
    }
  }
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}