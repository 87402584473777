@use "../../variables";
@use "../../mixins";

.pause {
    display: block;
    font-size: variables.$font-size-lg;
    width: auto;
    left: -300em;
    margin-bottom: 10px;
    height: auto;
    position: relative;
    top: 5px;
    right: -20px;
}

.pause:focus {
    display: block;
    position: relative;
    font-size: variables.$font-size-lg;
    width: auto;
    left: 0;
    margin-bottom: 10px;
    height: auto;
    top: 5px;
    right: -20px;
}


.slider {
    margin: 20px 0;
}

.small-slider__wrapper {
    margin-top: auto;

    .tns-outer {
        margin: 0 auto;
    }
}

.small-slider__list {
    display: flex;
    width: 100% !important;
    justify-content: center;
    margin: 24px 0;
    flex-wrap: wrap;

    .slider__item {

        &:focus {
            outline: 0;
        }
    }

    img {
        max-width: 56px;
        max-height: 56px;
        margin-right: 10px;
    }

    .slider__image {
        border: solid 1px;
        border-color: transparent;
        padding: 13px;
        border-radius: variables.$border-radius-round;

        &:hover {
            border-color: variables.$neutral-lighter-100;
            cursor: pointer;
        }

        &--active {
            border-color: variables.$neutral-lighter-100;
        }
    }
}

.tns-inner {
    text-align: center;
}

.big-slider {
    display: flex;
    justify-content: center;
    align-items: center;



    &__wrapper {
        position: relative;
        margin-top: 32px;
        flex-basis: 80%;

        .tns-outer {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }



    &__controls {
        z-index: 1;
        width: 100%;

        @include mixins.slider-controls;

    }

    .slider__item {
        position: relative;
    }

    .slider__image {
        object-fit: contain;
    }
}

button[data-action="start"],
button[data-action="stop"] {
    display: block;
    left: -300em;
    position: relative;

    &:focus {
        display: block;
        position: relative;
        left: 0;
    }
}

.modal-product {
    z-index: 1000001; // On top of Zendesk icon

    .big-slider img {
        height: 100%;
    }
    
    .modal {
        &__wrapper {
            height: 100%;
            width: 100%;
            flex-direction: column;
            align-items: center;
        }

        &__body,
        &__header,
        &__footer {
            padding: 0;
        }

        &__header .close span i{
            font-size: variables.$font-size-lg;
        }

        &__body {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
        }
    }
}

.video {
    display: none;
    border: none;

    &--active {
        display: block;
    }
}

.product-video__thumbnail--placeholder {
    height: 72px;
    background: black;
}

.cookie-centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -100%; // Offset iframes cause scroll
}

@media only screen and (max-device-width: 480px) {

    .modal-product {

        .modal__content {
            height: 760px;
        }
    }
}
@media(max-width:variables.$responsive-break - 1){
    .modal-product {
        .modal__content {
            height: 100%;
            width: 100%;
        }
    }
}
@media(min-width:variables.$responsive-break){
    .modal-product {
        .modal__content {
            min-height: 480px;
            min-width: 600px;
        }
    }
    .big-slider__controls .prev {
        margin-left: 114px;
    }

    .big-slider__controls .next {
        margin-right: 114px;
    }

}