@use "../../variables";

.quote {
    margin-left: 16px;
    font-size: variables.$font-size-3xl;
    color: variables.$neutral;

    &:before {
        content: url("/images/quote.png");
    }
}