@use "../../variables";

$active-tab-border-radius: 2px;
$text-color: variables.$white;
@if (variables.$site-avi) {
    $text-color: variables.$primary;
}

.tabs {
    display: flex;
    height: 40px;
    align-items: center;
    position: relative;
    z-index: variables.$zindex-tabs;

    &__item {
        cursor: pointer;
        border: 0;
        background: transparent;
        color: $text-color;

        a {
            color: $text-color;
        }

        &--active {
            height: 100%;
            background-color: variables.$white;
            color: variables.$primary;
            font-weight: variables.$font-weight-bold;
            border-top-left-radius: $active-tab-border-radius;
            border-top-right-radius: $active-tab-border-radius;
        }
    }
}

.tabs--main {

    .tabs__item {
        font-weight: variables.$font-weight-bold;

        &:hover {

            &:not(.tabs__item--active) {
                border-top-left-radius: $active-tab-border-radius;
                border-top-right-radius: $active-tab-border-radius;
                background: rgba(255, 255, 255, .26);
            }
        }

        &:not(:last-of-type) {
            margin-right: 1px;
        }
    }
}

#main-header-menu .tabs__item--active:not(.preload) {
    transition: color 1s, background-color 1s;
}

.day {
    color: #08da08;
}

.night {
    color: variables.$supporting-01-dark;
}

@media(min-width: variables.$responsive-break) {
    .tabs__item {
        padding: 10px 24px;
    }
    @if(variables.$site-avi){
        .header__menu .tabs__item[data-event-name="promotions_tab"] {
            display: none;
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {
    @if variables.$site-nova {
        #main-header-menu {
            display: none;
        }
    }

    .header__menu .tabs__item[data-event-name="news_tab"] {
        display: none;
    }
}