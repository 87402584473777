@use "../../variables";
@use "../../mixins";

.panel-slider {
    position: relative;
    margin: 0 32px;

    @include mixins.carousel-buttons;

    .tns-outer {
        margin: 0;
    }

    &__list {
        display: flex;
    }

    &__title {
        font-size: variables.$font-size-sm;
        margin-bottom: 26px;
    }

    &__container {
        max-width: 432px;
    }

    .carousel-card {
        padding: 8px 8px 16px 8px;

        .image {
            max-height: 65px;
            height: 100%;
            object-fit: contain;
        }

        &__wrapper {
            padding: 8px;
        }
    }

    &__controls {
        width: calc(100% + 96px);
        left: -48px;
        top: 40%;
    }

    @include mixins.tns-nav;
}

.circle {
    position: relative;
    text-align: center;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    background-color: variables.$supporting-01;
    background-image: linear-gradient(91deg, variables.$white 50%, variables.$white 50%), linear-gradient(90deg, variables.$white 50%, transparent 50%);

    &__inner {
        position: relative;
        top: 5px;
        left: 5px;
        text-align: center;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        background: variables.$neutral-lighter-25;
        display: flex;
        justify-content: center;
        align-items: center;
        color: variables.$supporting-01;

        .icon {
            font-size: variables.$font-size-default;
        }
    }
}

.slider-list {
    overflow-y: auto;
    height: 100%;

    &__item {
        background: variables.$white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        &:not(:first-of-type) {
            padding-top: 32px;
        }
    }

    &__amount {
        display: flex;
        justify-content: flex-end;
    }

    &__title {
        width: 100%;
        max-width: 200px;
        margin: 0 32px;
        font-size: variables.$font-size-sm;
        font-weight: variables.$font-weight-bold;
        color: variables.$neutral-darker;
        line-height: variables.$paragraph-lh;
    }

    &__info {
        margin-right: 32px;
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-normal;
    }

    &__img {
        max-width: 48px;
        max-height: 48px;
    }

    &__delete {
        font-size: variables.$font-size-sm;
        color: variables.$primary;
    }
}

.scrollable-pane {
    position: relative;
    max-height: calc(100% - 80px);

    &--active {

        &:before,
        &:after {
            height: 40px;
            content: "";
            position: absolute;
            z-index: 10;
            width: 100%;
        }

        &:before {
            right: 24px;
            top: 0;
            background: linear-gradient(variables.$white, transparent);
        }

        &:after {
            right: 24px;
            bottom: 0;
            background: linear-gradient(transparent, variables.$white);
        }
    }
}

.bottom.scrollable-pane--active:after,
.top.scrollable-pane--active:before {
    height: 0;
}

.free-transport {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
    align-items: center;

    &__title {
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
    }

    &__icon {
        margin-right: 32px;
    }
}

// safari

@supports (-webkit-backdrop-filter: blur(1px)) {

    .scrollable-pane {
        overflow-y: scroll
    }
}
