@use "../../variables";
@use "../../mixins";

@media (max-width: variables.$responsive-break - 1) {
  .home-page .news {
    display: none;
  }
  .news-article:last-of-type{
      display:none;
  }
}
@media (min-width: variables.$mobile-break){
    .news {
        margin-top: 48px;

        &__body {
            display: grid;
            grid-gap: 32px;
        }
    }
}
@media (min-width: variables.$mobile-break) and (max-width: variables.$responsive-break - 1) {
    .news__body {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: variables.$responsive-break){
    .news__body {
        grid-template-columns: repeat(3, 1fr);
    }
}
.news {

    .image-wrapper {
        display: flex;
        height: 100%;
        max-height: 200px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 34px;
        align-items: baseline;
    }

    &__link {
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;

        &:visited {
            color: variables.$primary;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__title {
        font-size: variables.$font-size-3xl;
        font-weight: variables.$font-weight-normal;
        color: variables.$primary;
        margin: 0;
    }
}

.news-article {
    overflow: hidden;
    color: variables.$neutral-darker;

    &__img {
        transition: all .4s ease-in-out;
        width: 100%;
        overflow: hidden;
    }

    &__link {
        height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &:hover {
        background: variables.$neutral-lighter-25;

        .news-article__img {
            transform: translateZ(0) scale(1.010);
            opacity: .96;
        }
    }
    &__body {
        @include mixins.news-text-short;
    }

    &__paragraph {
        line-height: variables.$paragraph-lh;
        margin: 8px 0;
    }

    &__title {
        color: variables.$neutral-darker;
        margin: 0;
        font-size: variables.$font-size-lg;
        font-weight: variables.$font-weight-bold;
        line-height: variables.$lh-medium;
    }

    &__tag {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &--primary {
            color: variables.$secondary-darker
        }

        .title {
            font-weight: variables.$font-weight-bold;
            margin-left: 8px;
        }
    }
}

.icon-microphone {
    content: url('/images/microphone.svg');
}

p.news-article__paragraph {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 10px;
}