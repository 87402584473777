@use '../../variables';

.icon-circle {
  box-sizing: border-box; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: variables.$secondary;
  color: variables.$primary;
}

 