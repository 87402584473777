@use "../../variables";

.icon-swed {
    content: url('/images/swedbank.png');
}

.icon-seb {
    content: url('/images/seb.svg');
}

.icon-siauliu {
    content: url('/images/sb.svg');
}

.icon-lhv {
    content: url('/images/lhv.svg');
}

.icon-luminor {
    content: url('/images/luminor.svg');
}

.icon-citadele {
    content: url('/images/citadele.svg');
}

.icon-cart {
    content: url('/images/euronics_cart.svg');
}

.icon-bill-payment {
    content: url('/images/bill_payment.png');
}

.icon-cash-payment {
    content: url('/images/cash_payment.png');
}

.icon-incredit {
    content: url('/images/incredit.svg');
}

.icon-bigbank {
    content: url('/images/bigbank.svg');
}

.icon-inbank {
    content: url('/images/inbank.svg');
}

.icon-sblizingas {
    content: url('/images/sblizingas.svg');
}

.icon-perku3 {
    content: url('/images/perku3.svg');
}

.icon-visa{
    content: url('/images/visa.svg');
}

.icon-mastercard {
    content: url('/images/mastercard.svg');
}

@if variables.$site-nova {
    .icon-creditcard {
        content: url('/images/novastar/creditcard.svg');
    }
} @else if variables.$site-avi {
    .icon-creditcard {
        content: url('/images/avitela/creditcard.svg');
    }
} @else {
    .icon-creditcard {
        content: url('/images/creditcard.svg');
    }
}

.icon-apple-pay {
    content: url('/images/apple_pay.svg');
}

.icon-google-pay {
    height: 37px;
    content: url('/images/google_pay.svg');
}

.icon-smart-rent{
    content: url('/images/inban_smartrent_button.svg');
}

.icon-delete-card-token {
    content: url('/images/delete_card_token.svg');
    width: 25px;
    border-radius: 100%;

    &:hover {
        border: 1px solid variables.$primary;
    }
}

.icon-remove {
    content: url('/images/remove.svg');
    vertical-align: text-top;
    margin-left: 5px;
}

.icon-remove-yellow {
    content: url('/images/remove-yellow.svg');
    vertical-align: text-top;
    margin-left: 5px;
}

.sort-up-down {
    content: url('/images/sort-up-down.svg');
}

.funnel {
    content: url('/images/funnel.svg');
}

.icon-calculator {
    content: url('/images/calculator.svg');
}

.icon-compatibles {
    content: url('/images/compatibles.svg');
}

.icon-specification {
    content: url('/images/specification.svg');
}

.icon-product-delivery {
    content: url('/images/product-delivery.svg');
}

.icon-availability {
    content: url('/images/availability.svg');
}

.icon-links {
    content: url('/images/links.svg');
}

.icon-description {
    content: url('/images/description.svg');
}

.icon-energy {
    content: url('/images/energy.svg');
}

.icon-discussion {
    content: url('/images/discussion.svg');
}

.icon-similar {
    content: url('/images/similar.svg');
}

.icon-gift {
    content: url('/images/gift.svg');
}

.icon-fb {
    content: url('/images/fb.svg');
    height: 20px;
    width: 20px;
}

.icon-g {
    content: url('/images/G.svg');
    height: 20px;
    width: 20px;
}

.icon-protection-package-ew {
    content: url('/images/protection-package-ew.svg');
}

.icon-protection-package-ady {
    content: url('/images/protection-package-ady.svg');
}

.icon-protection-package-adew {
    content: url('/images/protection-package-adew.svg');
}

@if variables.$site-avi {
    .icon-spare-parts {
        content: url('/images/avitela/spare_parts.svg');
    } 
}@else{
    .icon-spare-parts {
        content: url('/images/spare_parts.svg');
    }
}

.icon-service {
    content: url('/images/sass/service.svg');
}

.logo-uefa {
    content: url('/images/uefa_logo.png');
}

.logo-dpd {
    content: url('/images/dpd-logo.svg');
}

.logo-omniva {
    content: url('/images/omniva-logo.svg');
}

.logo-venipak {
    content: url('/images/venipak-logo.svg');
}

.category-alt-icon__recreation {
    content: url('/images/categoryalticons/recreation.png');
}

.category-alt-icon__phone {
    content: url('/images/categoryalticons/phone.png');
}

.category-alt-icon__kitchen {
    content: url('/images/categoryalticons/kitchen.png');
}

.category-alt-icon__it {
    content: url('/images/categoryalticons/it.png');
}

.category-alt-icon__housekeeping {
    content: url('/images/categoryalticons/housekeeping.png');
}

.category-alt-icon__entertainment {
    content: url('/images/categoryalticons/entertainment.png');
}

.category-alt-icon__beauty_health {
    content: url('/images/categoryalticons/beauty_health.png');
}

.category-alt-icon__audio {
    content: url('/images/categoryalticons/audio.png');
}
.category-alt-icon__appliances {
    content: url('/images/categoryalticons/appliances.png');
}
.category-alt-icon__tv {
    content: url('/images/categoryalticons/tv.png');
}

@if variables.$site-nova {
    .logo-brand {
        content: url('/images/novastar/novastar-logo.svg');
    }
} @else if variables.$site-avi {
    .logo-brand {
        content: url('/images/avitela/logo.svg');
    }
} @else {
    .logo-brand {
        content: url('/images/euronics_logo.svg');
    }
}

@if variables.$site-nova-or-avi {
    .icon-parcel {
        content: url('/images/parcel.png');
    }
    .icon-home-delivery {
        content: url('/images/home-delivery.png');
    }
    @if variables.$site-avi {
        .icon-shop {
            content: url('/images/avitela-shop.png');
        }
    }
    @if variables.$site-nova {
        .icon-shop {
            content: url('/images/shop.png');
        }
    }
}

@if variables.$site-avi {
    .icon-free-financing {
        content: url('/images/free_financing_avitela.svg');
    }
    .icon-free-delivery {
        content: url('/images/free_delivery_avitela.svg');
    }
    .icon-free-gift {
        content: url('/images/free_gift_avitela.svg');
    }
} @else if variables.$site-nova {
    .icon-free-financing {
        content: url('/images/free_financing_nova.svg');
    }
    .icon-free-delivery {
        content: url('/images/free_delivery_nova.svg');
    }
    .icon-free-gift {
        content: url('/images/free_gift_nova.svg');
    }
} @else {
    .icon-free-financing {
        content: url('/images/free_financing.svg');
    }
    .icon-free-delivery {
        content: url('/images/free_delivery.svg');
    }
    .icon-free-gift {
        content: url('/images/free_gift.svg');
    }
}

@if variables.$site-nova {
    .logo-evertink {
        content: url('/images/novastar/evertink_logo.png');
    }

    .novanaut {
        content: url('/images/novastar/novanaut.png');
    }
    .novanaut-with-shadow {
        content: url('/images/novastar/novanaut-with-shadow.png');
    }
    .novanaut-head {
        content: url('/images/novastar/novanaut-head.png');
    }
    .novanaut-customer-support {
        content: url('/images/novastar/novanaut-head-customer-support.png');
        width: 60px;
        padding: 0 16px;
    }
    .novanaut-head-hearts {
        content: url('/images/novastar/novanaut-head-hearts.png');
        width: 60px;
        padding: 0 16px;

        &--small {
            width: 40px;
        }

        &-purple {
            width: 60px;
            padding: 0 16px;
            content: url('/images/novastar/novanaut-head-hearts-purple.png');
        }
    }
    .novanaut-head-percent {
        content: url('/images/novastar/novanaut-head-percent.svg');
    }
    .novanaut-head-percent-curved-line {
        content: url('/images/novastar/novanaut-head-percent-curved-line.png');
    }
    .novanaut-head-curved-line {
        content: url('/images/novastar/novanaut-head-curved-line.png');
    }

    .icon-bill-payment {
        content: url('/images/novastar/novastar_bank_transfer.png');
        margin-right: 10px;
    }
    .icon-cash-payment {
        content: url('/images/novastar/novastar_cash_payment.png');
        margin-right: 10px;
    }
}

@else if variables.$site-avi{
    .logo-avitela-account{
        content: url('/images/avitela/avitela_account.svg');
    }
}

.icon-alert-error {
    content: url('/images/alert-error.svg');
}
.icon-info{
    content: url('/images/info.svg');
}
.icon-info-black {
    content: url('/images/info-black.svg');
}
.coupon-background {
    content: url('/images/coupon-background.svg');
}