@use "../../variables";

.switch {

  .value {
    font-weight: variables.$font-weight-bold;
    color: variables.$primary;
  }

  .button {
    display: inline;
    padding: 0;

    .icon {
      font-size: variables.$font-size-sm;
      color: variables.$neutral;
      display: none;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(88px, 88px));
    grid-gap: 8px;
  }

  &__title {
    display: flex;
    margin-bottom: 8px;
    color: variables.$neutral-dark;
    font-size: variables.$font-size-sm;

    .value {
      margin-left: 5px;
      color: variables.$neutral-darker;
    }
  }

  .tooltip__button {
    display: none;
    font-size: variables.$font-size-sm;
    color: variables.$neutral;
  }
}

.label {

  &--active {
    background: variables.$primary-lighter-10;
    border-color: variables.$primary;
    color: variables.$primary !important;
    border-color: variables.$primary !important;
    font-weight: variables.$font-weight-bold;
    font-size: variables.$font-weight-bolder;
  }

  &--disabled {
    border-style: dotted !important;

    .switch__input {
      
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
}

.switch label {
  font-size: variables.$font-size-sm;
  text-align: center;
  padding: 7px;
  border: 1px solid variables.$neutral-dark;
  color: variables.$neutral-dark;
  border-radius: 4px;
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;


  &:hover {
    cursor: pointer;
    border-color: variables.$neutral-dark;
    background: variables.$neutral-lighter-25;
    color: variables.$neutral-darker;
  }

  .switch__input {
    width: 100%;
    margin: 0;
    height: 100%;
    margin: 0 -8px;

    &:hover {
      cursor: pointer;
    }
  }
}