@use "../../variables";

.dropdown {
    list-style: none;
    position: relative;
    border: 1px solid variables.$neutral-lighter;
    border-radius: 2px;
    height: 30px;
    cursor: pointer;
    padding: 0;
    font-size: variables.$font-size-default;

    input[type="checkbox"] {
        visibility: hidden;

        &:disabled {
            background: variables.$neutral-lighter-100;
        }
    }

    &__label {
        position: absolute;
        top: -29px;
        color: variables.$neutral-darker;
    }

    &__arrow {
        position: absolute;
        transition: transform .2s linear;
        top: 0;
        right: 0;
    }

    .icon {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 8px;
    }

    &__list {
        box-sizing: border-box;
        left: 0;
        width: 100%;
        position: absolute;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.25);
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        z-index: variables.$zindex-positive;
        border: 1px solid variables.$neutral-lighter;
    }

    &__list-container {
        position: relative;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        background: variables.$white;
    }

    &__list-item {
        color: variables.$neutral-darker;
        padding: 10px 8px;
        list-style-position: inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: variables.$font-size-sm;

        &--active,
        &:hover,
        &:focus {
            background-color: variables.$neutral-lighter-25;
            color: variables.$neutral-darker;
        }

        &.disabled {
            color: variables.$neutral-lighter-100;
            background: variables.$white;

            &:hover {
                cursor: auto;
                color: variables.$neutral-lighter-100;
                background: variables.$white;
            }
        }

        &__icon {
            font-size: 16px;
            padding-left: 8px;
            color: variables.$primary;
        }
    }

    &--apartment {
        display: none;
    }
}

.dropdown.open {
    .dropdown__arrow {
        transform: rotate(-180deg);
    }

    .dropdown__list {
        opacity: 1;
        overflow: auto;
        max-height: 26rem;
    }
}

.dropdown.top {
    .dropdown__list-container {
        bottom: 100%;

        .dropdown__list {
            bottom: 0;
        }
    }
}

.dropdown__selected {
    color: variables.$neutral-darker;
    padding: 6.5px 0px;
    padding-left: 8px;
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        outline: 0;
    }

    &--gray {
        color: variables.$neutral;
    }
}

.dropdown--cart {
    text-align: left;
    height: 48px;
    box-sizing: border-box;

    .dropdown {

        &__list {
            box-sizing: content-box;
            left: -1px;
            z-index: 2;
        }

        &__list-item {
            padding: 15px 8px;
        }

        &__selected {
            padding: 0 8px;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}

.language-selector .icon.dropdown__arrow {
    font-size: 10px;
    position: initial;
}
