@use "../../variables";

.discussion-card {
  background-color: variables.$white;

    .icon-euronics-star {
        content: url("/images/review_answer_euronics_star.svg");
        float: right;
        padding: 10px;
    }
    
  &__date,
  &__translation {
    color: variables.$neutral;
  }

  &__name,
  &__title {
    color: variables.$primary;
  }

  &__info {
    margin-top: 8px;
  }

    &__translation {
        margin-top: 10px;

        .icon {
            padding-left: 0;
        }
        
        .display-original,
        .display-translation {
            color: variables.$neutral-darker;
            font-weight: variables.$font-weight-bold;
            text-decoration: underline;
        }
    }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .button {
      padding: 0;
      
      .button__text {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

  }

  &__feedback {

    .feedback {
      justify-content: flex-start;
        
        &__dislikes {
            margin-left: 30px;
        }
    }
  }
}

.discussion-card {
    border-radius: variables.$border-radius-round;


    &__paragraph {
        margin-top: 8px;
        overflow: auto;
    }

    &__item {
        padding-bottom: 16px;
        
        &--answer {
            padding-left: 24px;
            border-top: variables.$primary solid 2px;
            background: variables.$neutral-lighter-25;
        }

        .feedback {
            .button.already-liked-or-disliked {
                cursor: default;
            }
        }

        &--modal {
            padding: 24px;
            background-color: variables.$neutral-lighter-25;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .discussion-card {
        &__item {
            padding-left: 24px;

            .review-rating__stars {
                float: right;
                padding-right: 10px;
            }
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .discussion-card {
        padding: 24px 16px;
    }
}
