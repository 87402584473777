@use "../../variables";

#spare-parts-form-panel {
    color: variables.$primary;
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: column;

    .panel__body, .panel__footer {
        padding: 1rem 2rem;
    }

    #back-button {
        justify-content: left;
        padding: 0.5rem;
        font-size: 1.25rem;
        font-weight: variables.$font-weight-normal;

        i {
            color: variables.$secondary;
        }
    }

    .panel__header {
        font-size: 1.25rem;
        background: variables.$gray-25;
        height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .panel__body {
        overflow: hidden auto;
        height: 100%;

        .form-description {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4rem;
            margin-bottom: 0.5rem;
        }

        form {
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
            margin-top: 0.5rem;

            .input-field, .textarea {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 0.5rem;

                .textarea__label {
                    padding: 0 0.5rem;
                }

                .textarea__input-container {
                    width: 100%;
                }

                textarea {
                    height: 8rem;
                }
            }
        }
    }

    .panel__footer {
        #submit-button {
            width: 100%;
            margin: 1rem 0;
        }
    }
}

@media(max-width: variables.$mobile-break - 1) {
    #spare-parts-form-panel {
    }
}
