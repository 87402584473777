.product-rating {
  display: inline-block;

  .star {
    content: url("/images/star-empty.svg");

    &.filled {
      content: url("/images/star-filled.svg")
    }
  }
}