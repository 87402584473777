@use "../variables";

.home-page {
    
    .desc {
        min-height: 100%;
        padding-bottom: 8px;
    }

    .info {
        margin-top: 24px;
    }


    .info {
        margin-bottom: 30px;
    }

    .items-carousel__wrapper {
        margin-top: 48px;
    }

    .recently-viewed__list .items-carousel__wrapper {
        margin-top: 0;
    }

    .items-carousel {

        &__wrapper {
            width: 100%;
        }
    }

    .recently-viewed {
        margin-bottom: 0;
        padding-top: 24px;
        text-align: center;

        &:before {
            background: variables.$neutral-lighter-50;
            height: 1px;
            position: absolute;
            content: "";
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            height: 1px;
        }
    }
    
    .image-slider .image-slider__item{
        padding: 8px;
    }
}