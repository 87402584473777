@use '../../variables';

.news-tags {
    display: flex;
    align-items: center;

    &__heading {
        margin-right: 16px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        padding: 0 0 5px 5px;
    }

    &__button {
        margin-right: 8px;
        padding: 6px 18px;
        background-color: variables.$primary-lighter-10;
        border-radius: variables.$border-radius-circle;
        border: solid 1px transparent;

        &:hover {
            background-color: variables.$primary-lighter-10;
            border-color: variables.$primary;
        }

        .button__text {
            font-size: variables.$font-size-default;
            padding: 0;
        }

        &--active {
            background-color: variables.$primary;
            color: variables.$white;

            &:hover {
                background-color: variables.$primary;
                color: variables.$white;
            }
        }
    }
}

.news-tags a[data-selected] {
    background-color: variables.$secondary;
}