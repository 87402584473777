@use "../../variables";

.review-card {
    padding: 24px 16px 8px 16px;
    background: variables.$white;
    margin-bottom: 16px;
    border-radius: variables.$border-radius-round;
    position: relative;


    &__rating {

        .icon {
            padding: 0;
            transform: rotate(15deg);
            color: variables.$neutral;
        }

        .checked {
            color: variables.$secondary;
        }
    }

    &__title {
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-default;
        line-height: 24px;
        margin: 8px 0;
    }

    &__date {
        font-size: variables.$font-size-sm;
        color: variables.$neutral;
    }

    &__name {
        color: variables.$primary;
        margin-left: 17px;
        font-size: variables.$font-size-sm;

        &:visited {
            color: variables.$primary;
        }
    }

    &__paragraph {
        color: variables.$neutral-darker;
        text-align: justify;
        line-height: variables.$paragraph-lh;
        margin-top: 24px;
    }

    &__feedback {
        color: variables.$neutral;
    }
}


.feedback {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .button {
        display: flex;
        align-items: baseline;
        color: variables.$primary;

        .icon {
            padding-left: 0;
        }

        &__text {
            padding: 0;
        }
    }
}

.long-text {
    display: none;
}

.read-more {
    color: variables.$primary;
    font-weight: variables.$font-weight-bold;
    font-size: variables.$font-size-sm;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 50px;
    border: 0;
    left: 0;
    height: 36px;
    background-color: variables.$white;

    &:hover {
        cursor: pointer;
    }

    &:before {
        content: "";
        position: absolute;
        height: 30px;
        background: -webkit-gradient(linear,left top,left bottom,from(transparent),to(variables.$white));
        background: linear-gradient(transparent,variables.$white);
        z-index: 123;
        width: 100%;
        top: -30px;
    }
}
