@use "../../variables";

#parcel-modal{
    .type-filter{
        &-wrapper{
            display: flex;
        }

        &-elem{
            height: 90px;
            width: -webkit-fill-available;
            padding: 15px;
            margin: 5px;
            background: variables.$neutral-lighter-50;
            border-radius: 8px;
            border: 1px solid variables.$neutral-lighter-100;
            user-select: none;
            text-align: -webkit-center;
            
            .elem{
                &-logo{
                    height: 65px;
                }
                
                &-price{
                    display: block;
                    color: variables.$primary;
                    font-weight: variables.$font-weight-bolder;
                    font-size: variables.$font-size-md;
                    padding-top: 5px;
                }
            }
            
            &--active{
                background: variables.$white;
            }
        }
    }
    
    #ParcelLocationName {
        border-radius: 8px;
        margin: 4px;
        width: 99%;
    }
    .input-field__input-container--icon .icon{
        top: 22px;
    }
    
    .modal{
        &__header{
            justify-content: center;
            position: sticky;
            top: 0px;
            background: variables.$white;

            .close{
                background-color: variables.$neutral-lighter;
                color: variables.$white;
                width: 25px;
                height: 25px;
                right: 12px;
                top: 8px;
            }
        }
        
        &__content {
            height: 99vh;
        }
        
        &__title{
            color: variables.$primary;
            margin-bottom: unset;
            font-weight:  variables.$font-weight-normal;
            font-size: variables.$font-size-xl;
            text-transform: unset;
        }
        
        &__body{
            background: variables.$neutral-lighter-25;

            .action_button{
                background: variables.$white;
                color: variables.$primary;
                text-align: left;
                text-wrap: pretty;

                &--active{
                    background: variables.$neutral-lighter-100;
                }

                mark{
                    color: inherit;
                }
            }
        }
    }
}

@media (max-width: variables.$mobile-break) {
    #parcel-modal{
        .modal{
            &__header{
                padding: 14px;
                background: variables.$neutral-lighter-25;
                h3{
                    display: none;
                }
            }
        }
        
        
        .type-filter-elem{
            height: unset;
            align-content: end;
            .elem-logo{
                height: unset;

                &-wrapper{
                    display: flex;
                    height: 70%;
                    justify-content: center;
                }
            }
        }
    }
}