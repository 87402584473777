@use "../../variables";
@use "../../mixins";

.header {
    position: relative;
    z-index: variables.$zindex-header;
    height: inherit;
    display: flex;
    flex-direction: column;
    background-color: variables.$primary;

    .row {
        justify-content: space-between;
        align-items: center;
    }

    &__top {
        position: relative;
    }

    &--dark {
        background: variables.$neutral-darker;

        .header__background-image {
            filter: brightness(0) invert(1);
            opacity: .3;
        }

        .tabs__item--active {
            color: variables.$neutral-darker;
        }
    }


    &__overlay {
        height: 100%;
        left: 0;
        position: fixed;
        display: none;
        top: 0;
        width: 100%;

        &--active {
            background: variables.$neutral-darker;
            display: block;
            z-index: variables.$zindex-header-overlay;
            opacity: .5;
        }
    }

    &__background-image {
        position: absolute;
        height: 100%;
        right: 0;
        z-index: -1;
    }

    &__menu {
        @if variables.$site-avi {
            background-color: variables.$neutral-lighter-25;
        } @else {
            background-color: rgba(255, 255, 255, .05);
        }

        &--dark {
            background-color: variables.$neutral-darker;
        }

        .tabs {

            &__item {
                height: 100%;
                display: flex;
                align-items: center;
            }

            :focus {
                box-shadow: none;
            }
        }
    }

    &__search {
        display: flex;
    }

    .image-slider__main {
        order: 1;
        z-index: -1;
    }
}

.cart-r {
    fill: variables.$white;

    &--active {
        fill: variables.$secondary;
    }
}

.icon-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: .8;

    &--active {
        opacity: 1;
    }

    &:first-of-type {

        .button:first-of-type {
            margin-right: 18px;

            .icon {
                padding-left: 10px;
            }
        }
    }

    .button {
        padding: 8px;

        &__text {
            font-size: variables.$font-size-default;
            padding: 0 8px;
            max-width: 325px;
        }

        &:hover {
            @if variables.$site-avi {
                background: variables.$gray-25;
            } @else {
                background: rgba(255, 255, 255, .26);
            }
        }
    }

    .icon-circle {
        background: transparent;
        color: variables.$white;
        border: variables.$white solid 1px;
    }

    &:hover {
        opacity: 1;
    }
}

.language-selector {
    border: none;
    width: initial;
    z-index: variables.$zindex-header-slide-menu;

    .dropdown__selected {
        color: variables.$white-500;
        display: flex;
        align-items: center;
    }

    .dropdown__list {
        width: 100px;
        height: fit-content;
        background: variables.$white-500;
        border-radius: 5px;
    }

    .dropdown__list-item {
        display: flex;
        justify-content: space-between;
    }

    .dropdown__list-item input:checked::after {
        visibility: visible;
        color: #27AE60;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f00c";
    }
}

.main-nav {
    width: 100%;
}

.sub-nav {

    &__footer {
        position: relative;
        top: 100%;
        width: 100%;
        background: #F8F8F8;
        padding: 10px 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;


        ul {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
            grid-gap: 32px;
            //margin: 0 32px;
        }

        &-list {
            width: 100%;
        }

        &-item {
            display: grid;
            justify-content: center;

            .icon {
                margin: 0;
                color: variables.$primary;
            }

            .title {
                color: variables.$primary;
                font-weight: variables.$font-weight-bold;
            }

            .paragraph {
                color: variables.$neutral-dark;
                font-size: variables.$font-size-sm;
            }

            img {
                height: 40px;
                width: 40px;
            }
        }

        &-link:hover {

            .title {
                text-decoration: underline;
            }
        }

        .grid-wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;

            .icon {
                margin: 8px;
            }
        }

        .button__text {
            color: variables.$neutral-darker;
            font-size: variables.$font-size-default;
        }
    }
}

.bottom-level__block li {
    margin-bottom: 8px;
}

.main-nav__item .nav-item__link {
    @include mixins.nav-icons;
}

.nav-icon {
    width: 40px;
    height: 40px;
}

#promotions .icon-hover {
    fill: none;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;

    path, circle {
        stroke: variables.$white;
    }

    .see-through {
        fill: variables.$primary;
    }
}

symbol[id^="i"] {
    fill: none;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;

    .see-through {
        @if variables.$site-nova {
            fill: variables.$primary;
        } @else {
            fill: variables.$white;
        }
    }

    .active {
        stroke: none;
    }

    .active-s, .active {
        @if (variables.$site-nova) {
            fill: #998ade;
        } @else {
            fill: variables.$neutral-lighter-25;
        }
    }

    path, circle {
        @if variables.$site-nova {
            stroke: variables.$white;
        } @else {
            stroke: variables.$primary;
        }
    }
}

symbol[id^="s"] {
    path, circle {
        fill: none;
        stroke: variables.$primary;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;

        &.active {
            stroke: none;
        }

        &.active-s, &.active {
            @if (variables.$site-nova) {
                fill: #998ade;
            } @else {
                fill: variables.$primary-lighter-50;
            }
        }

        &.see-through {
            @if (variables.$site-nova) {
                fill: variables.$primary;
            } @else {
                fill: variables.$white;
            }
        }

        &.fill {
            fill: variables.$primary;
            stroke: none;
        }
    }
}

.main-nav__item--active {
    .nav-item__label {
        font-weight: variables.$font-weight-bold;
    }

    .nav-item__link {

        &:hover {

            .icon-hover {
                display: none;
            }
        }

        .icon-idle,
        .icon-hover {
            display: none;
        }

        .icon-expand {
            display: block;
        }
    }
}

.cart-icon-menu, .cart-r {
    .count {
        display: none;
    }
}

.icon-menu--active, .cart-r--active {
    .count {
        display: flex;
        justify-content: center;
        align-items: center;
        background: variables.$supporting-01;
        font-weight: variables.$font-weight-bold;
        border-radius: 25px;
        right: -5px;
        position: absolute;
        color: variables.$white;
    }
}

#cart-icon-logged-in {
    @if variables.$site-avi {
        .cart-background {
            fill: variables.$primary;
        }
        .cart-contour {
            stroke: variables.$white;
        }
    } @else if variables.$site-nova {
        .cart-contour {
            stroke: variables.$black;
        }
        .cart-background {
            fill: none;
        }
    } @else {
        .cart-background {
            fill: variables.$secondary;
        }

        .cart-contour {
            stroke: variables.$primary;
        }
    }
}

#cart-icon-logged-out {
    @if variables.$site-avi {
        .cart-background {
            fill: variables.$white;
            stroke: variables.$primary;
        }
        .cart-contour {
            stroke: variables.$primary;
        }
    } @else if variables.$site-nova {
        .cart-contour {
            stroke: variables.$black;
        }
        .cart-background {
            fill: none;
        }
    } @else {
        .cart-background {
            stroke: variables.$white;
            fill: variables.$primary;
        }
        .cart-contour {
            stroke: variables.$white;
        }
    }
}

#cart-icon-idle {
    .cart-contour {
        stroke: variables.$primary;
    }

    .cart-background {
        stroke: variables.$primary;
        fill: variables.$secondary;
    }
}

#cart-icon-hover {
    @if variables.$site-avi {
        .cart-contour {
            stroke: variables.$primary;
        }
        .cart-background {
            stroke: variables.$primary;
            fill: variables.$secondary-darker;
        }
    } @else {
        .cart-background {
            stroke: variables.$neutral-dark;
            fill: variables.$secondary-darker;
        }
        .cart-contour {
            stroke: variables.$neutral-dark;
        }
    }
}

#cart-icon-active {
    .cart-background {
        stroke: variables.$white;
        fill: variables.$primary;
    }

    .cart-contour {
        stroke: variables.$white;
    }
}

.cart-icon {
    position: relative;
    height: 40px;
}

.user-icon {
    position: relative;
    height: 40px;
}

.bonus {
    font-weight: variables.$font-weight-bold;
    font-size: variables.$font-size-xs;

    &__amount {
        margin-left: 5px;
    }
}

.tabs--secondary {

    .tabs__item {
        padding: 0;
        margin: 0 8px;

        &:hover {
            text-decoration: underline;
        }

        a {
            color: variables.$neutral-lighter-100;
        }

        .highlighted {
            text-decoration: underline;
            font-weight: variables.$font-weight-bold;
        }
    }
}

.header-service {
    color: variables.$neutral-lighter-100;

    a {
        color: variables.$neutral-lighter-100;
    }
}

.breadcrumb__back {
    font-size: variables.$font-size-md;
    margin: 16px 8px;

    .breadcrumb__back-services-label {
        color: variables.$primary;
    }

    .icon {
        color: variables.$secondary;
    }
}

/* wide */
@media(min-width: variables.$responsive-break) {
    .burger,
    .search,
    .cart-r,
    .nav-item__label-desc,
    .breadcrumb__back {
        display: none;
    }

    #categoryMenu, #servicesMenu {
        background: variables.$white;
        height: 90px;
    }

    .header__search {
        min-height: 88px;
    }

    .main-nav {
        .icon {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a:hover {
            text-decoration: underline;
        }

        &__item {
            .nav-item__link {
                justify-content: center;
                text-align: center;
            }

            &--active {
                .nav-item__label {
                    border-bottom: solid 3px variables.$secondary;

                    a {
                        color: variables.$primary;
                    }
                }

                .sub-menu {
                    display: flex;
                    background: variables.$white;
                    font-size: variables.$font-size-sm;
                    color: variables.$primary;

                    a {
                        color: variables.$primary;
                    }
                }
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
            background: variables.$white;
            position: relative;
        }
    }

    .nav-item__link {
        flex-direction: column;

        &:hover {
            background: variables.$neutral-lighter-25;
        }
    }

    .nav-item__label {
        .nav-item__link .icon {
            display: none; /* prioriteetsem kui: '.nav-item__label a .icon' */
        }

        a {
            height: 87px;
            display: flex;
            align-items: center;

            .icon {
                margin-bottom: 2px;
                display: flex;
                justify-content: center;
            }
        }

        &-name {
            margin-top: 4px;
        }
    }

    .sub-menu {
        top: 100%;
        position: absolute;
        padding: 24px;
        left: 0;
        right: 0;
        display: none;
        background: variables.$white;

        &::before {
            content: "";
            border-top: 3px solid variables.$neutral-lighter-25;
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
        }

        &__container {
            font-size: variables.$font-size-sm;
            background: variables.$white;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
        }

        &__block {
            padding: 8px;
            box-sizing: border-box;
            flex: 0 1 20%;


            &.with-banner {
                flex-basis: 25%;
            }

            &.grow {
                flex-grow: 1;
            }

            &-heading {
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
                display: block;
                padding-bottom: 5px;

                @if variables.$site-nova-or-avi {
                    padding-bottom: 7px;
                }
            }

            ul {
                li {
                    padding: 0;
                }
            }

            .nav-icon {
                display: none;
            }
        }

        &__back, &__title {
            display: none;
        }

        &__block-heading {
            font-size: 19px;
        }
    }
    .icon-menu--active {
        .count {
            width: 19px;
            height: 19px;
            font-size: 13px;
            top: 0;
        }
    }
}

/* narrow */
@media (max-width: variables.$responsive-break - 1) {
    .tabs--secondary,
    .header-service,
    .icon-menu,
    .language-selector__item,
    #categoryMenu,
    #servicesMenu,
    #Home #categoryMenu.d-none,
    #Home #servicesMenu.d-none,
    #Home .breadcrumb__back,
    .header__container {
        display: none;
    }

    .language-selector__item--active,
    .language-selector--active .language-selector__item,
    #Home #categoryMenu,
    #Home #servicesMenu,
    #Home .header__menu,
    #Home #servicesMenu .breadcrumb__back-services-label,
    #Home .header__container {
        display: block;
    }

    .burger, #language-selector {
        order: -1;
    }

    .header {
        &__search {
            min-height: 56px;

            .row {
                justify-content: space-around;
                display: grid;
                grid-template-rows: 56px auto;

                .burger, .euronics-logo, #language-selector, .search, .cart-r {
                    grid-row: 1;
                }

                .burger {
                    grid-column: 1;
                    padding: 100% 0;
                }

                #language-selector {
                    grid-column: 2;
                }

                .euronics-logo {
                    grid-column: 3;
                }

                .search {
                    grid-column: 4;
                }

                .cart-r {
                    grid-column: 5;
                }
            }
        }

        &__wrapper {
            justify-content: center;
            display: flex;
        }

        .tabs {
            flex-grow: 1;

            .tabs__item {
                flex-grow: 1;
                justify-content: center;
            }
        }

        .image-slider__main {
            order: 0;
        }
    }

    .logo-brand {
        width: 150px;
    }

    .main-nav {
        background: variables.$neutral-lighter-25;

        &__item {

            &--active {
                .nav-item__label {
                    border-bottom: none;
                }
            }

            .nav-item {
                &__label-name {
                    font-weight: variables.$font-weight-bold;
                }

                &__link {
                    @include mixins.menu-item-three-columns;
                }
            }
        }

        &__list {
            background: variables.$neutral-lighter-25;
            margin: 8px;
        }
    }
    @if variables.$site-nova {
        symbol {
            .see-through {
                fill: variables.$white;
            }

            .active {
                stroke: none;
            }

            .active-s, .active {
                fill: variables.$gray-100;
            }
        }

        symbol[id^=i] path, symbol[id^=i] circle {
            stroke: variables.$black;
        }
    }

    .nav-item__label-desc {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 44px;
        color: variables.$neutral;
    }

    .bottom-level__block {
        display: none;
    }


    .sub-menu {
        &__container {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &__mid-level-container {
            @include mixins.responsive-category-menu-item;
            display: flex;

            &::after {
                color: variables.$neutral;
                font-size: variables.$font-size-3xl;
            }
        }

        &__block {
            @include mixins.nav-icons;
            border: 1px solid variables.$neutral-lighter-100;
            border-radius: 8px;
            margin: 4px 8px;

            &--active {
                background-color: variables.$neutral-lighter-25;

                li {
                    align-items: center;
                    display: flex;
                    padding: 8px 0;
                    border-top: 1px solid variables.$neutral-lighter-100;
                    margin: 0;

                    a {
                        padding-left: 30px;
                        line-height: 24px;
                        width: 100%;
                        font-size: 16px;
                    }
                }

                .bottom-level__block {
                    display: block;
                }
            }

            .sub-menu__mid-level-container::after {
                content: "+";
            }

            &--active .sub-menu__mid-level-container::after {
                content: "-";
            }


            &-heading {
                font-size: 18px;
                color: variables.$primary;
                pointer-events: none;
                cursor: default;
            }
        }

        &__title {
            text-align: center;
            color: variables.$primary;
            font-weight: normal;
        }
    }

    .sub-nav__footer {
        display: none;
    }

    .cart-r--active {
        position: relative;

        .count {
            width: 12px;
            height: 12px;
            font-size: 10px;
            top: -5px;
        }
    }
}

@if variables.$site-nova-or-avi {
    .header {
        background: variables.$white;
        color: variables.$primary !important;

        a {
            color: variables.$primary !important;
        }

        .language-selector {
            .dropdown__selected {
                color: variables.$neutral-darker;
            }
        }

        .header-service {
            color: variables.$color-black;
        }
    }
    @media (min-width: 1216px) {
        .openPanel .button__text {
            color: variables.$primary;
        }
    }
}

@if variables.$site-nova-or-avi {
    .cart-r {
        fill: variables.$primary;
    }
}

@if variables.$site-avi {
    .logo-brand {
        content: url('/images/avitela/logo-dark.svg');
        height: 64px;
    }
    @media (max-width: variables.$responsive-break - 1) {
        .header .logo-brand {
            height: 40px;
        }
    }
}

@if variables.$site-nova {
    .logo-brand {
        content: url('/images/novastar/novastar-logo-dark.svg');
        height: 30px;
    }
    .header {
        &__menu {
            border-top: 1px solid variables.$neutral-lighter-25;
        }

        &__background-image {
            position: absolute;
            height: 100%;
            right: 0;
            z-index: -1;
            content: none;
        }

        #searchForm {
            .autocomplete {

                &__input {
                    border-radius: unset;
                    border: solid 1px;
                    border-color: variables.$neutral-lighter-25;
                    border-radius: 6px;
                }

                &__search-button {
                    background-color: unset;
                }
            }
        }

        .icon-menu {
            .user-icon {
                content: url('/images/novastar/user_icon-no-circle.svg');
                border-right: 1px solid variables.$neutral-lighter-25;
            }
        }

        .openPanel {
            color: variables.$neutral-darker;
            font-weight: 400;


            .button__text {
                color: variables.$neutral-darker;


                .text-small {
                    color: variables.$primary;
                }
            }
        }
    }

    .cart-icon, #cart-icon {
        position: relative;

        .g-circle {
            stroke: variables.$color-black;
            fill: none;
        }

        circle {
            display: none;
        }

        path {
            stroke: none;
            fill: variables.$color-black;
        }

        &--active {
            circle, path {
                fill: variables.$color-black;
            }
        }
    }

    .tabs--main {

        .tabs__item {
            color: variables.$primary-light;
            font-weight: 400;

            &:hover {
                text-decoration: underline;
            }

            &--active {
                color: variables.$white;
                background-color: variables.$primary;
            }
        }
    }

    #categoryMenu, #servicesMenu {
        background: variables.$primary;
    }

    .main-nav {
        color: variables.$white;

        .nav-item__link {
            background-color: variables.$primary;
            color: variables.$white;
        }

        .nav-item__label-name, .nav-item__label-text {
            color: variables.$white;
        }

        &__list {
            background: unset;
        }
    }

    .cart-r {
        fill: variables.$color-black;
    }

    .icon-menu--active .count {
        background: variables.$primary;
        color: variables.$white;
    }

    @media (max-width: variables.$responsive-break - 1) {
        #login-panel .logo-brand {
            content: url('/images/novastar/novastar-logo-mobile.svg');
        }

        .main-nav {
            color: variables.$color-black;

            .nav-item__link {
                background-color: variables.$white;
                color: variables.$color-black;
            }

            .main-nav__item:not(:last-child) {
                .nav-item__link {
                    border-bottom: none;
                }
            }

            .nav-item__label-name, .nav-item__label-text {
                color: variables.$color-black;
            }
        }
    }
}