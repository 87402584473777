@use "../../variables";

.tags {
    display: flex;
    align-items: baseline;
    flex-direction: column;

    .tags {

        &__wrapper {
            align-items: center;

            .tags__list {
                align-items: baseline;
            }
        }
    }

    &__result {

        .counter {
            color: variables.$neutral-darker;
            font-weight: variables.$font-weight-bold;
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;

        .tags {

            &__list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &__item {
                position: relative;
                margin: 0 8px 8px 0;
                color: variables.$primary;
                padding: 6px 12px;
                border-radius: 154px;
                display: flex;
            }

            &__title {
                margin-right: 25px;
                word-break: break-word;
            }
        }
    }

    &__remove {
        position: absolute;
        right: 0;
        top: 0;
        height: 30px;
        width: 30px;
        background: transparent;
        border: 0;
        color: variables.$primary;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }
}

.tags__wrapper--primary {

    .tags__item {
        color: variables.$primary;
        background: variables.$primary-lighter-10;

        &:hover {

            .tags__remove {
                background: variables.$neutral-lighter-50;
            }

            .tags-remove:hover {
                background: variables.$supporting-01;
            }
        }

        &--jumplist {
            border: 1px solid variables.$primary;
            background: variables.$white;
            span {
                margin: 0;
            }
        }
    }

    .tags__remove {
        border-radius: variables.$border-radius-circle;

        &:active {
            background: variables.$supporting-01-dark;
        }


        .icon {
            padding: 0;
        }
    }
}


.toggle {
  display: flex;

  &__button {
    width: 40px;
    height: 40px;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
