@use '../../variables';

.icon {
    display: inline-flex;
    padding: 0 .5rem;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
    
    &.half-opacity {
        opacity: 0.5;
    }

    &--hollow {
        pointer-events: none;
    }

    &--lightblue {
        color: variables.$primary-lighter-50;
    }

    &--back {
        color: variables.$secondary;
    }
    
    &--large i{
        font-size: variables.$font-size-3xl;
        line-height: 1.7;
    }

    &--medium i{
        font-size: variables.$font-size-xl;
        line-height: 1.7;
    }
}

.services-list__item .icon {
    padding: 0 5px 0 0;
}

.shipping-list__item .icon {
    padding: 0;
}

.ok-tick {
    color: variables.$supporting-03;
    font-size: variables.$font-size-7xl;
}