@use "../variables";

.accordion.accordion--services {
    max-width: variables.$grid__bp-lg * 1px;
    margin: 0 auto;

    .accordion__item {
        list-style: none;
        margin: 0;

        .accordion__title {
            padding: 24px 12px;
            background: variables.$neutral-lighter-25;
            justify-content: space-between;
            font-weight: variables.$font-weight-bold;

            .accordion__date {
                font-weight: variables.$font-weight-normal;
                color: variables.$neutral-dark;
                font-size: variables.$font-size-sm;
            }
        }

        .accordion__content {
            background: variables.$neutral-lighter-25;
        }
    }
}
