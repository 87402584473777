@use "../../variables";
@use "../../mixins";

.social-auth {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    &__item {
        margin-bottom: 10px;
        $fb_darkblue: #4267B2;
        .btn{
            width: 370px;
            height: 40px;
            border-radius: 3px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: bold;

            &.login-btn{
                width: 250px;
            }
            
            &__fb {
                background-color: $fb_darkblue;
                border: 1px solid $fb_darkblue;
                color: white;
            }

            &__google {
                background-color: variables.$white;
                border: 1px solid variables.$neutral-lighter;
                color: #3C4043;
            }
        }

    }
}
.login {
    &__wrapper {
        flex: 1;
        background: variables.$neutral-lighter-25;
        padding: 24px 24px 12px 24px;
        
        button[type="submit"],
        button[type="button"]{
            display: flex;
            width: auto;
            margin: auto;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
    }
}


.recover {
    display: none;

    &__title {
        margin-top: 16px;
        margin-bottom: 32px;
        font-weight: variables.$font-weight-bold;
    }

    .input-field {
        margin-bottom: 32px;

        &__label {
            flex-basis: 0;
        }
    }

    &__back {
        min-width: unset;
    }
}

.static {

    &__title,
    &__value {
        margin: 0;
    }

    &__value {
        font-size: variables.$font-size-xl;
        font-weight: variables.$font-weight-bold;
    }

    &__field {
        margin-bottom: 28px;
    }
}

.login-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;

    &__image {
        @if variables.$site-nova {
            content: url('/images/novastar/novanaut-with-shadow.png');
            height: 230px;
        } @else if variables.$site-avi {
            content: url('/images/avitela/avitela_login_logo.svg');
        } @else {
            width: 190px;
            height: 144px;
            content: url('/images/login_hero.svg');
        }
    }

    &__title {
        font-weight: variables.$font-weight-bold;
        margin-top: 16px;
        color: variables.$neutral-darker;
        font-size: variables.$font-size-lg;
    }
}

.login__form {

    .forgot {
        font-weight: bold;
        color: variables.$primary;
    }
}


@media (max-width: variables.$responsive-break - 1) {
    form.login__form.responsive-login-text-white {
        .static__field p, p:nth-child(2) {
            color: variables.$white;
        }
    }
}

#login-panel.register, #cart-panel-wrapper.register {
    .login-password, .forgot {
        display: none;
    }
}

.secure-login-container {
    @include mixins.my-info-container;
    &{
        display: block;
        max-width: 450px;
        margin: 10px auto; 
    }

    h3 {
        text-align: center;
        margin-top: 24px;
    }
}

@media (min-width: variables.$responsive-break) {
    .login__section {
        height: 100%;
    }
    .panel--login {

        .panel__header {
            padding: 0;
        }

        .panel__close {
            position: absolute;
            right: 24px;
            top: 24px;
        }


        .alert {
            margin-bottom: 20px;
            display: none;

            &__content {
                display: flex;

                .alert__icon {
                    align-items: baseline;
                }
            }

            &-validation--active {
                display: flex;
            }
        }
    }

    .login__wrapper .accordion-toggle-handle {
        max-width: 550px;
        margin: auto;
    }
}

@media only screen and (max-height: 769px) {

    .login-hero {
        flex-direction: row;
        flex-basis: 0;
        padding: 10px 0;


        &__image {
            @if variables.$site-nova {
                //Novanaut is in landscape.
                width: 50px;
            } @else {
                //Euronics hero is in portrait.
                width: 105px;
            }
            height: 80px;
        }

        &__title {
            margin-left: 40px;
        }
    }
}

@media only screen and (max-height: 608px) {

    .panel {
        overflow-y: auto;
    }
}