@use "../../../variables";

.payment-button {
    padding: 12px 10px;
    background: transparent;
    border: solid 1px variables.$neutral-lighter;
    border-radius: variables.$border-radius-rounder;
    width: 240px;
    height: 60px;

    &:hover {
        background-color: variables.$neutral-lighter-50;
    }

    &:hover:not(.payment-button--active) {
        border: solid 1px variables.$neutral-lighter;
    }


    &--active {
        background-color: transparent;
        border: solid 2px variables.$secondary;
    }

    &__img {
        max-width: 120px;
        max-height: 25px;
        &.icon-smart-rent{
            width: 190px;
            max-width: 190px;
        }
    }

    &.no-padding {
        padding: unset;
    }
    
    &.credit-card-button{
        color: variables.$black;
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-default;
        display: flex;
        justify-content: space-around;
    }
    .credit-card__expiration{
        color: variables.$neutral;
        font-size: variables.$font-size-xs;
    }
    
}
