@use "../../variables";

.btn-remove{
    position: relative;
    z-index: 1;
    height: 30px;
    width: 30px;
    min-width: 30px;
    font-size: variables.$font-size-default;
    border-radius: 50%;
    margin: 0 auto;
    background: variables.$secondary;
    cursor: pointer;
}
.comparison-footer {
    flex-direction: column;
    background-color: variables.$neutral-lighter-20;
    box-shadow: variables.$top-shadow;

    &__container {
        z-index: variables.$zindex-comparison-footer;
        &--active{
            .comparison-footer{
                display: block;
            }
        } 
        &:not(.comparison-footer__container--active){
            .comparison-footer{
                display: none;
            }
            .icon{
                transform: rotate(180deg);
            }
        } 
        & {
            position: fixed;
            inset: auto 0 0 0;
        }
    }
    
    .alert {
        display: block;
    }
    .comparison-sticky{
        &__container{
            display: flex;
            max-width: 1216px;
            margin: 0 auto;
        }
        &__list{
            justify-content: center;
            display: flex;
            width: 100%;
            overflow: auto;
            gap: 10px;
            background-color: variables.$neutral-lighter-20;
            padding: 16px;
        }
        &__item{
            background: variables.$white;
            border: 1px solid variables.$gray-100;
            border-radius: 6px;
            .btn-remove{
                position:absolute;
                margin: 6px 8px 0 180px;
                visibility: hidden;
            }
            &:hover .btn-remove{
                visibility: visible;
            }
            .responsive{
                display: none;
            }
        } 
    }
    
    &__buttons {
        display: none;

        &--active {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

  &__close {

        &:hover {

            .icon {
                color: variables.$neutral;
            }
        }

        .icon {
            color: variables.$neutral-lighter-100;
        }
    }

    &--bottom {
        display: flex;
        align-items: center;
    }
    
    &__toggle{
        width: 80px;
        height: 36px;
        background: variables.$primary;
        border-radius: 0 10px 0 0;
        border: none;
        &:hover{
            cursor: pointer;
        }
        .icon{
            color: variables.$secondary;
        }
    }
}

.comparison-message__container{
    position: fixed;
    top:0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: variables.$zindex-panel-overlay;
    background: variables.$primary-lighter-50;
}

@media only screen and (max-width: 1650px) and (min-width: 1216px) {
    .comparison-footer .comparison-sticky__item {
        min-width: 215px;
        max-width: 215px;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .comparison-footer, .comparison-footer__container {

        &:before,
        &:after {
            content: none;
        }
    }

    .comparison-footer {
        min-width: 100%;

        p.alert {

            &__text {
                color: variables.$white-500;
            }

            &__title {
                color: variables.$white-500;
                font-size: variables.$font-size-default;
            }
        }

        .button--small .button__text {
            line-height: 30px;
        }
        .comparison-sticky{
            &__list{
                flex-wrap: wrap;
                .button {
                    &--small {
                        min-width: auto;
                    }
                }
            }
            &__item{
                width: auto;
                justify-content: center;
                .btn-remove{
                    visibility: visible;
                    width: 30px;
                    height:30px;
                    margin: -10px 0 0 45px;
                    .button__text{
                        font-size:variables.$font-size-xs;
                    }
                }
            }
            &__link{
                display:none;
            }
            &__container{
                justify-content: center;
            }
        }

        .comparison-footer__buttons {
            flex-direction: row;
            width: 100%;
            justify-content: center;
        }
    }
}