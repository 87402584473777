@use "variables" as faVariables;
@use "mixins";
// Rotated & Flipped Icons
// -------------------------

.#{faVariables.$fa-css-prefix}-rotate-90 { @include mixins.fa-icon-rotate(90deg, 1);  }

.#{faVariables.$fa-css-prefix}-rotate-180 { @include mixins.fa-icon-rotate(180deg, 2); }

.#{faVariables.$fa-css-prefix}-rotate-270 { @include mixins.fa-icon-rotate(270deg, 3); }

.#{faVariables.$fa-css-prefix}-flip-horizontal { @include mixins.fa-icon-flip(-1, 1, 0); }

.#{faVariables.$fa-css-prefix}-flip-vertical { @include mixins.fa-icon-flip(1, -1, 2); }

.#{faVariables.$fa-css-prefix}-flip-both,
.#{faVariables.$fa-css-prefix}-flip-horizontal.#{faVariables.$fa-css-prefix}-flip-vertical { @include mixins.fa-icon-flip(-1, -1, 2); }

// Hook for IE8-9
// -------------------------

:root {

  .#{faVariables.$fa-css-prefix}-rotate-90,
  .#{faVariables.$fa-css-prefix}-rotate-180,
  .#{faVariables.$fa-css-prefix}-rotate-270,
  .#{faVariables.$fa-css-prefix}-flip-horizontal,
  .#{faVariables.$fa-css-prefix}-flip-vertical,
  .#{faVariables.$fa-css-prefix}-flip-both {
    filter: none;
  }
}
