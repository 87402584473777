@use "../../variables";

.reviews {
    padding-bottom: 200px;
    position: relative;
    background: variables.$neutral-lighter-25;

    &__wrapper {
        padding-top: 64px;
    }



    &__rating {
        display: flex;
        justify-content: center;
    }

    &__feedback {
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        padding-left: 80px;
    }

    .tabs-menu {
        margin: 0;
        width: 100%;
        margin-bottom: 16px;
    }
}

.loadMore {
    background: variables.$neutral-lighter-100;
    font-size: variables.$font-size-default;
    font-weight: variables.$font-weight-bold;
}
