@use "variables" as faVariables;

// List Icons
// -------------------------

.#{faVariables.$fa-css-prefix}-ul {
  list-style-type: none;
  margin-left: calc(faVariables.$fa-li-width * (5/4));
  padding-left: 0;

  > li { position: relative; }
}

.#{faVariables.$fa-css-prefix}-li {
  left: - faVariables.$fa-li-width;
  position: absolute;
  text-align: center;
  width: faVariables.$fa-li-width;
  line-height: inherit;
}
