@use "../../variables";

.checkbox {
    position: relative;
    display: flex;

    &__input {
        vertical-align: top;
        position: absolute;
        left: 0;

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            ~ .checkbox__label {
                color: variables.$neutral-lighter;
                font-weight: variables.$font-weight-normal;
            }

            &:before {
                border: solid 1px variables.$neutral-lighter !important;
            }

            &:after {
                border-left: 2px solid variables.$neutral-lighter;
                border-bottom: 2px solid variables.$neutral-lighter;
            }
        }

        &::before {
            position: absolute;
            content: "";
            display: inline-block;
            height: 24px;
            width: 24px;
            box-sizing: border-box;
            border: 1px solid variables.$neutral;
            background-color: variables.$white;
            border-radius: 2px;
            left: 0;
        }


        &:focus, &:focus-visible {
            outline: none; // Visible system checkbox outline on focus, WCAG 2.4.7

            &:before {
                outline: rgb(59, 153, 252) auto 5px; // WCAG 2.4.7
            }
        }

        &:checked {

            &:before {
                border-color: variables.$primary;
                content: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M20 43L25 37L40 52L65 26L71 32L40 63Z' fill='%231D428A'/%3e %3c/svg%3e");
            }
        }
    }

    &__label {
        position: relative;
        padding-left: 40px;
        height: 100%;
        width: 100%;
        line-height: 26px;
        min-height: 24px;
        word-break: break-word;

        &:hover {
            cursor: pointer;
        }
    }
    :disabled::before{
        background-color: variables.$neutral-lighter;
    }
    &__readonly {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        border: 1px solid variables.$neutral;
        box-sizing: border-box;

        .checked {
            content: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M20 43L25 37L40 52L65 26L71 32L40 63Z' fill='%23C0C3CE'/%3e %3c/svg%3e");
        }
    }

    .tooltip__wrapper {
        margin: auto;
    }

    .counter {
        margin: auto auto auto 8px;
    }
    
    &--column {
        flex-direction: column;
        a.openModal {
            margin-left: 40px;
        }
    }
}
