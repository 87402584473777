@use "../../variables";

.breadcrumbs {
  padding: 0;
  color: variables.$neutral;

  &__item {
    display: inline-block;
    list-style: none;
    font-size: variables.$font-size-sm;

    &:before {
      content: ">";
      margin: 0 10px;
    }

    &:first-child {

      &:before {
        content: "";
        margin: 0;
      }
    }

    &:hover {

      .breadcrumbs__link {
        text-decoration: underline;
      }
    }
  }

  &__link {
    color: variables.$neutral;
  }
}