@use "../../variables";
@use "../../mixins";

.news-landing {

    .image {

        &__wrapper {
            position: relative;
        }
    }
}

.vertical-list--large .image__wrapper {
    height: 100px;
}

@media (min-width: variables.$responsive-break) {
    .news-tab-content {
        margin-top: 56px;
    }

    .first-news {
        display: flex;

        &__text {
            margin-left: 32px;
        }
        
        img {
            max-width: 800px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .image__wrapper .image {
        max-width: 100%;
    }

    .news-header, .news-landing .combos {
        display: none;
    }

    .vertical-list {
        &__title {
            display: none;
        }
    }
}

@media (max-width: variables.$mobile-break - 1) {
    .first-news, .news-landing .news {
        flex-wrap: wrap;
    }

    .first-news__text {
        @include mixins.news-text-short;
    }

    .vertical-list {
        &__title {
            display: none;
        }

        &__item {
            border-bottom: solid 1px variables.$neutral-lighter-100;
            padding: 10px;

            .image__wrapper, .paragraph, .date, .news-article__tag {
                display: none;
            }

            .title {
                color: variables.$primary;
            }
        }
    }
}

@media (min-width: variables.$mobile-break) and (max-width: variables.$responsive-break - 1) {
    .first-news {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(2, 1fr);
    }
}
