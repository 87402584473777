@use '../variables';
@use 'sass:string';

/* stylelint-disable */
$spaceamounts: (0);
$sides: (x, y);

.util {
    @each $space in $spaceamounts {
        @each $side in $sides {
            @at-root #{&}-m#{string.slice($side, 0, 1)}-#{$space} {
                @if $side == x {
                    margin-left: #{$space }rem !important;
                    margin-right: #{$space}rem !important;
                }

                @else if $side == y {
                    margin-top: #{$space}rem !important;
                    margin-bottom: #{$space}rem !important;
                }
            }

            @at-root #{&}-p#{string.slice($side, 0, 1)}-#{$space} {
                @if $side == x {
                    padding-left: #{$space}rem !important;
                    padding-right: #{$space}rem !important;
                }

                @else if $side == y {
                    padding-top: #{$space}rem !important;
                    padding-bottom: #{$space}rem !important;
                }
            }
        }
    }
}


$color-map: ( "primary" : variables.$secondary, "blue": variables.$primary, "dark": variables.$neutral-darker, "success" : variables.$supporting-03, "gray": variables.$neutral-darker, "danger" : variables.$supporting-01-dark, "light" : variables.$neutral, "white": variables.$white );


@each $name, $color in $color-map {

    .text-#{$name} {
        color: $color;
    }
}

$spaceamounts: (auto, 0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 80, 104, 128);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
    @each $side in $sides {
        @if $space == auto {
            .m-#{string.slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space} !important;
            }
        }
        @else {

            .m-#{string.slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important;
            }

            .p-#{string.slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important;
            }
        }
    }
}

.flex-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.lh-small {
    line-height: variables.$lh-small;
}

.text-small {
    font-size: variables.$font-size-sm;
}

.text-medium {
    font-size: variables.$font-size-md;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.position-relative {
    position: relative;
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
    text-align: right;
}

.align-self-flex-start {
    align-self: flex-start;
}

.flex-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline !important;
}

.align-flex-end {
    align-items: flex-end;
}

.responsive-image {
    height: auto;
    max-width: 100%;
}


.flex {
    &-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-self-center {
        align-self: center;
    }

    &-nowrap {
        flex-wrap: nowrap;
    }
}

.h-500 {
    height: 500px;
}

.h-0 {
    height: 0;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.d-none {
    display: none !important;
}

.spacer {
    width: 100%;
    border-top: solid 1px transparent;

    &--ltgray {
        border-color: variables.$neutral-lighter-100;
    }
}

.text-small {
    font-size: variables.$font-size-sm;
}

.text-large {
    font-size: variables.$font-size-lg;
}

.text-xlarge {
    font-size: variables.$font-size-xl;
}

.text-strikethrough, .del {
    text-decoration: line-through;
}

.util-sr-only {
    position: absolute !important; /* Outside the DOM flow */
    height: 1px;
    width: 1px; /* Nearly collapsed */
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
    clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.responsive-image {
    max-width: 100%;
    height: auto;
}

.font-weight-bolder{
    font-weight: variables.$font-weight-bolder !important;
}

.font-weight-bold {
    font-weight: variables.$font-weight-bold !important;
}

.font-weight-normal {
    font-weight: variables.$font-weight-normal !important;
}

.text-underline {
    text-decoration: underline;
}

.link {

    &:hover {
        text-decoration: underline;
    }
}

.bg--ltgray {
    background: variables.$neutral-lighter-25;
    color: variables.$primary;

    h1, h2, h3, h4, h5, h6, p, body {
        color: variables.$primary;
    }
}

.bg--blue {
    background: variables.$primary;
    color: variables.$white;

    h1, h2, h3, h4, h5, h6, p, body {
        color: variables.$white;
    }
}

.bold {
    font-weight: variables.$font-weight-bold;
}

.red {
    color: variables.$supporting-01-dark;
}

.inline-block {
    display: inline-block;
}

hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid variables.$neutral-lighter;
}

.blue {
    color: variables.$primary;
}

.disclaimer {
    color: variables.$neutral;
    text-align: center;
}

body.panel-open, body.modal-open {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

@media (min-width:variables.$responsive-break) {
    .wide-d-none {
        display: none !important;
    }

    .bg-full {

        &--gray {
            background-color: variables.$neutral-lighter-25;
        }
    }
}

@media (min-width:variables.$mobile-break) {
    .larger-d-none {
        display: none !important;
    }
}


@media (max-width:variables.$responsive-break - 1) {
    .narrow-d-none {
        display: none !important;
    }
}
