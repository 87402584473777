@use "../../variables";

.icon-cookie {
    content: url('/images/cookie.png');
    height: 48px;
    width: 48px;
    margin: auto 0;
}

.icon-comparison{
    content: url('/images/comparison.svg');
    height: 48px;
    width: 48px;
    margin: auto 0;
}

.icon-double-arrow-right {
    content: url('/images/double-arrow-right.svg');
}

.logo-my-euronics {
    @if (variables.$site-nova) {
        content: url('/images/novastar/novanaut-head-curved-line.png');
        width: 60px;
        padding: 0 16px;
    }
    @else if(variables.$site-avi){
        content: url('/images/avitela/avitela_account.svg');
    }
    @else {
        content: url('/images/my_euronics_logo.svg');
    }
}

.header .icon-phone {
    @if variables.$site-avi {
        content: url('/images/phone_icon_black.svg');
    } @else {
        content: url('/images/phone_icon_gray.svg');
    }
    position: relative;
    top: 5px;
}