@use "../../variables";
@use "../../mixins";

.input-field {
    align-items: center;
    width: 100%;
    min-width: 0;
    display: flex;

    &--vertical {

        .input-field__label {
            padding: 0;
            padding-bottom: 8px;
        }
    }

    &--center {
        align-items: center;
    }
}

.input-field-editable {

    &--active {
        @include mixins.input-field-editable;
    }
}

.input-field__label {
    @include mixins.input-field-label;
}

.input-field__input-container {
    @include mixins.input-field-container;

    .currency {
        margin: 15px 0 0 345px;
        position: absolute;
        color: variables.$neutral;
    }
}

.input-field__input {
    @include mixins.input-field-input;

    &--currency {
        padding-right: 20px;
        text-align: right;

        &.readonly {
            color: variables.$neutral;
            background-color: variables.$neutral-lighter-25;
            border: none;
        }
    }
}

.input-field__helper-text {
    @include mixins.input-field-helper-text;
}

.input-field__helper-link {
    @include mixins.input-field-helper-link;
}
