@use "../../variables";

.discount-overlay {
    fill: variables.$supporting-04;

    text {
        fill: variables.$white;
        font-weight: variables.$font-weight-bold;
        font-family: variables.$font-family;
        font-size: variables.$font-size-md;
        dominant-baseline: middle;
        text-anchor: middle;
    }
}
