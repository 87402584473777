@use "../variables";
@use "../mixins";

.product-desc {
    .breadcrumbs {
        display: none;
    }

    &--grid {
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-gap: 32px;
    }

    .tabs-menu {
        margin-top: 8px;


        &--active {
            box-shadow: 0 -2px 4px rgba(38, 47, 68, .25);

            .tabs-menu__list {
                height: 64px;
            }

            &:before,
            &:after {
                height: 64px;
                box-shadow: 0 -2px 4px rgba(38, 47, 68, .25);
            }
        }
    }

    .recently-viewed {
        margin-bottom: 50px;
    }

    .shipping-methods--responsive {
        display: none;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .product-desc {
        padding: 0 8px;

        .breadcrumbs {
            display: block;
        }
        
        .different-for-mobile{
            .tabs-menu {
                &__item {
                    @include mixins.menu-item-three-columns;

                    .icon {
                        max-width: 40px;

                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                }

                &__link {
                    font-weight: normal;
                    font-size: 20px;
                }
            }
        }


        &__wrapper {
            flex-direction: column;
            align-items: center;
            margin: 10px 0;
        }

        .shipping-methods {
            &--responsive {
                display: block;
                background: variables.$neutral-lighter-25;
                border: 1px solid variables.$neutral-lighter-50;
                border-radius: 10px;
                padding: 14px 10px;
            }

            &--header {
                display: grid;
                grid-template-columns: 66px 6fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas: 
                    "icon title" 
                    "icon subtitle";
                margin-bottom: 18px;

                .icon {
                    grid-area: icon;
                    padding-right: 16px;
                }

                .title {
                    grid-area: title;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;
                    color: variables.$black-500;
                }

                .subtitle {
                    grid-area: subtitle;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    color: variables.$neutral-light
                }
            }


            &--item {
                background: variables.$white-500;
                border: 1px solid variables.$neutral-lighter-100;
                border-radius: 10px;
                grid-template-columns: minmax(0, 1fr) fit-content(100%);

                
                @if (variables.$site-nova-or-avi) {
                    grid-template-columns: fit-content(100%) minmax(0, 1fr) fit-content(100%);;
                }

                .shipping-list {
                    &__name {
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 22px;
                        color: variables.$black-500;
                    }

                    &__time {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: variables.$neutral-darker-opaque;
                    }
                }

                .shipping {
                    &__wrapper{
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }
                    &__price {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: variables.$primary;
                        align-self: flex-end;
                        padding: 0 0 8px 0;
                    }

                    &__details {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: variables.$neutral-lighter;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}


@media (min-width: variables.$responsive-break) {
    .product-desc {

        .shipping {
            margin-top: 16px;
        }
    }
}