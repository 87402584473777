@use "../../variables";

.radio-tabs {
    display: flex;

    .content {
        display: none;
    }

    label {
        order: -1;
        display: flex;
        flex: 1;
        padding: 10px;
        color: variables.$primary;
        font-size: variables.$font-size-default;
    }

    [type=radio] {
        display: none;
    }

    [type=radio]:checked + label {
        font-weight: variables.$font-weight-bold;
    }

    [type=radio]:checked + label + .content {
        display: block;
        flex-basis: 100%;
        margin: 32px;
    }

    .custom-radio__label {
        height: initial;
    }
}

/* all but mobile */
@media (min-width: variables.$mobile-break) {
    .radio-tabs {
        flex-wrap: wrap;

        [type=radio]:checked + label {
            border-bottom: 4px solid variables.$secondary;
        }

        label {
            text-align: center;
            justify-content: center;
            border-bottom: solid 1px variables.$neutral-lighter;
        }

        .custom-radio__input, .custom-radio__input:checked {
            & + .custom-radio__label {
                &::before, &::after {
                    display: none;
                }
            }
        }
    }




}

/* mobile */
@media (max-width: variables.$mobile-break - 1) {
    .radio-tabs {
        flex-direction: column;
        padding: 16px;

        [type=radio] + label:not(:first-of-type) {
            border-top: 1px solid variables.$neutral-light;
        }

        label {
            order: 0;
            justify-content: flex-start;
            padding-left: 32px;
        }
    }
}
