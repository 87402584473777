@use "mixins";
/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
@use "_core/core";
@use "buttons/button/button";
@use "buttons/load_more_button/load_more_button";
@use "buttons/back_to_top_button/back_to_top_button";
@use "content_page_slider/content_page_slider";
@use "comparison/comparison_demo/comparison_demo";
@use "comparison/_comparison_sticky_tab/comparison_sticky_tab";
@use "comparison/accordion/accordion";
@use "comparison/comparison_footer/comparison_footer";
@use "form/form";
@use "grid/grid";
@use "home_page_components/home_page_banner/home_page_banner";
@use "home_page_components/home_page_carousel/home_page_carousel";
@use "home_page_components/home_page_evertink/home_page_evertink"; // LT only
@use "home_page_components/home_page_info/home_page_info";
@use "home_page_components/home_page_news/home_page_news";
@use "icons/icon/icon";
@use "icons/icon-circle/icon-circle";
@use "icons/icons/icons";
@use "menus/breadcrumb/breadcrumb";
@use "menus/sort-menu/sort-menu";
@use "menus/tabs/tabs";
@use "news/news_article/news_article";
@use "news/news_landing/news_landing";
@use "news/news_tags/news_tags"; // LT only
@use "news/quote/quote";
@use "news/vertical_list/vertical_list";
@use "patterns/_added_cart_modal/added_cart_modal";
@use "patterns/_added_cart_panel/added_cart_panel";
@use "patterns/_badge/badge";
@use "patterns/_footer-list/footer-list";
@use "patterns/_product_rating/product_rating";
@use "patterns/_services_list/services_list";
@use "patterns/additional_info/additional_info";
@use "patterns/breadcrumbs/breadcrumbs";
@use "patterns/carousel_card/carousel_card";
@use "patterns/description_tab/description_tab";
@use "patterns/discount_overlay/discount_overlay";
@use "patterns/flash_sales/flash_sales";
@use "patterns/footer/footer";
@use "patterns/modal/modal";
@use "patterns/modal/post_package_modal";
@use "patterns/more_separator/more_separator";
@use "patterns/product_image/product_image";
@use "patterns/product_info/product_info";
@use "patterns/product_modal/product_modal";
@use "patterns/recently_viewed/recently_viewed";
@use "patterns/shipping/shipping";
@use "patterns/tabs_menu/tabs_menu";
@use "patterns/tooltip/tooltip";
@use "patterns/responsive/responsive";
@use "patterns/_spare_parts_form_panel/spare_parts_form_panel";
@use "product_description/product_description";
@use "product_view/bonus/bonus";
@use "product_view/product_card/product_card"; // Does not belong in main
// TODO: split into product list view and product details
@use "product_view/product_tags/product_tags";
@use "tab-content/_items_carousel/items_carousel";
@use "tab-content/availability/availability";
@use "tab-content/combos/combos";
@use "tab-content/discussion_card/discussion_card";
@use "tab-content/discussion_card/discussion";
@use "tab-content/review_card/review_card";
@use "tab-content/review_rating/review_rating";
@use "tab-content/reviews/reviews";
@use "tab-content/specification/specification";
@use "tab-content/energy_label/energy_label";
@use "tab-content/links/links";
@use "form/slider/slider";
@use "checkout_flow/thank_you";
@use "checkout_flow/shared";
@use "sliders/minu_euronics_login/minu_euronics_login";
@use "sliders/ostukorv_slider_demo_collapse/ostukorv_slider_demo_collapse";
@use "sliders/slider_logged_in/slider_logged_in";
@use "sliders/_minu_euronics/_login_buttons/login_button";
@use "sliders/_minu_euronics/_panel/panel" as slider-panel;
@use "panel/panel" as general-panel;
@use "sliders/customer-service-slider/customer-service-slider";
@use "zendesk/zendesk";
@use "giftcard/giftcard";
@use "in-ads/in-ads";
@use "financial/financial";
@use "_utils/utils";
@use "accordion/accordion-services";
@use "home_page/home_page";
@use "notify_page_error/notify_page_error";

/* Peab asuma peale vormi väljade stiili */
@use "_utils/validation";

@use "../../../src/fonts/Fontawesome/fontawesome";
@use "../../../src/fonts/Fontawesome/solid";
@use "../../../src/fonts/Fontawesome/regular";
@use "../../../src/fonts/Fontawesome/duotone";

@use "~tiny-slider/src/tiny-slider";
@use "cookie";