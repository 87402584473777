@use "../../variables";

.product-bonus {
    padding: 16px;
    display: flex;
    align-items: center;
    border-radius: variables.$border-radius-round;

    &__icon {
        padding-left: 16px;
        padding-right: 32px;
    }

    &__title {
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;
        line-height: 24px;
    }

    &__text {
        font-size: variables.$font-size-sm;
        line-height: 24px;
    }

    &--primary {
        background: variables.$primary-lighter-10;
        @if (variables.$site-nova) {
            background-color: variables.$neutral-lighter-25;
        }
    }

    &__content {
        margin-left: 16px;
    }

    &__link {
        line-height: 24px;
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;

        &:visited {
            color: variables.$primary;
            font-weight: variables.$font-weight-bold;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

@media(max-width:variables.$responsive-break - 1) {
    .product-bonus {
        &__icon {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    }