@use "../../variables";
@use "../../site";
$autocomplete-background: variables.$white;
@if variables.$site-avi {
    $autocomplete-background: variables.$neutral-lighter-25;
}
$autocomplete-height: 40px;
$autocomplete-border-radius: 20px;

.autocomplete {
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
}

.autocomplete__input {
    height: $autocomplete-height;
    width: 100%;
    background-color: $autocomplete-background;
    padding: 0 1rem;
    border-radius: $autocomplete-border-radius;
    box-sizing: border-box;
    transition: .2s all;
    font-size: variables.$font-size-default;
    border: solid 3px transparent;

    &:focus {
        outline: none;
        border-color: variables.$secondary-darker;
        border-width: 3px;
        box-shadow: 0 12px 24px rgba(86, 86, 86, .25), 0 0 12px rgba(0, 0, 0, .25);

        .autocomplete__search-button {
            border-color: variables.$secondary-darker;
        }
    }
}

.autocomplete__search-button {
    cursor: pointer;
    position: absolute;
    width: 58px;
    height: 34px;
    padding: 0;
    right: 3px;
    background-color: $autocomplete-background;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$primary;
    border-radius: 0 25px 25px 0;
    z-index: 1;

    .icon {
        padding: 0;
    }

    &:hover {
        height: 40px;
        right: 0;
        background: variables.$secondary-darker;

        .icon {
            margin-right: 6px;
        }
    }
}

.autocomplete__search-result {
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: $autocomplete-height;
    margin-top: 10px;
    background-color: $autocomplete-background;
    color: variables.$neutral-darker;
    box-shadow: variables.$shadow-autocomplete-result;
    z-index: variables.$zindex-autocomplete;

    &--active {
        display: flex;
        flex-direction: column;
    }
}

.search-result-container {
    display: flex;

    .search-pane {
        flex: 1;
        padding: 8px 16px;

        &:last-child {
            min-width: 360px;
        }

        &.type-history #suggestions-title,
        #search-history-title,
        {
            display: none;
        }

        &.type-history #search-history-title,
        #suggestions-title {
            display: block;
        }

        #search-history-title.d-none {
            display: none;
        }
    }
}

#products-pane [data-search-item-name] {
    margin: 0 16px;
    width: 300px;
}

[data-search-item-price] {
    color: variables.$neutral;
    font-size: 16px;
    margin-left: auto;
    text-align: right;
    white-space: nowrap;
}

#suggestions-pane {
    background: variables.$neutral-lighter-25;
}

#suggestions-pane [data-search-item-name] {
    margin-right: auto;
}

.search-result__item {
    justify-content: space-between;
    margin: 8px 0;
    align-items: center;
    cursor: pointer;
    display: flex;

    &:hover {
        background-color: variables.$neutral-lighter-50;
    }
}

.suggestion-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: variables.$primary;
    margin-top: 16px;

    .icon {
        float: right;
        padding: 10px;
    }
}

#suggestions-documents-title .icon {
    content: url("/images/document.svg")
}

// trade-in, aadressiotsing
.autocomplete-list {
    background: variables.$white;
    position: absolute;
    z-index: variables.$zindex-positive;
    box-shadow: 0 0 4px rgba(35, 41, 69, .25);

    &__result {
        width: 100%;
        text-align: left;
        color: variables.$neutral-dark;
        border: 0;
        background-color: transparent;
        padding: 16px 8px;

        &:hover {
            cursor: pointer;
            background-color: variables.$neutral-lighter-25;
        }
    }
}

mark {
    font-weight: variables.$font-weight-bold;
    color: variables.$primary;
    background: transparent;
}

.autocomplete__wrapper {
    flex-grow: 0.75;
}

/* wide */
@media (min-width: variables.$responsive-break) {
    .search-result-container {
        .search-pane {

            &:first-child {
                margin-top: -16px;
                min-width: 440px;
            }
        }
    }
}

/* narrow */
@media (max-width: variables.$responsive-break - 1) {

    .autocomplete {
        &__overlay {
            height: 100%;
            top: 0;
            position: fixed;
            display: none;
            width: 100%;

            &.autocomplete__overlay--active {
                background: variables.$neutral-darker;
                display: block;
                z-index: variables.$zindex-header;
                opacity: .5;
            }
        }
    }

    .autocomplete__wrapper {
        display: none;
        grid-column: 2 / span 3;
        grid-row: 2;
        margin-bottom: 14px;
        z-index: variables.$zindex-autocomplete;

        &.visible {
            display: block;
        }

        .autocomplete__search-result {
            width: 100%;

            #search-pane {
                overflow-y: auto;

                #suggestions-title, #search-history-title {
                    display: none;
                }

                #products-pane {

                    [data-search-item-name] {
                        margin: 0 16px;
                        width: 100%;
                    }
                }

                .accordion {
                    width: 100%;
                    padding: 0 16px;
                    margin: 0 -16px;
                    background-color: variables.$gray-25;

                    .accordion__item {
                        .suggestion-title:after {
                            content: "+";
                            color: variables.$gray-300;
                            font-size: 28px;
                        }

                        &--active {
                            .suggestion-title:after {
                                content: "-";
                            }
                        }
                    }
                }
            }

            #suggestions-pane {
                display: none;
            }
        }
    }
}

/* mobile */
@media (max-width: variables.$mobile-break - 1) {
    #search-display-all-results {
        position: fixed;
        bottom: 10px;
        width: 75%;
    }
    .autocomplete__wrapper {
        grid-column: 1 / span 5;
        grid-row: 2;

        #products-pane {
            .search-result__item {
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
