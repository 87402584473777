@use "../../variables";
@use "../../mixins";

.panel {
    &--added-cart {
        flex-direction: column;
        height: 100%;

        .panel__header {
            display: flex;
            flex-direction: column;
            padding: 0;

            .back {
                border-bottom: 1px solid variables.$gray-50;

                .button {
                    font-size: 1.25rem;
                    font-weight: variables.$font-weight-normal;
                }

                .fa-chevron-left {
                    color: variables.$secondary;
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 0.5rem;
                padding: 1.5rem;
            }

            .panel__title {
                font-weight: variables.$font-weight-bolder;
                margin-bottom: 0.5rem;
            }

            h5 {
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
            }
        }

        .panel__footer {
            display: flex;
            flex-direction: row;
            padding: 1.5rem 2rem;
            column-gap: 0.75rem;
            background: variables.$white;
            box-shadow: 0 -2px 4px rgba(26, 46, 44, .10);;
            border-radius: 1px;
            :nth-child(1) {
                flex: 1;
            }

            :nth-child(2) {
                flex: 2;
            }

            .button__text {
                display: flex;
                justify-content: center;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-default;
            }
        }

        .panel__body {
            background-color: variables.$gray-25;
            padding: 2rem;
            overflow: hidden auto;
            height: 100%;
            h3 {
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
            }

            .related-product-section {
                display: flex;
                flex-direction: column;
                border-top: 1px solid variables.$gray-100;
                padding: 1.5rem 0;
                row-gap: 1.5rem;

                .view-more {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    color: variables.$primary;
                    font-weight: variables.$font-weight-normal;
                    font-size: variables.$font-size-sm;
                    text-decoration: underline;

                    ~ * {
                        display: none;
                    }
                }
            }

            .recommendation-card {
                display: grid;
                grid-template-columns: 80px auto fit-content(100%);
                grid-template-rows: auto;
                column-gap: 0.5rem;
                background-color: variables.$white;
                border-radius: 0.5rem;
                padding: 1.5rem 1rem 1rem 1rem;
                position: relative;
                .add-to-cart {
                    @include mixins.add-to-cart;
                    & {
                        align-items: end;
                        display: flex;
                    }

                    &--active {
                        display: flex;
                    }
                }
                &-link{
                    grid-row: 1 / span 2;
                    display: flex;
                    align-items: center;
                }
                
                .product-name {
                    overflow: hidden;
                    color: variables.$primary;
                    font-weight: variables.$font-weight-normal;
                    text-overflow: ellipsis;
                    &__container{
                        display: flex;
                        .tooltip__button .icon{
                            color: variables.$neutral;
                        }
                        grid-row: 1;
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    font-weight: variables.$font-weight-bold;
                    grid-row: 2;
                }
                
                .package-icon {
                    grid-row: 1 / span 2;
                    div {
                        width: 75%
                    }
                }
                .responsive-image{
                    max-width: 80px;
                }
                .add-to-cart{
                    grid-row: 1 / span2;
                }

                .recommended-label{
                    position: absolute;
                    top: -14px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background-color: variables.$supporting-09;
                    color: variables.$supporting-05;
                    
                    .card-label {
                        padding: 4px 10px;
                        text-transform: uppercase;
                        float: left;
                        line-height: 20px;
                        font-weight: variables.$font-weight-bolder;
                        font-size: variables.$font-size-xs;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }

        @media (max-width: variables.$responsive-break - 1) {
            display: flex;
            .panel__body .recommendation-card{
                .product-name__container{
                    grid-column: 2 / span 2;
                }
                .add-to-cart{
                    grid-row: 2 / span 1;
                    width: 100%;
                    justify-content: flex-end;
                }
            }
            .panel__footer{
                padding: 1.5rem 0.5rem;
                .button{
                    min-width: unset;
                    padding: 12px;
                    &__text{
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

