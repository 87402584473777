@use "../../variables";

/* Jump to another category in mobile category list view + filters and sorting section.*/

.sort-menu {
    padding: 0;
    display: inline-block;
    color: variables.$neutral-darker;
    width: 292px;

    &__heading,
    &__title {
        color: variables.$primary;
    }


    &__title {
        line-height: 150%;
        font-size: variables.$font-size-lg;
        font-weight: variables.$font-weight-normal;
    }
}


.sort-menu li {
    list-style: none;
}

.sort-menu__button,
.sort-menu__show-more {
    color: variables.$neutral-darker;

    .icon {
        padding-left: 0;
    }
}

.sort-menu {

    &__list {
        margin-top: 20px;

        .sort-menu__item {

            .sort-menu__jumplist--blue,
            .sort-menu__jumplist--blue:hover {
                background-color: variables.$primary;
                color: variables.$white;

                .icon {
                    color: variables.$white;
                }
            }

            .sort-menu__jumplist--yellow,
            .sort-menu__jumplist--yellow:hover {
                background-color: variables.$secondary;
                color: variables.$primary;

                .icon {
                    color: variables.$primary;
                }
            }

            .sort-menu__jumplist--white,
            .sort-menu__jumplist--white:hover {
                background-color: variables.$primary-lighter-10;
                color: variables.$black;

                @if (variables.$site-nova) {
                    background-color: variables.$neutral-lighter-25;
                }
                
                .icon {
                    color: variables.$black;
                }
            }
        }
    }

    &__button {
        font-size: variables.$font-size-default;
        padding: 0;
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
        border: 0;
        background: transparent;

        .icon {
            color: variables.$primary;
            padding-left: .5rem !important;
            margin-left: -.5rem;
        }
    }
}

span.counter {
    word-break: keep-all;
}

.show-more {
    color: variables.$primary;
    margin-left: 15px;
    background: transparent;
    border: 0;
    font-size: variables.$font-size-sm;
    font-weight: variables.$font-weight-bold;
}

.sort-menu__item {
    font-size: variables.$font-size-sm;
    color: variables.$neutral-darker;
    margin-bottom: 8px;

    .sort-menu__sub {
        display: none;
    }

    &--open {

        .sort-menu__button {
            background: variables.$neutral-lighter-25;
            border-radius: variables.$border-radius-round;
            color: variables.$primary-dark;

            @if (variables.$site-nova) {
                background: variables.$neutral-lighter-50;
            }

            .icon {
                color: variables.$primary-dark;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }

        .sort-menu__sub {
            display: block;
        }
    }

    .sort-menu__button {
        width: 100%;
        text-align: left;
        padding: 8px;

        &:hover {
            background: variables.$neutral-lighter-25;
            border-radius: variables.$border-radius-round;
        }

        .icon {
            -webkit-transition: all .5s ease;
            transition: all .5s ease;
        }
    }

    &:hover * {
        cursor: pointer;
    }

    .counter {
        margin-left: 8px;
        color: variables.$neutral;
        font-weight: variables.$font-weight-normal;
    }

    .checkbox {
        position: relative;
        padding-left: 4px;

        &__label {
            width: 100%;
            padding-top: 6px;
            padding-bottom: 4px;
            display: flex;
            justify-content: space-between;
            line-height: 26px;

            &:after {
                top: inherit;
            }
        }

        &:hover {
            border-radius: variables.$border-radius-round;
            background: variables.$neutral-lighter-25;
        }
    }

    .custom-radio {
        &:hover {
            border-radius: variables.$border-radius-round;
            background: variables.$neutral-lighter-25;
        }

        &__label {
            padding-top: 6px;
            padding-bottom: 4px;
            color: variables.$neutral-darker;
        }
    }
}

.sort-menu__sub {
    margin-top: 16px;
    margin-bottom: 28px;
    padding-left: 0;
    margin-left: 8px;

    .sort-menu__item {

        &:not(:first-child) {
            margin-top: 6px;
        }
    }

    .sort-menu__sub--open {
        display: list-item;
    }
}

a.sort-menu__button {
    display: block;
}

.product-list-sort {
    display: flex;
    align-items: center;
    max-width: 270px;

    span {
        padding-right: 10px;
    }
}

#list-sort-dropdown {
    min-width: 100%;
    border: 1px solid variables.$neutral-lighter;

    .dropdown__selected {
        color: variables.$neutral-darker;

        &:hover {
            background: inherit;
        }

        &--active {
            border-bottom: none;
        }
    }
}

.submit__filter {

    &__wrapper {
        display: none;

        &--visible {
            display: block;
            text-align: center;
            padding-top: 20px;
        }

        .submit__filter {
            display: inline-flex;
            padding: 0 5px 0 5px;

            .button__text {
                font-size: variables.$font-size-default;
            }
        }
    }
}

.price-filter {

    &__input {
        max-width: 100px;
    }

    &__wrapper {
        display: flex;
        align-items: baseline;
        justify-content: center;

        .input-field {
            width: auto;
        }

        span {
            padding: 10px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    #list-sort-dropdown {
        height: 44px;
    }
    #list-sort-dropdown .dropdown__selected span {
        display: inline-flex;
    }
}
