.date-input {
    display: flex;
    align-items: center;
    width: 100%;

    .input-field__input-container {
        flex-direction: row;

        div {
            padding-right: .5rem;
        }

        div:last-child {
            padding-right: unset;
        }

        .dropdown {
            height: unset;
        }

        .dropdown__selected {
            padding: 14px 8px;
        }
    }
}
