@use "variables" as faVariables;

// Bordered & Pulled
// -------------------------

.#{faVariables.$fa-css-prefix}-border {
  border: solid .08em faVariables.$fa-border-color;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.#{faVariables.$fa-css-prefix}-pull-left { float: left; }

.#{faVariables.$fa-css-prefix}-pull-right { float: right; }

.#{faVariables.$fa-css-prefix},
.fas,
.far,
.fal,
.fab {

  &.#{faVariables.$fa-css-prefix}-pull-left { margin-right: .3em; }

  &.#{faVariables.$fa-css-prefix}-pull-right { margin-left: .3em; }
}
