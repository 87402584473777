@use "../../variables";

input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radio {
    padding-left: 32px;
    position: relative;
    height: 30px;
    cursor: pointer;

    &__label {
        margin-left: 16px;
    }

    &--active {

        .radio__label {
            color: variables.$neutral-darker;
        }
    }

    &__list > * {
        margin-top: 16px;
    }
}

/* DEFAULT radio button*/
.checkmark {
    border: solid 1px variables.$neutral-lighter;
    background: variables.$white;
    position: absolute;
    left: 0;
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
    border-color: variables.$neutral;
}

// DISABLED STATE

.radio input[disabled] ~ .checkmark {
    background: variables.$neutral-lighter-25;
    border-color: variables.$neutral-lighter;
}

.radio:hover input[disabled] ~ .checkmark {
    border-color: variables.$neutral-lighter;
}

.radio:hover input[disabled]:checked ~ .checkmark {
    border-color: variables.$neutral-lighter;
}

.radio input[disabled]:checked ~ .checkmark:after {
    background: variables.$neutral-lighter;
}

.radio input:checked[disabled] ~ .checkmark {
    border-color: variables.$neutral-lighter;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
    border-color: variables.$primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: variables.$primary;
}

.custom-radio {

    &__input {
        // opacity: 0;
        display: none;
        position: absolute;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        &:disabled + .custom-radio__label,
        &:disabled + .radio-url > .custom-radio__label {
            color: variables.$neutral-lighter;
            font-weight: variables.$font-weight-normal;

            &:before {
                border: solid 1px variables.$neutral-lighter;
            }

            &:after {
                border-left: 2px solid variables.$neutral-lighter;
                border-bottom: 2px solid variables.$neutral-lighter;
            }
        }

        &:focus + .custom-radio__label,
        &:focus + .radio-url > .custom-radio__label {

            &:before {
                outline: rgb(59, 153, 252) auto 5px;
            }
        }

        &:checked + .custom-radio__label,
        &:checked + .radio-url > .custom-radio__label {

            &:before {
                background: variables.$white;
            }

            &:after {
                content: "";
            }
        }

        & + .custom-radio__label::after,
        & + .radio-url > .custom-radio__label::after {
            content: none;
        }
    }

    &__label {
        position: relative;
        padding-left: 30px;
        height: 100%;
        line-height: variables.$lh-medium;
        /*width: 100%;*/
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before,
        &::after {
            position: absolute;
            content: "";
            display: inline-block;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__label::before {
        height: 24px;
        width: 24px;
        box-sizing: border-box;
        border: 1px solid variables.$neutral;
        background-color: variables.$white;
        border-radius: 12px;
        left: 0;
    }

    &__label::after {
        height: 12px;
        width: 12px;
        background: variables.$primary;
        left: 6px;
        border-radius: 50%;
    }
}


.custom-radio__input {

    &:checked {

        + .custom-radio__label,
        + .radio-url > .custom-radio__label {

            &:before {
                border-color: variables.$primary;
            }
        }
    }
}
