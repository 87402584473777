@use "../../variables";

.flash-sales {

  border-radius: 50px;
  padding: 11px 30px;
  background: variables.$supporting-01;

  &__text {
    color: variables.$white;
    font-size: variables.$font-size-sm;
    margin: 0;
  }

  &__timer {
    font-weight: variables.$font-weight-bold;
  }
}