@use "../../variables";

#message-container {
    position: sticky;
    top: 0;
    z-index: variables.$zindex-alert;
}

#cookieConsentBlock {
    font-size: variables.$font-size-default;
    z-index: variables.$zindex-panel-overlay;
    position: sticky;
    top: 0;

    .consents {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding-top: 8px;
    }
}
#customize-cookies-modal{
    z-index: variables.$zindex-customize-cookie;
    .modal__header.modal__header{
        margin-top: 40px;
        .close {
            top:32px;
        }
    }
    .modal__body{
        padding-top: 0;
        .checkbox{
            font-weight: bold;
        }
        .essential{
            color: variables.$neutral;
        }
        .essential, .marketing{
            border-bottom: 1px solid variables.$neutral;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
        .essential, .marketing, .performance{
            padding-left: 40px;
        }
    }
    .modal__footer{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-around;
        background: variables.$neutral-lighter-25;
        padding-bottom: 40px;
    }
}


#cookie-declaration-modal {
    z-index: variables.$zindex-customize-cookie;

    .modal {
        &__header {
            padding: 27px 58px 32px 58px;
            flex-direction: column;

            .language-links {
                color: variables.$neutral;

                a {
                    color: variables.$neutral;
                }
            }

            span {
                color: variables.$primary;
                padding-top: 10px;
                font-weight: 400;
                font-size: 2em;
            }

            .close {
                top: 32px;
            }
        }

        &__body {
            padding: 48px 58px;
            background: variables.$neutral-lighter-25;
            max-height: calc(50vh - 64px);


            .checkbox {
                font-weight: bold;
            }

            .essential {
                color: variables.$neutral;
            }

            .essential, .marketing {
                border-bottom: 1px solid variables.$neutral;
                padding-bottom: 16px;
                margin-bottom: 16px;
            }

            .essential, .marketing, .performance {
                padding-left: 40px;
            }

            .cookie-info {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                padding: 12px 0;

                .accordion {
                    &__title {
                        justify-content: left;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        padding: 12px 0;
                    }

                    .openModal {
                        display: none;
                    }
                }
            }
        }

        &__footer {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: space-around;
            padding: 24px 48px 40px 48px;

            button {
                flex-grow: 1;
                flex-basis: 0;
            }
        }
    }

    @media(max-width: variables.$responsive-break - 1) {
        .modal {
            &__header {
                padding: 28px;
            }
            
            &__body {
                padding: 28px;
                
                .accordion {
                    .row {
                        flex-direction: column;
                        
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                           li {
                               a {
                                   word-break: break-all;
                               }
                           } 
                        }
                    }
                    
                }
            }
            
            &__footer {
                padding: 20px 28px;
            }
            
        }
    }
}

.alert {
    border-radius: variables.$alert-box-radius;
    padding: 16px;
    position: relative;
    display: flex;

    &-validation {
        display: none;

        &--active {
            display: flex;
        }
    }

    &--center {
        justify-content: center;
    }

    &--md {

        .alert__wrapper {
            width: initial;
            max-width: 1000px;
            margin: auto;
        }
    }

    &--small {
        max-width: 400px;
    }

    &--limit {

        .alert__wrapper {
            align-items: center;
            flex-direction: column;
            padding-top: 24px;
            padding-bottom: 48px;
        }

        .alert__content {
            text-align: center;
        }

        .alert__text,
        .alert__title {
            font-size: variables.$font-size-sm;
        }

        .alert__title {
            margin-top: 16px;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        margin-right: 32px;

        .icon {
            padding: 0;
        }
    }
    
    @media(max-width: variables.$responsive-break - 1) {
        .alert__icon {
            display: none;
        }
    }

    img {
        object-fit: contain;
    }

    .icon {
        font-size: variables.$font-size-xl;
    }

    &__link {
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;
    }

    &__content {
        display: flex;
        align-items: center;
        padding-right: 16px;
    }

    &__wrapper {
        display: flex;
        position: relative;
    }

    &__text {
        font-size: variables.$font-size-default;
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
            cursor: pointer;
            background: variables.$neutral-lighter-25;

            .icon {
                color: variables.$neutral-darker;
            }
        }

        .icon {
            font-size: variables.$font-size-lg;
        }
    }

    &--danger {
        background: variables.$alert-danger;

        .alert__icon .icon {
            color: variables.$supporting-01-dark;
        }
    }

    &--success {
        background: variables.$alert-success;

        .alert__icon .icon {
            color: variables.$supporting-03;
        }
    }

    &--info {
        background: variables.$secondary-lighter;


        .alert__icon .icon {
            color: variables.$secondary;
        }
    }

    &--primary {
        background: variables.$primary-lighter-50;

        .alert__icon .icon {
            color: variables.$primary-light;
        }
    }

    &--loyalty-campaign-warning{
        border-radius: 8px;
        border: 1px solid #E6E6EB;
        background: variables.$neutral-lighter-20;
        color: variables.$neutral-darker;
        .alert__icon {
            display: flex;
            .icon {
                color: variables.$primary;
                .fa, .fas{
                    font-weight: variables.$font-weight-normal;
                    font-size: variables.$font-size-3xl;
                }
            }
        }
    }

    &--negative-main {
        background: variables.$primary;


        .alert {

            &__icon,
            &__title,
            &__text,
            &__close {
                color: variables.$white;
            }

            .button__text {
                color: variables.$primary;
            }
        }
    }

    &--negative-secondary {
        background: variables.$secondary-darker;


        .alert {

            &__icon,
            &__title,
            &__text,
            &__close {
                color: variables.$white;
            }

            .button__text {
                color: variables.$primary;
            }
        }
    }

    &--negative-custom {
        background: variables.$supporting-01;

        .alert {

            &__icon,
            &__title,
            &__text,
            &__close {
                color: variables.$white;
            }

            .button__text {
                color: variables.$primary;
            }
        }
    }

    &--full-width {
        width: 100%;
        box-sizing: border-box;
        margin-top: 10px;
    }
}

.map-alert {
    display: none;

    &.alert--active {
        display: block;
        margin-top: 8px;
    }
}

@media(max-width: variables.$small-mobile-break - 1) { 
    .alert__content, button {
        padding: 0;
    }
}
