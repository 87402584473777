@use '../../variables';

.scroll-to-top-container {
  text-align: center;
  display: none;
}

.scroll-to-top-button {
  display: inline-block;
  color: variables.$primary;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: variables.$font-size-md;

  .fas {
    font-size: variables.$font-size-5xl;
  }
}

.scroll-to-top-container--active {
  display: block;
}
