@use '../variables';

.address {
    &__component,
    &__component--manual {
        font-size: variables.$font-size-default;

        .manual-address-container {
            width: 100%;
        }
    }
}

.address-manually {
    display: block;
    margin: 16px 0;
    font-size: variables.$font-size-default;
}

.manual-address {
    &__hidden,
    &__hidden.manual-address {
        display: none;
    }

    .dropdown {
        height: initial;

        &__selected {
            padding: 14px 8px;
            font-size: variables.$font-size-default;
        }
    }
    #LTManualAddressSearch {
        flex: 1 1 80%;
    }
}

.address-search {
    display: flex;
    align-items: baseline;
    width: 100%;

    >.label__wrapper {
        margin: 8px 0;
    }
    
    &__hidden {
        display: none;
    }

    &--with-favorite {
        align-items: baseline;

        .address-component {
            margin-bottom: 1.5rem;
        }
    }

    &-component {
        .input__wrapper {
            display: flex;
            flex-direction: column;
            
            .input-address_search{
                flex: 3 0 75%;
                border-radius: 2px 0 0 2px;
            }
            
            .input-apartment {
                flex: 1 0 25%;
                border-left: none;
                border-radius: 0 2px 2px 0;
            }
            
        }
    }
    
    .label__wrapper {
        flex: 1 1 28%;
        
        .input-field__label {
            flex: unset;
        }
    }
}
