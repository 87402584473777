@use "../../variables";

.items-carousel {
    display: flex;
    justify-content: center;

    &__list {
        display: flex;
    }

    &__wrapper {
        max-width: variables.$grid__bp-lg * 1px;
        position: relative;
    }

    &__discount {
        color: variables.$supporting-01;

        &--bold {
            font-weight: variables.$font-weight-bold;
        }
    }

    &__title {
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-lg;
        display: inline;
        margin: 0;
        margin-right: 10px;
        color: variables.$neutral-darker;
        line-height: 100%;
    }

    &__link {
        font-size: variables.$font-size-sm;
        color: variables.$neutral;
        font-weight: variables.$font-weight-bold;

        &:hover {
            text-decoration: underline;
        }

        &:visited {
            color: variables.$neutral;
        }
    }

    &__offers {
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;

        &:hover {
            text-decoration: underline;
        }
    }

    &__header {
        display: flex;
        justify-content: space-around;
        margin: 12px 0;

        &--wide {

            .items-carousel__offers {
                margin-left: auto;
            }
        }
    }

    .carousel-card {

        &__image {
            max-width: 162px;
            max-height: 136px;
        }

        &__button {
            padding: 0;
        }
    }
}

@media(min-width: variables.$responsive-break) {
    .items-carousel {
        &__header {
            width: variables.$responsive-break;
        }

        &__link--responsive {
            display: none;
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .items-carousel {
        &__list {
            flex-direction: column;
        }

        &__item {
            margin-bottom: 16px;
        }

        &__wrapper {
            width: 100%;
        }

        &__link {
            display: none;

            &--responsive {
                display: block;
                text-align: center;
                padding: 0 16px 0 16px;

                .button {
                    width: 100%;
                }
            }
        }
    }
}
