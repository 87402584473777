@use "../../variables";

.tooltip {
    display: none;
    position: absolute;
    padding: 16px;
    background: variables.$primary;
    border-radius: variables.$border-radius-round;
    color: variables.$white;
    z-index: variables.$zindex-tooltip;
    box-sizing: border-box;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    width: 40vw;
    max-width: 400px;
    
    &__wrapper {
        position: relative;
        display: inline-flex;
    }

    &__content {
        word-break: break-word;

        .dropdown__list {
            opacity: 1;
            max-height: none;
            position: relative;
            width: 350px;
            box-shadow: none;
            border: none;

            &-item:not(.opt-group) {
                background: variables.$neutral-darker;
                color: variables.$white;
            }

            &-item__icon {
                color: variables.$secondary;
            }
        }
    }

    &__button {
        font-size: 16px;
        line-height: 16px;
        margin: auto;
        cursor: pointer;
        .icon{
            color: variables.$primary;
        }

        &:hover {
            cursor: pointer;
        }

        * {
            pointer-events: none;
        }

        &--active {
            background: variables.$primary;


            &:hover {
                background-color: variables.$primary;
            }

            i {
                color: variables.$white
            }
        }

        &.hover {

            &:hover {
                color: variables.$white;
            }
        }
    }

    &--top {

        .tooltip__arrow-triangle {
            margin-left: -10px;
            bottom: -9px;
            transform: rotate(180deg)
        }
    }

    &--bottom {

        .tooltip__arrow-triangle {
            margin-left: -10px;
            top: -9px;
        }
    }

    &--visible {
        display: block;
        visibility: visible;
    }
}

.tooltip__arrow-triangle {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid variables.$primary;
}


.vertical .product-card__comparison .tooltip__button {
    background: variables.$tooltip-background;
}

.vertical .product-card__comparison .compare .tooltip__button--active {
    background: variables.$primary;
}

.eol_tooltip__container {
    position: absolute;
    top: -120px;
    transform: translateX(-71px);

    .tooltip__wrapper {
        position: relative;

        .tooltip {
            left: 50%;
            transform: translateX(-50%);
            width: 250px;
            max-width: 250px;
            position: relative;

            .tooltip__close {
                position: absolute;
                top: 8px;
                right: 8px;
                cursor: pointer;
                color: variables.$white;
                font-size: 14px;
                line-height: 1;
                padding: 4px;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }
            }

            .tooltip__arrow-triangle {
                left: 90%;
            }
        }
    }
}
