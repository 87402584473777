@use "../../variables";
@use "../../mixins";

.product {
  &-video {
    margin-top: 48px;
    display: flex;
    align-items: center;
    padding: 16px 18px;
    border-top: solid 1px variables.$neutral-lighter-50;
    border-bottom: solid 1px variables.$neutral-lighter-50;

    &:hover {
      border-top: solid 1px variables.$primary-light;
      border-bottom-color: variables.$primary-light;
      cursor: pointer;

      .play-button {
        transform: scale(1.25);
      }
    }

    &__title {
      display: flex;
      align-items: center;
      font-weight: variables.$font-weight-bold;
      font-size: variables.$font-size-sm;
      color: variables.$primary;
      margin-left: 18px;
    }

    &__wrapper {
      position: relative;
      max-width: 300px;
      width: 100%;
    }

    &__thumbnail {
      max-height: 72px;
      width: 100%;
      object-fit: cover;
    }

    .play-button {
      transition: all .4s ease-in-out;
      height: 40px;
      width: 40px;
      background: transparent;
      position: absolute;
      top: 16px;
      left: 40%;
      border: 0;
      cursor: pointer;
      padding: 0;
      background-image: url("/images/play.svg")
    }
  }
}

.product-image {
    text-align: center;
    max-width: initial;
    $margin-for-overlay: 20px;
    
    &__wrapper {
        position: relative;
        display: inline-block;
        &.mt{
            margin-top: $margin-for-overlay;
        }
    }


    &__item {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;

        &:not(:last-of-type) {
            margin-right: 12px;
        }
    }

    &__img {
        width: 305px;
        height: 305px;
        z-index: 1;
    }

    .product-image__more {
        display: inline-block;
        position: relative;
        padding: 0;
        border: solid 1px transparent;
        border-radius: variables.$border-radius-round;

        &:hover {
            border: solid 1px variables.$neutral-lighter-100;
            cursor: pointer;
        }

        * {
            pointer-events: none
        }
    }

    .product-image__more .after,
    .after__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
    }

    .product-image__more .after,
    .after__background {
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: center;
        font-weight: variables.$font-weight-bold;
        color: variables.$neutral-darker;
        border-radius: variables.$border-radius-round;
        z-index: variables.$zindex-positive;
        font-size: variables.$font-size-default;

        &__background {
            z-index: variables.$zindex-pseudo-bg;
            background: rgba(219, 219, 219, .72);
        }
    }

    &__img {
        &:hover {
            cursor: zoom-in;
        }
    }

    &__list {
        display: flex;
        padding: 0;
        list-style: none;
        overflow: hidden;
        flex-wrap: wrap;

        .product-image__img {
            width: 48px;
            height: 48px;
            padding: 5px;
            border: solid 1px;
            border-color: transparent;


            &:hover {
                border-color: variables.$neutral-lighter-100;
                border-radius: variables.$border-radius-round;
                cursor: pointer;
            }
        }
    }

    .thumb-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        left: 0;
        top: -$margin-for-overlay;
        pointer-events: none;
        &.top-left{
            pointer-events: all;
            width:40%;
            z-index: 2;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
@media(min-width: variables.$responsive-break) {
    .product-image {
        &__list {
            margin-top: 48px;
            margin-left: 14px;
            margin-right: 14px;
        }

        &__wrapper .product-image__img {
            margin: 28px;
        }
    }

    .responsive-product-image__wrapper {
        display: none;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .product-image {
        &__list {
            display: none;
        }

        &__wrapper {
            display: none;
        }
    }

    .responsive-product-image__controls {
        z-index: 1;
        width: 100%;

        @include mixins.slider-controls;

        .prev, .next{
            i{
                &:before{
                    //Pseudo element positioning in iOS.
                    position:absolute;
                    top: 0;
                    right: 0;
                    margin-top: 15px;
                    margin-right: 12px;
                }
            }
    
        }


    }
}

.tns-nav {
    margin-top: 5px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.tns-nav .tns-nav-active {
    background: variables.$primary;
    opacity: 1;
    filter: alpha(opacity=100);
    box-shadow: none;
    border: 1px solid variables.$primary;
}

.tns-nav button {
    width: 2px;
    height: 2px;
    background: variables.$secondary;
    /*opacity: 0.25;*/
    filter: alpha(opacity=25);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    border: 1px solid variables.$secondary;
    margin: 10px 7px;
    padding: 2px;
}