@use "variables" as faVariables;
@use "mixins";
// Base Class Definition
// -------------------------

.#{faVariables.$fa-css-prefix},
.fas,
.far,
.fal,
.fad,
.fab {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

%fa-icon {
  @include mixins.fa-icon;
}
