@use "../../variables";
@use "../../mixins";

.modal-cart {
    @include mixins.modal-general;

    &--primary {
        @include mixins.modal-gray;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .modal-cart {
        &.modal {
            z-index: 1000001;
        }

        .modal__body {
            max-height: 60vh;
            box-sizing: border-box;
        }

        .modal__header, .modal__body, .modal__footer {
            padding: 10px;
        }
        .modal__title{
            h5{
                font-size: variables.$font-size-default;
            }
        }
    }
}
