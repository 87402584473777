@use "../variables";
@use "./normalize.scss";

* {
    -webkit-font-smoothing: antialiased;
}

@if(variables.$site-nova-or-avi){
    //Open sans ümber nimetatud kuna Leedus Klaviyo kirjutab meie sätteid üle muidu
    @font-face {
        font-family: Leedu-font;
        src: url("./fonts/Leedu-Font-Bold.woff2") format("woff2");
        font-weight: variables.$font-weight-bold;
        font-style: normal;
    }

    @font-face {
        font-family: Leedu-font;
        src: url("./fonts/Leedu-Font-Regular.woff2") format("woff2");
        font-weight: variables.$font-weight-normal;
        font-style: normal;
    }

    @font-face {
        font-family: Leedu-font;
        src: url("./fonts/Leedu-Font-ExtraBold.woff2") format("woff2");
        font-weight: variables.$font-weight-bolder;
        font-style: normal;
    }
}@else {
    @font-face {
        font-family: Nexa;
        src: url("./fonts/Nexa-Heavy.woff2") format("woff2");
        font-weight: variables.$font-weight-bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: Nexa;
        src: url("./fonts/Nexa-Regular.woff2") format("woff2");
        font-weight: variables.$font-weight-normal;
        font-style: normal;
    }
}

body {
    margin: 0 auto;
    font-family: variables.$font-family;
    font-weight: variables.$font-weight-normal;
    font-size: variables.$font-size-sm;
    color: variables.$neutral-darker;
}

@media (min-width: variables.$responsive-break) {
    body {
        max-width: 2560px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: variables.$neutral-darker;
}

h2 {
    font-size: variables.$font-size-md;
}

h4 {
    font-size: variables.$font-size-default;
    line-height: 19px;
}

button {
    font-family: variables.$font-family;
    font-weight: variables.$font-weight-normal;
    font-size: variables.$font-size-default;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: variables.$primary;
}

a, button, [tabindex]{
    &:focus-visible {
        outline: rgb(59, 153, 252) auto 5px; // WCAG 2.4.7
    }
}

ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
    }
}

p {
    margin: 0;
    color: variables.$neutral-darker;
    line-height: variables.$paragraph-lh;
    margin: 0;
}

input,
textarea {
    font-family: variables.$font-family;
}
