@use "../../../variables";
@use "../../../mixins";

.panel, .responsive-menu {
    &__overlay {
        height: 100%;
        top: 0;
        position: fixed;
        display: none;
        width: 100%;

        &.panel__overlay--active {
            background: variables.$neutral-darker;
            display: block;
            z-index: variables.$zindex-panel-overlay;
            opacity: .5;
        }
    }
}

.panel {

    &.register .login__wrapper {
        background: none;
    }

    &--register {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1 1 auto;
        border: 0;
        background: none;

        .panel__body {
            padding: 0;
        }

        .accordion__item:not(.accordion__item--active) {
            background: variables.$neutral-lighter-25;

            .accordion__title:hover {
                background: variables.$neutral-lighter-50;
            }
        }

        .accordion {
            @include mixins.panel-accordion;
        }
    }
}

@media(min-width: variables.$responsive-break) {
    .panel {
        @include mixins.panel;

        .accordion {
            @include mixins.panel-accordion;
        }

        &__header {
            display: flex;
            padding: 12px 8px 0 8px;
            

            .feedback-title {
                padding: 16px;
            }
            
            &__top{
                width: -webkit-fill-available;
                text-align: right;
                align-self: center;
            }
        }

        &__title {
            min-width: 80%;
            width: -webkit-fill-available;
        }

        &--cart {
            flex-direction: column;
            height: 100%;

            .panel__body {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
                flex: 1;
                display: flex;
                flex-direction: column;

                .empty-cart {
                    display: flex;
                    flex-direction: column;
                    background: variables.$neutral-lighter-25;
                    padding: 0;     
                    height: 100%;
                    
                    .title {
                        font-weight: variables.$font-weight-bold;
                        font-size: variables.$font-size-lg;
                        color: variables.$primary;
                        
                        &--secondary {
                            font-weight: variables.$font-weight-normal;
                            font-size: variables.$font-size-lg;
                            color: variables.$primary;
                        }
                    }

                    .header {
                        background: inherit;                        
                        justify-content: start;
                        align-items: center;
                        flex-direction: column;
                        padding: 48px 24px;
                        color: variables.$primary;
                        height: auto;
                        border-top: 1px solid variables.$neutral-lighter-50;
                        border-bottom: 1px solid variables.$neutral-lighter-50;

                        .image {
                            @if variables.$site-nova {
                                content: url('/images/novastar/empty_cart_nova.svg');                                
                            } @else if variables.$site-avi {
                                content: url('/images/avitela/empty_cart_avitela.svg');
                            } @else {                                
                                content: url('/images/empty_cart.svg');
                            }
                        }                       
                    }

                    .login-description {
                        display: flex;
                        justify-content: center;
                        background: variables.$white;
                        border-bottom: 1px solid variables.$neutral-lighter-100;
                        padding: 48px 0;

                        p {
                            font-weight: variables.$font-weight-normal;
                            font-size: variables.$font-size-lg;
                            color: variables.$primary;
                            text-align: center;
                            width: 60%;
                        }
                    }

                    .content {
                        background: variables.$white;
                        height: 100%;
                        padding: 24px 48px;

                        .panel__body {                           
                            display: flex;
                            position: relative;                            
                            background: inherit;                            
                            flex-direction: column;
                            padding: 0;

                            .separator {
                                border-bottom: 1px solid variables.$neutral-lighter-50;
                                width: 80%;
                                align-self: center;
                            }

                            .related-product-section {
                                border: 0;

                                .recommendation-card {
                                    display: grid;
                                    border: 1px solid variables.$neutral-lighter-100;
                                }

                                .view-more {
                                    display: none;
                                }
                            }
                            
                            .button {
                                align-self: center;
                                margin-top: 16px;
                                padding: 12px 40px;
                                border: 1px solid variables.$neutral-lighter-100;
                            }
                        }
                    }
                }
            }

            .slider-list {
                padding-right: 24px;
                padding-left: 24px;
            }

            .panel__footer {
                padding-bottom: 0;
            }
        }

        &--login {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1 1 auto;

            .panel__body {
                padding: 0;
            }

            .accordion__item:not(.accordion__item--active) {

                .accordion__title:hover {
                    background: #FAFBFC;
                }
            }
        }

        .section {
            overflow: hidden;
            height: auto;
            flex: 1 1 0;
            position: relative;

            &-1 {
                padding-bottom: 8px;
                display: flex;
                flex-direction: column;
            }

            &-2 {
                margin: 0 -32px;
            }

            &--bg-gray {
                background: variables.$neutral-lighter-25;
            }
        }

        &__title {
            color: variables.$primary;
            font-size: variables.$font-size-lg;
            font-weight: variables.$font-weight-normal;
            margin: 0;
            display: flex;
            align-items: center;

            .icon {
                width: 32px;
                height: 32px;
            }

            &:focus {
                outline: 0;
            }
        }

        &__close {
            border-radius: 0;
            background: transparent;
            color: variables.$neutral-lighter-100;
            font-size: variables.$font-size-lg;
            font-weight: variables.$font-weight-bolder;
            margin-left: auto;
            padding: 0;
        }


        &__body {
            height: 100%;
        }

        &__body,
        &__footer {
            padding: 16px 32px;
        }

        .accordion {
            @include mixins.accordion;
        }

        &--customer-service {
            .info-email {
                font-size: variables.$font-size-default;
                padding: 16px 0;
            }
        }
    }

    .openPanel {
        border: 0;
        display: flex;
        align-items: center;
        color: variables.$white;
        text-align: left;

        .button__text {
            margin-left: 8px;
            color: variables.$white;
            word-break: break-all;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .bg--blue {
        .openPanel {
            color: variables.$primary;

            .button__text {
                color: variables.$primary;
            }
        }
    }

    .responsive-panel__back {
        display: none;
    }

    .accordion__item {
        background: variables.$white;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .header-menu-banner {
        display: none;
    }

    .responsive-panel {
        @include mixins.panel;
        overflow-y: auto;
        padding: 0 16px;
        min-height: 100%;

        &:not(.responsive-menu) {
            .panel__close {
                display: none;
            }
        }

        &__back {
            @include mixins.panel__back;
        }
        
        .novanaut-head-hearts{
            content: url('/images/novastar/novanaut-head-hearts-purple.png');
        }
    }

    .panel, .responsive-menu {
        &__overlay {
            transition: .3s ease-out;
            display: flex;

            &.panel__overlay--active {

                @media screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape) {
                    width: 50%;
                }

                @media screen and (max-device-aspect-ratio: 1/1) and (orientation: portrait) {
                    width: 10%;
                }
            }
        }
    }

    .panel__overlay {
        left: 100%;

        &.panel__overlay--active {
            left: 0;
        }
    }

    .responsive-menu__overlay {
        right: 100%;

        &.panel__overlay--active {
            right: 0;
        }
    }
}
