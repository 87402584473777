/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@use "variables";

@use "mixins";

@use "core";

@use "larger";

@use "fixed-width";

@use "list";

@use "bordered-pulled";

@use "animated";

@use "rotated-flipped";

@use "stacked";

@use "icons";

@use "screen-reader";
