@use '../variables';

.changeFields {
    position: absolute;
    right: 24px;
    display: none;

    .icon {
        color: variables.$neutral-lighter-100;
        font-size: variables.$font-size-default;
    }
}
