@use '../../variables';

$font-size-xxs: 10px;

.energy-label {
    background: variables.$neutral-lighter-25;
    position: relative;

    .container {
        padding-top: 64px;
        display: flex;
    }

    p {
        position: relative;
        min-height: 70px;

        > span {
            position: absolute;
            font-size: variables.$font-size-sm;
            line-height: variables.$lh-small;
            text-align: center;

            &.energyRating {
                top: 5px;
                left: 0px;
                font-size: variables.$font-size-xl;
                font-weight: variables.$font-weight-bold;
                color: variables.$white;
                text-align: left;
                padding: 0 15px 0 20px;
            }

            &.annualEnergyConsumption {
                top: 5px;
                left: 17px;
            }

            &.fridgeCompartmentCapacity {
                top: 40px;
                left: 10px;
            }

            &.freezerCompartmentCapacity {
                top: 51px;
                left: 7px;
            }

            &.noiseLevel {
                top: 30px;
                left: 12px;
            }

            &.screenSize {
                top: 25px;
                left: 10px;
            }

            &.power {
                top: 6px;
                left: 25px;
            }

            &.annualWaterConsumption {
                top: 30px;
                left: 0px;

                span {
                    font-size: $font-size-xxs;
                }
            }

            &.maxLoadCapacity {
                top: 35px;
                left: 16px;
            }

            &.spinPerformanceClass {
                top: 40px;
                left: 17px;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }

            &.noiseLevelWashing,
            &.noiseLevelDrying {
                top: 7px;
                left: 37px;
            }

            &.dryingClass {
                top: 37px;
                left: 16px;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }

            &.loadingCapacity {
                top: 36px;
                left: 10px;
            }

            &.cycleLength {
                top: 37px;
                left: -1px;

                span {
                    font-size: $font-size-xxs;
                }
            }

            &.maxDryingCapacity {
                top: 34px;
                left: 14px;
            }

            &.dryingClassHor {
                top: 15px;
                left: 41px;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }

            &.suctionEfficiencyClass {
                top: 35px;
                left: 15px;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }

            &.lightingEfficiencyClass,
            &.greaseFilteringEfficiencyClass {
                top: 44px;
                left: 16px;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }

            &.ovenCapacity {
                top: 42px;
                left: 10px;
            }

            &.energyConsumptionRegular,
            &.energyConsumptionWithFan {
                top: 14px;
                left: 18px;

                span {
                    font-size: $font-size-xxs;
                }
            }
        }
    }

    h2 {
        padding-bottom: 20px;
    }

    .energyRating {
        background-repeat: no-repeat;
        text-align: justify;
    }
}
@media (min-width:variables.$responsive-break) {
    .energy-label {
        padding-bottom: 88px;
        .container img:first-child {
            padding-right: 40px;
        }
    }
}
@media (max-width:variables.$responsive-break - 1) {
    .energy-label {
        .container img:first-child {
            max-width: 100%;
        }
        h2, p{
            margin-left:8px;
            margin-right:8px;
        }
    }
}