@use "../../variables";

@media(min-width: variables.$responsive-break) {
  #responsive-sentinel {
    display: none;
  }
}

@media(max-width: variables.$responsive-break - 1) {
  #responsive-sentinel {
    position: fixed;
    left: 0;
    top: 0;
  }
}
