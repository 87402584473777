@use "../variables";

$map-grid-props: ( "": 0, "-sm": variables.$grid__bp-sm, "-md": variables.$grid__bp-md, "-lg": variables.$grid__bp-lg, "-xl": variables.$grid__bp-xl, "-xxl": variables.$grid__bp-xxl );

@mixin create-mq($breakpoint, $min-or-max) {
    @if($breakpoint == 0) {
        @content;
    }

    @else {
        @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
            @content;
        }
    }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
    @include create-mq($breakpoint, "min") {
        @for $i from 1 through $grid-cols {

            .col#{$modifier}-#{$i} {
                flex-basis: calc(100 / ($grid-cols / $i) ) * 1%;
                max-width: calc(100 / ($grid-cols / $i) ) * 1%;
                padding: 16px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
            }

            .col#{$modifier}-offset-#{$i} {
                margin-left: calc(100 / ($grid-cols / $i) ) * 1%;
            }
        }

        .col#{$modifier}-offset-0 {
            margin-left: 0;
        }
    }
}

.col {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 16px;
}

.noproducts {
    text-align: center;
    font-size: variables.$font-size-3xl;
    margin: 32px auto;
    display: block;
}

@each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, variables.$grid__cols, $breakpoint);
}

.row {
    display: flex;
    flex: 0 1 auto;
    box-sizing: border-box;
    width: 100%;

    &--align-items-center {
        align-items: center;
    }

    &--align-items-baseline {
        align-items: baseline;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--space-evenly {
        justify-content: space-evenly;
    }

    &--margin-top {
        margin-top: 16px;
    }

    &--margin-bottom {
        margin-bottom: 16px;
    }
}


.box {
    width: 100%;
    height: 50px;
    display: flex;
    background-color: variables.$neutral-lighter;
    align-items: center;
    justify-content: center;
}

.box--supporting-blue {
    background-color: variables.$primary-lighter-50;
}

.justify-content {

    &-center {
        justify-content: center;
        flex-direction: column;
    }
}

.product-desc__wrapper .col-lg-3 {
    flex-direction: column;
}

@media(min-width:variables.$responsive-break) {
    .container {
        max-width: variables.$grid__bp-lg * 1px;
        margin: 0 auto;
    }

    .row {
        flex-wrap: wrap;
    }
}

@media(max-width:variables.$responsive-break - 1) {
    .product-desc__wrapper .col-lg-3 {
        width: 100%;
    }
}
