.comparison {
    margin-top: 64px;

    .accordion {
        margin-top: 52px;
        margin-bottom: 64px;

        &__title {
            width: 100%;
            display: inline-block;
        }

        &__item:not(:first-of-type) {
            margin-top: 16px;
        }
    }
}
