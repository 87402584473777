@use '../variables';

.input-validation-error {
    border: 1px solid variables.$supporting-01-dark;
    padding:14px 8px;

    ~ .dropdown {
        border: 1px solid variables.$supporting-01-dark;
    }
}

.field-validation-valid {
  display: none;
}

.field-validation-error, .validation-summary-errors {
    display: block;
    color: variables.$supporting-01-dark;
    font-size: variables.$font-size-sm;
    padding: 9px 0;
}